import { Link } from 'react-router-dom';
import React from 'react';
import Swal from 'sweetalert2'
// import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElementTrabajador({ element, permisosUsuario }) {

    let { id_empleado, nombre, apellido_paterno, apellido_materno, foto_perfil, nombre_unidad_asignada, fecha_alta, nomina_semanal, cantidad_prestamo_semanal, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar al trabajador?' : 'Quieres deshabilitar al trabajador?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                // let values = new FormData()
                // values.append('folio', folio)
                // values.append('activo', activo)
                // let objectValues = Object.fromEntries(values);
                // // console.log({objectValues})
                // changeEstatusPermisionarioService({values:objectValues}).then(response => {
                //     // console.log(response)
                //     let _respuesta = JSON.parse(response);
                //     if (_respuesta.response === "success") {
                //         setEstadoEstatus(activo)
                //         Swal.fire(
                //             'Estatus actualizado',
                //             '',
                //             'success'
                //         )
                //     }
                    
                // })
            }
        })
    }

    return (
        <tr key={id_empleado}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/trabajadores/${id_empleado}`} className="dropdown-item" >Ver</Link>
                            {/* {
                                (permisosUsuario.trabajadores.editar === '1') &&
                                    (estadoEstatus === '1' ?
                                        <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                        :
                                        <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            } */}
                        </div>
                    </div>
                    
                    {
                        (foto_perfil) &&
                        (<img src={foto_perfil} className="img-fluid"/>)
                    }

                    <div className="table__nameDescription">
                        <p>{nombre} {apellido_paterno} {apellido_materno}</p>
                    </div>
                </div>
            </th>

            <td>
                <p>{nombre_unidad_asignada !== null && nombre_unidad_asignada}</p>
                <p>{nombre_unidad_asignada === null && '-'}</p>
            </td>

            <td>
                <p>$ {(parseFloat(nomina_semanal) - parseFloat(cantidad_prestamo_semanal))}</p>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus === '1' ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}