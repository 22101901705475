import React from 'react'
import { useParams } from 'react-router'
import Swal from 'sweetalert2'
import Icon from '../../components/Icon'
import updateEstatusUnidad from '../../services/unidades/updateEstatusUnidad'
import getEmpleado from '../../services/empleado/getEmpleado'
import updateEmpleado from '../../services/empleado/updateEmpleado'
import FormularioTrabajador from '../../components/FormularioTrabajador'
import TrabajadorEgresos from '../../components/TrabajadorEgresos'
import TrabajadorIngresos from '../../components/TrabajadorIngresos'
import TrabajadorCitas from '../../components/TrabajadorCitas'

export default function ViewTrabajador(props) {
    let { modulosPermisos } = props
    let { id_trabajador } = useParams()

    // var curr = new Date();
    // var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)));
    // var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    // const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    // const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    const [trabajador, setTrabajador] = React.useState({})
    const [loading, setLoading] = React.useState(true)

    const getTrabajadorService = () => {
        setLoading(true)
        let data = {
            id_empleado: id_trabajador,
        }
        getEmpleado({ values: data })
            .then(response => {
                let _response = JSON.parse(response)
                if (_response.response === 'success') {
                    setTrabajador(_response.data)
                    setLoading(false)
                }
            })
            .catch(error => {
                // console.log(`Ha petado getUnidad(): ${error}`)
            })
    }

    const onSubmitUpdate = e => {
        e.preventDefault()
        let values = new FormData(e.target)
        values.append('id_empleado', id_trabajador)
        let objectValues = Object.fromEntries(values)
        // // console.log({objectValues})
        updateEmpleado({ values: objectValues })
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                if (_response.response === 'success') {
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false,
                    })
                    getTrabajadorService()
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok',
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
            })
    }

    const onClickEstatus = activo => {
        let msg = activo ? 'Quieres habilitar la unidad?' : 'Quieres deshabilitar la unidad?'
        let txt = activo ? 'Habilitar' : 'Deshabilitar'
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
            if (result.isConfirmed) {
                let values = new FormData()
                values.append('id_trabajador', id_trabajador)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values)
                updateEstatusUnidad({ values: objectValues }).then(response => {
                    // // console.log(response)
                    let _respuesta = JSON.parse(response)
                    if (_respuesta.response === 'success') {
                        getTrabajadorService()
                        Swal.fire('Estatus actualizado', '', 'success')
                    }
                })
            }
        })
    }

    React.useEffect(() => {
        getTrabajadorService()
    }, [])

    if (!loading) {
        return (
            <section>
                <input
                    action="action"
                    onClick={() => {
                        window.history.go(-1)
                        return false
                    }}
                    type="submit"
                    value="Regresar"
                    className="btn btn-gris btn-sm noMargin"
                />

                <hr />

                <div className="row">
                    <div className="col-md-3">
                        <h2>{trabajador.nombre}</h2>

                        <div className="d-flex flex-column">
                            <p className="my-2">
                                <small>Fecha alta: {trabajador.fecha_alta}</small>
                            </p>
                            <span className={`flag ${trabajador.activo == 1 ? '' : 'flag-inactive'}`}>
                                {trabajador.activo == 1 ? 'Activo' : 'Inactivo'}
                            </span>
                        </div>

                        <hr />

                        <div
                            className="my-2 text-left nav flex-column nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                        >
                            <a
                                className="nav-link active"
                                id="v-pills-home-tab"
                                data-toggle="pill"
                                href="#v-pills-home"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                            >
                                <Icon icon="fas fa-hospital mr-2" />
                                Datos generales
                            </a>

                            {modulosPermisos.egresos.leer === '1' && (
                                <a
                                    className="nav-link"
                                    id="v-pills-egresos-tab"
                                    data-toggle="pill"
                                    href="#v-pills-egresos"
                                    role="tab"
                                    aria-controls="v-pills-egresos"
                                    aria-selected="true"
                                >
                                    <Icon icon="fas fa-money-bill-transfer mr-2" />
                                    Egresos
                                </a>
                            )}

                            {modulosPermisos.ingresos.leer === '1' && (
                                <a
                                    className="nav-link"
                                    id="v-pills-ingresos-tab"
                                    data-toggle="pill"
                                    href="#v-pills-ingresos"
                                    role="tab"
                                    aria-controls="v-pills-ingresos"
                                    aria-selected="true"
                                >
                                    <Icon icon="fas fa-money-bill-transfer mr-2" />
                                    Ingresos
                                </a>
                            )}

                            {modulosPermisos.expedientesCitas.leer === '1' && (
                                <a
                                    className="nav-link"
                                    id="v-pills-citas-tab"
                                    data-toggle="pill"
                                    href="#v-pills-citas"
                                    role="tab"
                                    aria-controls="v-pills-citas"
                                    aria-selected="true"
                                >
                                    <Icon icon="fas fa-calendar-check mr-2" />
                                    Citas
                                </a>
                            )}

                            {/* <a className="nav-link" id="v-pills-empleados-tab" data-toggle="pill" href="#v-pills-empleados" role="tab" aria-controls="v-pills-empleados" aria-selected="true">
                                <Icon icon="fas fa-user mr-2" />
                                Materiales e insumos
                            </a>

                            <a className="nav-link" id="v-pills-empleados-tab" data-toggle="pill" href="#v-pills-empleados" role="tab" aria-controls="v-pills-empleados" aria-selected="true">
                                <Icon icon="fas fa-book-medical mr-2" />
                                Procesos
                            </a>

                            <a className="nav-link" id="v-pills-empleados-tab" data-toggle="pill" href="#v-pills-empleados" role="tab" aria-controls="v-pills-empleados" aria-selected="true">
                                <Icon icon="fas fa-file-medical mr-2" />
                                Pacientes atendidos
                            </a> */}

                            {trabajador.activo == 1 ? (
                                <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(false)}>
                                    <Icon icon="fa-solid fa-circle-minus mr-2" />
                                    Deshabilitar
                                </a>
                            ) : (
                                <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(true)}>
                                    <Icon icon="fa-solid fa-circle-plus mr-2" />
                                    Habilitar
                                </a>
                            )}
                        </div>
                    </div>

                    <div className="col-md-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                {trabajador && (
                                    <FormularioTrabajador
                                        trabajador={trabajador}
                                        onSubmit={onSubmitUpdate}
                                        modulosPermisos={modulosPermisos}
                                    />
                                )}
                            </div>

                            <div
                                className="tab-pane fade"
                                id="v-pills-egresos"
                                role="tabpanel"
                                aria-labelledby="v-pills-egresos-tab"
                            >
                                <TrabajadorEgresos modulosPermisos={modulosPermisos} id_trabajador={id_trabajador} />
                            </div>

                            <div
                                className="tab-pane fade"
                                id="v-pills-ingresos"
                                role="tabpanel"
                                aria-labelledby="v-pills-ingresos-tab"
                            >
                                <TrabajadorIngresos modulosPermisos={modulosPermisos} id_trabajador={id_trabajador} />
                            </div>

                            <div
                                className="tab-pane fade"
                                id="v-pills-citas"
                                role="tabpanel"
                                aria-labelledby="v-pills-citas-tab"
                            >
                                <TrabajadorCitas
                                    modulosPermisos={modulosPermisos}
                                    id_trabajador={id_trabajador}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <div className="vh-75 d-flex justify-content-center align-items-center">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }
}
