import React from 'react';
import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2'
import FormularioUnidad from '../../components/FormularioUnidad';
import addUnidad from '../../services/unidades/addUnidad';
import isEmpleadoResponsableUnidad from '../../services/unidades/isEmpleadoResponsableUnidad';

export default function AddUnidad(props){

    let { modulosPermisos } = props

    const history = useHistory()

    function onSubmitUnidad(e){
        e.preventDefault();
        const formData = new FormData(e.target);
        let objectValues = Object.fromEntries(formData);
        isEmpleadoResponsableUnidad({values:{id_empleado: objectValues.id_trabajador}})
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'error')
                    // Significa que no es responsable de ningun area y se puede asignar
                    // tranquilamente
                    addUnidadService(objectValues)

                if(_res.response === 'success')
                    // Se avisa que ya es responsable de otra unidad y preguntar si desea
                    // hacerlo nuevo responsable y quitarle la responsabilidad de la unidad 
                    // anterior
                    Swal.fire({
                        showCancelButton: true,
                        text: 'El trabajador seleccionado ya es responsable de otra unidad, deseas hacerlo responsable también de la nueva?',
                        icon: 'info',
                        confirmButtonText: 'Si'
                    }).then(res=>{
                        if(res.isConfirmed)
                            addUnidadService(objectValues)
                    })
            })
    }

    function addUnidadService(values){
        addUnidad({values:values})
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    Swal.fire({
                        title:'Unidad agregada',
                        icon:'success',
                        timer:1200
                    }).then(res=>{
                        history.push('/unidades')
                    })
                }
            })
    }

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btn btn-gris noMargin"
            />

            <h2 className="my-4">Agregar una unidad</h2>

            <p>
                Para agregar una unidad, tienes que completar el siguiente formulario:
            </p>

            <hr className='my-3' />

            <FormularioUnidad 
                onSubmit={onSubmitUnidad}
                modulosPermisos={modulosPermisos}
            />


        </section>
    );
}