import React from "react";
import $ from 'jquery';
import "./ModalAddCitaCompletadaExpediente.css"
import getUnidades from "../../services/unidades/getUnidades";
import getUnidadEmpleados from "../../services/unidades/getUnidadEmpleados";
import getProcesosExpedienteByUnidad from "../../services/expediente/getProcesosExpedienteByUnidad";
import ToggleButton from "../ToggleButton";

export default function ModalAddCitaCompletadaExpediente({folio, onSubmitModal}){

    const [unidades, setUnidades] = React.useState([]);
    const [empleados, setEmpleados] = React.useState([]);
    const [procesos, setProcesos] = React.useState([]);

    function getUnidadesService(){
        getUnidades({values:{}})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setUnidades(_res.data)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const setDataFormulario = ()=>{
        setProcesos([])
        setEmpleados([])
        $("#formAddCitaCompletadaExpediente")[0].reset();
        document.getElementById('fecha_visita').valueAsDate = new Date();
        setTimeout(()=>{
            $('.selectpicker').selectpicker('refresh');
        }, 200)
    }

    function getProcesosByUnidadService(id_unidad){
        let data = {
            id_unidad: id_unidad,
            folio: folio.folio
        }
        getProcesosExpedienteByUnidad({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                console.log({_res})
                if(_res.response === 'success'){
                    setProcesos(_res.data)
                    $("#id_proceso").attr("disabled",false)
                    // if(elementToEdit !== null){
                    //     $('#id_proceso option[value="'+ elementToEdit.id_proceso +'"]').prop('selected', true);
                    //     $('.selectpicker').selectpicker('refresh');
                    // }
                } else {
                    setProcesos([])
                    // $("#id_proceso").attr("disabled",true)
                }
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function getEmpleadosByUnidadService(id_unidad){
        let data = { id_unidad }
        getUnidadEmpleados({values:data})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setEmpleados(_res.data)
                    $("#id_empleado_responsable_unidad").attr("disabled",false)
                    // if(elementToEdit !== null){
                    //     document.getElementById("id_empleado_responsable_unidad").value = elementToEdit.id_empleado
                    //     $('.selectpicker').selectpicker('refresh');
                    // }
                } else {
                    setEmpleados([])
                    // $("#id_empleado_responsable_unidad").attr("disabled",true)
                }
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function onChangeUnidad(value){
        getProcesosByUnidadService(value)
        getEmpleadosByUnidadService(value)
    }

    React.useEffect(getUnidadesService, []);
    React.useEffect(setDataFormulario, [folio]);

    return(
        <div className="modal" id="modalAddCitaCompletadaExpediente" tabIndex="-1" role="dialog" aria-labelledby="modalAddCitaCompletadaExpedienteLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} id="formAddCitaCompletadaExpediente" className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddCitaCompletadaExpedienteLabel">Marca una cita realizada por el paciente</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        {
                            folio && 
                                <h5 className="mb-3">
                                    Este formulario es para indicar que el paciente: "{folio.nombre} {folio.apellido_paterno} {folio.apellido_materno}", realizó una cita.
                                </h5>
                        }

                        <small>
                            Debes indicar cuándo fue la cita, qué doctor y qué unidad lo atendió, qué tratamiento se trabajó y alguna descripción detallada del motivo de la cita <small className="font-weight-bold">(esto último es opcional).</small>
                        </small>

                        <div className="row mt-3">
                            <div className="col-6">
                                <label htmlFor="fecha_visita">
                                    Fecha de la cita:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_visita"
                                    name="fecha_visita"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_unidad">
                                    Qué unidad lo atendió?
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_unidad"
                                    name="id_unidad"
                                    onChange={(e) => onChangeUnidad(e.target.value)}
                                    required
                                >
                                    <option value="">Elige una unidad</option>
                                    {
                                        unidades &&
                                        unidades.map( (unidad) => (
                                            <option key={ unidad.id_unidad } value={ unidad.id_unidad }>{ unidad.nombre }</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <hr className="my-2" />

                        <small>
                            Sí no aparece ningún tratamiento, es porque primero <small className="font-weight-bold">debes darlo de alta</small> en el expediente.
                        </small>

                        <div className="row mt-3">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <label htmlFor="id_empleado_responsable_unidad">
                                    Qué doctor lo atendió?
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_empleado_responsable_unidad"
                                    name="id_empleado"
                                    required
                                >
                                    {
                                        empleados &&
                                        empleados.map( (empleado) => (
                                            <option key={ empleado.id_empleado } value={ empleado.id_empleado }>{ empleado.nombre }</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <label htmlFor="id_proceso_expediente">
                                    Qué tratamiento se trabajó?
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_proceso_expediente"
                                    name="id_proceso_expediente"
                                    data-live-search="true"
                                    required
                                >
                                    {
                                        procesos &&
                                        procesos.map( (proceso) => (
                                            <option key={ proceso.fecha_alta } value={ proceso.id_proceso_expediente }>{ `ID ${proceso.id_proceso_expediente}. ${proceso.nombre}` }</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div  className="mr-4">
                                        <label className="m-0">
                                            Terminó el tratamiento?
                                        </label>
                                        <br/>
                                        <small className="text-muted">
                                            Sí al registrar esta cita el tratamiento ha terminado, marca esta opción.
                                        </small>
                                    </div>
                                    <ToggleButton
                                        id="termino_tratamiento"
                                        name="termino_tratamiento"
                                        className="my-0"
                                    />
                                </div>
                                <hr className="my-3"/>
                            </div>

                            
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción detallada de la cita:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="Sé lo más detallado posible (Opcional)"
                                    className="input textarea"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button 
                            type="submit" 
                            className="btn btn-primary" 
                            id="btnModalAddCitaCompletadaExpedienteSubmit"
                        >
                            Agregar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}