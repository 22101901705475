import $ from 'jquery';
import config from './../../config';
const URL_API = config.urlApi+'controlador/laboratorio/getLaboratorios.php';

export default function getLaboratorios({values}){
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values
    })
}