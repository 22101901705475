import React from "react";
import { useParams } from "react-router";
import FormularioUnidad from "../../components/FormularioUnidad";
import Swal from "sweetalert2";
import Icon from "../../components/Icon";
import getExpediente from "../../services/expediente/getExpediente";
import updateExpediente from "../../services/expediente/updateExpediente";
import updateEstatusExpediente from "../../services/expediente/updateEstatusExpediente";
import UnidadEmpleados from "../../components/UnidadEmpleados";
import UnidadIngresos from "../../components/UnidadIngresos";
import UnidadEgresos from "../../components/UnidadEgresos";
import FormularioExpediente from "../../components/FormularioExpediente";
import ExpedienteTratamientos from "../../components/ExpedienteTratamientos";
import ExpedientePagos from "../../components/ExpedientePagos";
import ExpedienteCitas from "../../components/ExpedienteCitas";

export default function ViewExpediente(props){

    let { modulosPermisos } = props
    let { folio } = useParams()

    const [expediente, setExpediente] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const getExpedienteService = () => {
        setLoading(true)
        let data = {
            folio: folio
        }
        getExpediente({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    setExpediente(_response.data);
                    setLoading(false)
                }
            })
            .catch(error => {
                // console.log(`Ha petado getExpediente(): ${error}`)
            })
    }

    const onSubmitUpdate = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('folio_actual', folio);
        values.append('id_paciente', expediente.id_paciente);
        let objectValues = Object.fromEntries(values);
        // console.log({objectValues})
        updateExpediente({values:objectValues})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                    if(folio !== objectValues.folio){
                        window.history.pushState({}, "", `/expedientes/${objectValues.folio}`)
                        window.location.reload()
                    }
                    getExpedienteService();
                } else if(_response.response === 'existe_folio') {
                    Swal.fire({
                        title: 'Folio repetido',
                        text: 'Ya existe un folio '+objectValues.folio+', registra otro',
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el expediente?' : 'Quieres deshabilitar el expediente?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('folio', folio)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                updateEstatusExpediente({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getExpedienteService();
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    React.useEffect(getExpedienteService, []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {
                    window.history.go(-1);
                        return false;
                    }}
                    type="submit"
                    value="Regresar"
                    className="btn btn-gris btn-sm noMargin"
                />

                <hr />

                <div className="row">
                    <div className="col-md-3">
                        <h2>{expediente.nombrePaciente}</h2>

                        <div className="d-flex flex-column">
                            <p className="my-2">Folio: {expediente.folio}</p>
                            <small>Fecha alta: {expediente.fecha_alta_primera_vez}</small>
                            <span
                            className={` mt-2 flag ${
                                expediente.activo === '1' ? '' : 'flag-inactive'
                            }`}
                            >
                            {expediente.activo === '1' ? 'Activo' : 'Inactivo'}
                            </span>
                        </div>

                        <hr />

                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a
                                className="nav-link active"
                                id="v-pills-home-tab"
                                data-toggle="pill"
                                href="#v-pills-home"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                            >
                                <Icon icon="fas fa-hospital mr-2" />
                                Datos generales
                            </a>

                            {modulosPermisos.expedientesTratamientos.leer === '1' && (
                            <a
                                className="nav-link"
                                id="v-pills-procesos-tab"
                                data-toggle="pill"
                                href="#v-pills-procesos"
                                role="tab"
                                aria-controls="v-pills-procesos"
                                aria-selected="true"
                            >
                                <Icon icon="fas fa-book-medical mr-2" />
                                Tratamientos
                            </a>
                            )}

                            {modulosPermisos.expedientesCitas.leer === '1' && (
                            <a
                                className="nav-link"
                                id="v-pills-citas-tab"
                                data-toggle="pill"
                                href="#v-pills-citas"
                                role="tab"
                                aria-controls="v-pills-citas"
                                aria-selected="true"
                            >
                                <Icon icon="fas fa-calendar-check mr-2" />
                                Citas
                            </a>
                            )}

                            {modulosPermisos.ingresos.leer === '1' && (
                            <a
                                className="nav-link"
                                id="v-pills-ingresos-tab"
                                data-toggle="pill"
                                href="#v-pills-ingresos"
                                role="tab"
                                aria-controls="v-pills-ingresos"
                                aria-selected="true"
                            >
                                <Icon icon="fas fa-money-bill-transfer mr-2" />
                                Pagos
                            </a>
                            )}

                            {modulosPermisos.expedientes.editar === '1' && (
                            <>
                                {expediente.activo === '1' ? (
                                <a
                                    className="nav-link"
                                    role="tab"
                                    type="button"
                                    onClick={() => onClickEstatus(0)}
                                >
                                    <Icon icon="fa-solid fa-circle-minus mr-2" />
                                    Deshabilitar
                                </a>
                                ) : (
                                <a
                                    className="nav-link"
                                    role="tab"
                                    type="button"
                                    onClick={() => onClickEstatus(1)}
                                >
                                    <Icon icon="fa-solid fa-circle-plus mr-2" />
                                    Habilitar
                                </a>
                                )}
                            </>
                            )}
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                {expediente && (
                                    <FormularioExpediente
                                    expediente={expediente}
                                    onSubmit={onSubmitUpdate}
                                    modulosPermisos={modulosPermisos}
                                    />
                                )}
                            </div>

                            <div
                                className="tab-pane fade"
                                id="v-pills-citas"
                                role="tabpanel"
                                aria-labelledby="v-pills-citas-tab"
                            >
                                {<ExpedienteCitas folio={folio} expediente={expediente} modulosPermisos={modulosPermisos} />}
                            </div>

                            <div
                                className="tab-pane fade"
                                id="v-pills-procesos"
                                role="tabpanel"
                                aria-labelledby="v-pills-procesos-tab"
                            >
                                {<ExpedienteTratamientos folio={folio} modulosPermisos={modulosPermisos} />}
                            </div>

                            <div
                                className="tab-pane fade"
                                id="v-pills-ingresos"
                                role="tabpanel"
                                aria-labelledby="v-pills-ingresos-tab"
                            >
                                {<ExpedientePagos folio={folio} modulosPermisos={modulosPermisos} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );

    } else {
        return(
            <div className="vh-75 d-flex justify-content-center align-items-center">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}