import React from "react"

export default function ModalAddTipoEgreso({onSubmitModal, elementToEdit}){

    const setElementToEdit = () =>{
        if(elementToEdit !== null){
            document.getElementById('nombre').value = elementToEdit.nombre;
            if(elementToEdit.descripcion !== undefined){
                document.getElementById('descripcion').value = elementToEdit.descripcion;
            } else {
                document.getElementById('descripcion').value = ""
            }
        }

        if(elementToEdit === null){
            document.getElementById('nombre').value = "";
            document.getElementById('descripcion').value = "";
        }
    }

    React.useEffect(setElementToEdit, [elementToEdit])

    return(
        <div className="modal" id="modalAddTipoEgreso" tabIndex="-1" role="dialog" aria-labelledby="modalAddTipoEgresoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} className="modal-content" id="formAddTipoEgreso">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddTipoEgresoLabel">Agregar tipo de egreso</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="nombre">
                                    Nombre:
                                </label>
                                <input 
                                    type="text"
                                    className="input" 
                                    id="nombre"
                                    name="nombre"
                                    placeholder="Escribe el nombre del gasto"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción detallada del gasto:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="(Opcional) Sé lo más detallado posible"
                                    className="input textarea"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary">
                            {
                                elementToEdit !== null ? "Actualizar" : "Agregar"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}