import ReactDOM from 'react-dom';
import React from 'react'
// Librerías
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';

import './index.css';
import App from './App';
library.add(fas);

ReactDOM.render(
  <App />,
  document.getElementById('root')
)