import React from "react";
import { validateNoNumbers } from "../../validators/validateNoNumbers";

export default function ModalEmitirReembolso({onSubmitModal}){

    return(
        <div className="modal" id="modalEmitirReembolso" tabIndex="-1" role="dialog" aria-labelledby="modalEmitirReembolsoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} className="modal-content" id="formEmitirReembolso">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalEmitirReembolsoLabel">Emite un reembolso</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="fecha_del_reembolso">
                                    Fecha del reembolso:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_del_reembolso"
                                    name="fecha_del_reembolso"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="cantidad_reembolso">
                                    Cantidad del reembolso:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="cantidad_reembolso"
                                    name="cantidad_reembolso"
                                    placeholder="$"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="motivo_reembolso">
                                    Descripción detallada del reembolso:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="Sé lo más detallado posible (Opcional)"
                                    className="input textarea"
                                    id="motivo_reembolso"
                                    name="motivo_reembolso"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary">Asignar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}