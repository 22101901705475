import React from 'react'
import Table from '../../components/Table'
import TableHead from '../../components/Table/TableHead'
import TableBody from '../../components/Table/TableBody'
import useOrdenesTrabajo from '../../hooks/useOrdenesTrabajo'
import AlertWarningSearch from '../../components/AlertWarningSearch'
import LoadingIndicator from '../../components/LoadingIndicator'
import CardEmpleado from '../../components/CardEmpleado'
import useTrabajador from '../../hooks/useTrabajador'

export default function PaginaPrincipal() {
    const { ordenesTrabajoByUsuario, loadingOrdenesTrabajoByUsuario, getOrdenesTrabajoByUsuarioService } =
        useOrdenesTrabajo()
    const { trabajadores, loadingTrabajadores, getMetaSemanalTrabajadorService, fechaMetaInicio, fechaMetaFinal } =
        useTrabajador()

    React.useEffect(() => {
        getOrdenesTrabajoByUsuarioService()
    }, [])

    React.useEffect(() => {
        getMetaSemanalTrabajadorService()
    }, [])

    const reload = () => {
        getOrdenesTrabajoByUsuarioService()
    }

    return (
        <section>
            <h1>Dashboard</h1>
            <hr />

            {loadingTrabajadores && <LoadingIndicator show={loadingTrabajadores} />}
            {!loadingTrabajadores && trabajadores.length > 0 && (
                <>
                    <h2>Meta semanal</h2>
                    <p>
                        Del <u>{fechaMetaInicio}</u> al <u>{fechaMetaFinal}</u>
                    </p>

                    <div className="row my-3">
                        {trabajadores.length !== 0 &&
                            !loadingTrabajadores &&
                            trabajadores[0].unidades.map((unidad, index) => {
                                return (
                                    <div className="col-lg-6 col-12" key={unidad.id_unidad + index}>
                                        <CardEmpleado
                                            foto_perfil={trabajadores[0].foto_perfil}
                                            nombre={trabajadores[0].nombre}
                                            total_pacientes_atendidos={unidad.total_pacientes_atendidos}
                                            ingresos={unidad.total_ingresos}
                                            egresos={unidad.total_egresos}
                                            meta={unidad.meta}
                                            comision={unidad.comision}
                                            egreso_prestamos={unidad.cantidad_prestamo_semanal}
                                            egreso_laboratorios={unidad.gasto_laboratorio}
                                            id_empleado={trabajadores[0].id_empleado}
                                            nombre_unidad={unidad.nombre}
                                        />
                                    </div>
                                )
                            })}
                    </div>

                    <hr />
                </>
            )}

            <h2>Trabajos de laboratorio</h2>

            {ordenesTrabajoByUsuario.length !== 0 && !loadingOrdenesTrabajoByUsuario && (
                <Table>
                    <TableHead
                        rows={[
                            { id: 1, name: 'Paciente' },
                            { id: 2, name: 'Etapa' },
                            { id: 3, name: 'Estado' },
                        ]}
                    />

                    <TableBody
                        elements={ordenesTrabajoByUsuario}
                        typeElement="dashboard_orden_trabajo"
                        reload={reload}
                    />
                </Table>
            )}

            {ordenesTrabajoByUsuario.length === 0 && !loadingOrdenesTrabajoByUsuario && <AlertWarningSearch />}
        </section>
    )
}
