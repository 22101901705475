import React from "react";
import $ from 'jquery';
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import getMetodosPago from "../../services/ingreso/getMetodosPago";
import getEmpleados from "../../services/empleado/getEmpleados";
import searchProcesos from "../../services/proceso/searchProcesos";
import getUnidades from "../../services/unidades/getUnidades";
import getUnidadEmpleados from "../../services/unidades/getUnidadEmpleados";
import getProcesosExpedienteByUnidad from "../../services/expediente/getProcesosExpedienteByUnidad";
import getProcesosExpedienteByUnidadFinalizado from "../../services/expediente/getProcesosExpedienteByUnidadFinalizado";

export default function ModalAddIngresoExpediente({folio, id_empleado_responsable_unidad, onSubmitModal, elementToEdit}){

    const [unidades, setUnidades] = React.useState([]);
    const [empleados, setEmpleados] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [procesos, setProcesos] = React.useState([]);

    const getMetodosPagoService = ()=>{
        getMetodosPago({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMetodosPago(_res.data)
                    $('.selectpicker').selectpicker('refresh');
                    setDataFormulario()
                }
            })
    }

    const getUnidadesService = ()=>{
        getUnidades({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setUnidades(_res.data)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    function getEmpleadosByUnidadService(id_unidad){
        let data = { id_unidad }
        getUnidadEmpleados({values:data})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setEmpleados(_res.data)
                    $("#id_empleado_responsable_unidad").attr("disabled",false)
                    if(elementToEdit !== null){
                        document.getElementById("id_empleado_responsable_unidad").value = elementToEdit.id_empleado
                        $('.selectpicker').selectpicker('refresh');
                    }
                } else {
                    setEmpleados(_res.data)
                    $("#id_empleado_responsable_unidad").attr("disabled",false)
                }
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const setDataFormulario = ()=>{
        console.log(elementToEdit)
        if(elementToEdit !== null){
            $('#id_unidad option[value="'+ elementToEdit.id_unidad +'"]').prop('selected', true);
            onChangeUnidad(elementToEdit.id_unidad);
            document.getElementById("numero_recibo").value = elementToEdit.numero_recibo
            document.getElementById("fecha_del_pago").value = elementToEdit.fecha_del_pago_sin_formato
            document.getElementById("cantidad").value = elementToEdit.cantidad
            document.getElementById("id_metodo_pago").value = elementToEdit.id_metodo_pago
            document.getElementById("descripcion").value = elementToEdit.descripcion
        } else {
            $("#formAddIngresoExpediente")[0].reset();
            document.getElementById('fecha_del_pago').valueAsDate = new Date();
            setEmpleados([])
            setProcesos([])
            $('.selectpicker').selectpicker('refresh');
        }
    }

    function getProcesosByUnidadFinalizadoService(id_unidad){
        let data = {
            id_unidad: id_unidad,
            folio: folio
        }
        getProcesosExpedienteByUnidadFinalizado({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setProcesos(_res.data)
                    $("#id_proceso").attr("disabled",false)
                    if(elementToEdit !== null){
                        $('#id_proceso option[value="'+ elementToEdit.id_proceso +'"]').prop('selected', true);
                        $('.selectpicker').selectpicker('refresh');
                    }
                } else {
                    setProcesos([])
                    $("#id_proceso").attr("disabled",true)
                }
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function onChangeUnidad(value){
        getProcesosByUnidadFinalizadoService(value)
        getEmpleadosByUnidadService(value)
    }

    React.useEffect(getMetodosPagoService, []);
    React.useEffect(getUnidadesService, []);
    React.useEffect(setDataFormulario, [elementToEdit])

    return(
        <div className="modal" id="modalAddIngresoExpediente" tabIndex="-1" role="dialog" aria-labelledby="modalAddIngresoExpedienteLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} id="formAddIngresoExpediente" className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddIngresoExpedienteLabel">Agrega un ingreso al expediente</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="numero_recibo">
                                    No. de recibo:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="numero_recibo"
                                    name="numero_recibo"
                                    placeholder="######"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="fecha_del_pago">
                                    Fecha del pago:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_del_pago"
                                    name="fecha_del_pago"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="cantidad">
                                    Cantidad del pago:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="cantidad"
                                    name="cantidad"
                                    placeholder="$"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_metodo_pago">
                                    Método de pago:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_metodo_pago"
                                    name="id_metodo_pago"
                                    required
                                >  
                                    <option value="">Elige un método de pago</option>
                                    {
                                        metodosPago && 
                                        metodosPago.map( metodo_pago => (
                                            <option key={ metodo_pago.id_metodo_pago } value={ metodo_pago.id_metodo_pago }>
                                                { metodo_pago.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción detallada del pago:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="Sé lo más detallado posible (Opcional)"
                                    className="input textarea"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <h3 className='my-4'>
                                Datos generales
                            </h3>

                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="id_unidad">
                                        Unidad:
                                    </label>
                                    <br/>
                                    <select 
                                        className="input selectpicker"
                                        id="id_unidad"
                                        name="id_unidad"
                                        data-live-search="true"
                                        onChange={(e) => onChangeUnidad(e.target.value)}
                                        required
                                    >
                                        <option value="">Elige una unidad</option>
                                        {
                                            unidades &&
                                            unidades.map( (unidad) => (
                                                <option key={ unidad.id_unidad } value={ unidad.id_unidad }>{ unidad.nombre }</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="id_proceso">
                                        Proceso al que pertenece el pago:
                                    </label>
                                    <br/>
                                    <select 
                                        className="input selectpicker"
                                        id="id_proceso"
                                        name="id_proceso"
                                        data-live-search="true"
                                        required
                                    >
                                        <option value="">Elige un proceso</option>
                                        {
                                            procesos &&
                                            procesos.map( (proceso) => (
                                                <option key={ proceso.fecha_alta } value={ `${proceso.id_proceso}_${proceso.id_proceso_expediente}` }>
                                                    { `ID ${proceso.id_proceso_expediente}. ${proceso.nombre}` }
                                                    {proceso.dientes !== null && ' - Dientes: '}
                                                    {proceso.dientes !== null && proceso.dientes.map(
                                                        (diente, index) => diente.numero+((index !== proceso.dientes.length - 1) ? ', ' : '')
                                                    )}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="id_empleado_responsable_unidad">
                                        Trabajador a quien pertenece el pago:
                                    </label>
                                    <br/>
                                    <select 
                                        className="input selectpicker"
                                        id="id_empleado_responsable_unidad"
                                        name="id_empleado"
                                        required
                                    >
                                        <option value="">Elige un trabajador</option>
                                        {
                                            empleados &&
                                            empleados.map( (empleado) => (
                                                <option key={ empleado.id_empleado } value={ empleado.id_empleado }>{ empleado.nombre }</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary" id='btnAddIngresoExpediente'>
                            { elementToEdit !== null ? "Actualizar" : "Agregar" }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}