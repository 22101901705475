import React from 'react';
import './Expedientes.css';
import { useHistory } from "react-router-dom";
// import Icon from "./../../components/Icon";
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import InputSearch from "./../../components/InputSearch";
import AddButton from "./../../components/AddButton";
import LoadingIndicator from "../../components/LoadingIndicator";
import AlertWarningSearch from "../../components/AlertWarningSearch";
// import FilterButton from "../../components/FilterButton";
import getExpedientes from './../../services/expediente/getExpedientes';
import searchExpedientes from './../../services/expediente/searchExpedientes';import ModalAddCitaCompletadaExpediente from '../../components/ModalAddCitaCompletadaExpediente';
import Swal from 'sweetalert2';
import $ from 'jquery'
import addCita from '../../services/cita/addCita';

export default function Expedientes(props){

    let { modulosPermisos } = props
    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filters, setFilters] = React.useState([])
    const [orderBy, setOrderBy] = React.useState({})
    const [terminoBusqueda, setTerminoBusqueda] = React.useState("")
    const [folioAddCita, setFolioAddCita] = React.useState(null)
    // const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    // const [loadingFilters, setLoadingFilters] = React.useState(false)

    // History de la navegación
    let history = useHistory();

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Total pagado" },
        { id: 3, name: "Total presupuesto" },
        { id: 4, name: "Último pago" }
    ];
    
    function getExpedientesService(){
        setLoading(true)
        if(filters.length !== 0 || Object.keys(orderBy).length !== 0 || terminoBusqueda !== ""){
            search()
        } else {
            getExpedientes().then(response => {
                // console.log(response)
                let _respuesta = JSON.parse(response);
                if(!(_respuesta.response === "error")){
                    setElements(_respuesta.data);
                }
                setLoading(false)
            }).catch((error) => {
                // console.log(error)
                // console.log("ha petado")
            })
        }
    }

    const search = (q) =>{
        setLoading(true)
        let data = {}

        if(terminoBusqueda !== "")
            data.q = terminoBusqueda
        if(filters.length !== 0)
            data.filtros = filters
        if(Object.keys(orderBy).length !== 0)
            data.ordenar = orderBy

        searchExpedientes({values:data})
            .then((response) =>{
                // console.log(response)
                let _response = JSON.parse(response)
                if(_response.response === "success"){
                    setElements(_response.data)
                } else {
                    setElements([])
                }
                setLoading(false)
            })
            .catch((error)=>{
                // console.log(error)
            })
    }

    const onSubmitSearch = (e) =>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        let terminoBusqueda = objectValues.q
        setTerminoBusqueda(terminoBusqueda)
    }

    const onSubmitAddCitaCompletada = (e) => {
        e.preventDefault()

        // Desactivamos el botón submit del formulario
        let btnSubmit = document.getElementById('btnModalAddCitaCompletadaExpedienteSubmit');
        btnSubmit.disabled = true;
        btnSubmit.innerHTML = 'Agregando...';

        let form = new FormData(e.target)
        let data = Object.fromEntries(form)
        data.folio = folioAddCita.folio
        let termino_tratamiento = 0;
        if(data.termino_tratamiento === "on"){
            termino_tratamiento = 1;
        }
        data.termino_tratamiento = termino_tratamiento;
        // console.log({data})
        addCita({values:data})
            .then(res => {
                console.log(res)
                let _res = JSON.parse(res);
                if(_res.response === "success"){
                    setFolioAddCita(null)
                    Swal.fire({
                        title: "Se agregó correctamente",
                        timer: 1200,
                        showConfirmButton: false,
                        icon: "success"
                    })
                    // getMaterialesService()
                    $("#modalAddCitaCompletadaExpediente").modal("hide")
                } else {
                    Swal.fire({
                        title: "No se agregó",
                        timer: 1200,
                        showConfirmButton: false,
                        icon: "info"
                    })
                }

                // Activamos el botón submit del formulario de nuevo
                btnSubmit.disabled = false;
                btnSubmit.innerHTML = 'Agregar';

            }).catch((error) => {
                console.log(error)
                // console.log("ha petado")
            })
    }

    const onClickOrderBy = (campo, orden) => {
        let data = {
            campo: campo,
            orden: orden
        }
        setOrderBy(data)
    }

    React.useEffect(() => {
        getExpedientesService()
    },[filters, terminoBusqueda, orderBy]);

    return(
        <section>

            <div className="d-flex justify-content-between">

                <div className="d-flex justify-content-between">    

                    <h1>Expedientes</h1>

                    {
                        modulosPermisos.expedientes.escribir === '1' &&
                            <AddButton text="Agregar expediente" onClick={ async () => {
                                history.push("/expedientes/agregar")
                            } } />
                    }
                    
                </div>
            </div>
            
            <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <InputSearch textPlaceholder="Buscar expediente" onSubmit={onSubmitSearch}/>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-md-3 mt-sm-3 mt-3">
                    <div className="d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-end">

                        <div className="dropdown mx-2">
                            <button className="btn btn-sm btn-light dropdown-toggle text-muted" data-toggle="dropdown" aria-expanded="false">
                                <i className="fa-solid fa-arrow-down-short-wide mr-2"></i>
                                    Ordenar
                            </button>

                            <div className="dropdown-menu">
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','ASC')}>Nombre de A-Z</button>
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','DESC')}>Nombre de Z-A</button>
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','ASC')}>Fecha alta de A-Z</button>
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','DESC')}>Fecha alta de Z-A</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="my-3" />

            {/* <div className="d-flex justify-content-between align-items-center">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton text="Estatus" type="estatus" onSubmit={onSubmitFilter} />
                                <FilterButton text="Fecha alta" type="fecha_alta" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <button className={`btn btn-sm btn-light text-muted ${!showDeleteFilters && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                    <Icon icon='fa-solid fa-x' /> Eliminar
                </button>

            </div> */}

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="expedientes" 
                            permisosUsuario={modulosPermisos} 
                            setElementToEdit={setFolioAddCita}
                        />
                    </Table>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

            {
                <ModalAddCitaCompletadaExpediente 
                    folio={folioAddCita}
                    onSubmitModal={onSubmitAddCitaCompletada}
                />
            }

        </section>
    );
}