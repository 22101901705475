import React from "react";
import $ from 'jquery';
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import getMetodosPago from "../../services/ingreso/getMetodosPago";
import getEmpleados from "../../services/empleado/getEmpleados";
import getTipoEgresosActive from "../../services/tipo_egreso/getTipoEgresosActive";

export default function ModalAddEgresoUnidad({id_unidad, id_empleado_responsable_unidad, onSubmitModal, elementToEdit}){

    const [empleados, setEmpleados] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [tipoGasto, setTipoGasto] = React.useState([]);
    const [loading, setLoading] = React.useState(true)

    const getMetodosPagoService = ()=>{
        setLoading(true)
        getMetodosPago({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMetodosPago(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    function getTipoEgresosService(){
        getTipoEgresosActive()
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setTipoGasto(_res.data)
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function getEmpleadosService(){
        getEmpleados({values:{}})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setEmpleados(_res.data)
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const setDataFormulario = ()=>{
        console.log(elementToEdit)
        if(elementToEdit !== null){
            $('#id_tipo_gasto option[value="'+ elementToEdit.id_tipo_gasto +'"]').prop('selected', true);
            $('#id_metodo_pago option[value="'+ elementToEdit.id_metodo_pago +'"]').prop('selected', true);
            if(elementToEdit.id_empleado !== null){
                $('#id_empleado_egreso option[value="'+ elementToEdit.id_empleado +'"]').prop('selected', true);
            } else {
                $("#id_empleado_egreso option:first").prop('selected',true);
            }
            $('#folio option[value="'+ elementToEdit.folio +'"]').prop('selected', true);
            // onChangeUnidad(elementToEdit.id_unidad);
            document.getElementById("numero_recibo").value = elementToEdit.numero_recibo
            document.getElementById("fecha_del_pago_egreso").value = elementToEdit.fecha_del_pago_sin_formato
            document.getElementById("cantidad_egreso").value = elementToEdit.cantidad
            document.getElementById("descripcion_egreso").value = elementToEdit.descripcion
        } else {
            $("#formAddEgresoUnidad")[0].reset();
            document.getElementById('fecha_del_pago_egreso').valueAsDate = new Date();
        }
        $('.selectpicker').selectpicker('refresh');
    }

    React.useEffect(getMetodosPagoService, []);
    React.useEffect(getTipoEgresosService, []);
    React.useEffect(getEmpleadosService, []);
    React.useEffect(setDataFormulario, [elementToEdit])

    return(
        <div className="modal" id="modalAddEgresoUnidad" tabIndex="-1" role="dialog" aria-labelledby="modalAddEgresoUnidadLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} id="formAddEgresoUnidad" className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddEgresoUnidadLabel">Agrega un egreso de la unidad</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="fecha_del_pago_egreso">
                                    Fecha del pago:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_del_pago_egreso"
                                    name="fecha_del_pago"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="cantidad_egreso">
                                    Cantidad del pago:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="cantidad_egreso"
                                    name="cantidad"
                                    placeholder="$"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_metodo_pago">
                                    Método de pago:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_metodo_pago"
                                    name="id_metodo_pago"
                                    required
                                >  
                                    <option value="">Elige un método de pago</option>
                                    {
                                        metodosPago && 
                                        metodosPago.map( metodo_pago => (
                                            <option key={ metodo_pago.id_metodo_pago } value={ metodo_pago.id_metodo_pago }>
                                                { metodo_pago.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_tipo_gasto">
                                    Tipo de gasto:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_tipo_gasto"
                                    name="id_tipo_gasto"
                                    data-live-search="true"
                                    required
                                >  
                                    <option value="">Elige un concepto</option>
                                    {
                                        tipoGasto && 
                                        tipoGasto.map( tipo_gasto => (
                                            <option key={ tipo_gasto.id_tipo_gasto } value={ tipo_gasto.id_tipo_gasto }>
                                                { tipo_gasto.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="id_empleado_egreso">
                                    Trabajador a quien pertenece el egreso:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_empleado_egreso"
                                    name="id_empleado"
                                >
                                    <option value="">Opcional</option>
                                    {
                                        empleados &&
                                        empleados.map( (empleado) => (
                                            <option key={ empleado.id_empleado } value={ empleado.id_empleado }>{ empleado.nombre }</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion_egreso">
                                    Descripción detallada del pago:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="Sé lo más detallado posible (Opcional)"
                                    className="input textarea"
                                    id="descripcion_egreso"
                                    name="descripcion"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary" id="btnAddEgresoUnidad">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}