import React from 'react'
import './Login.css'
// import { validateEmail } from "./../../validators/validateEmail"
import logo from "../../asset/images/logo_divum.png"
import login from './../../services/user/login'
import AlertError from '../../components/AlertError'

export default function Login(props){

    // Estado del manejo de errores en el input
    const [errors, setErrors] = React.useState({});

    // Función que hará para validar todos los input
    const handleErrors = (values)=>{
        const err = {};
        if(!values.nombre_usuario){
            err.nombre_usuario = "El usuario es obligatorio";
        }
        if(!values.password){
            err.password = "La contraseña es obligatoria";
        }
        setErrors(err);
        return (err && Object.keys(err).length === 0) ? false : true;
    }

    // Función que borra un error del input
    const cleanError = (e) =>{
        setErrors({...errors, [e.target.name]: ""})
    }

    // Función que manejará el comportamiento del login
    const handleSubmitLogin = (e) =>{
        e.preventDefault();
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        if(!handleErrors(objectValues)){
            // Aquí se hará el llamado a la API
            login({values:objectValues}).then((response) => {
                // // console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === "error"){
                    AlertError({
                        titulo: 'Datos incorrectos'
                    })
                } else {
                    props.setIdUsuario(_response.id_usuario)
                    localStorage.setItem('idUsuario', _response.id_usuario);

                    if(_response.permisos !== undefined && _response.permisos !== null){
                        props.setPermisos(_response.permisos)
                        localStorage.setItem('permisos', JSON.stringify(_response.permisos));
                    }

                    props.setLogin(true)
                }
            }).catch((error) => {
                // console.log({error})
                AlertError({
                    titulo: 'Servidor no responde'
                })
            })
        }
    }

    return (
        <div className="login">
            <div className="login__background" />
            <div className="login__welcomeText">
                <img src={logo} alt="AUSE" className="img-fluid" />
                {/* <h1>Bienvenido</h1> */}
                <p>Por favor ingresa tus credenciales de acceso:</p>
            </div>

            <div className="login__form">
                <form onSubmit={ handleSubmitLogin }>
                    <input 
                        className={`input ${(errors.nombre_usuario !== "" && errors.nombre_usuario !== undefined) && "input-error"}`} 
                        type="text" 
                        placeholder="Nombre de usuario" 
                        name="nombre_usuario" 
                        onChange={ cleanError } />
                    <small className="error"> { errors.nombre_usuario } </small>
                    
                    <input 
                        className={`input ${(errors.password !== "" && errors.password !== undefined) && "input-error"}`} 
                        type="password" 
                        placeholder="Contraseña" 
                        name="password" 
                        onChange={ cleanError } />
                    <small className="error"> { errors.password } </small>

                    <button className="btn btn-gris btn-block" type="submit">Entrar</button>
                </form>
            </div>

            <div className="login__footer">
                <p>© 2023 DIVUM</p>
                <a href="mailto:ricardosandoval@cursodontic.com">Contactar al desarrollador</a>
            </div>

        </div>
    );
}