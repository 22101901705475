import React from "react";
import getPagosExpediente from "../../services/expediente/getPagosExpediente";
import AddButton from "../AddButton";
import AlertWarningSearch from "../AlertWarningSearch";
import LoadingIndicator from "../LoadingIndicator";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import $ from 'jquery';
import getIngresosTotalesExpediente from "../../services/expediente/getIngresosTotalesExpediente";
import addIngreso from "../../services/ingreso/addIngreso";
import CardIngresosUnidad from "../CardIngresosUnidad";
import Swal from "sweetalert2";
import ModalAddIngresoExpediente from "../ModalAddIngresoExpediente";
import ModalEmitirReembolso from "../ModalEmitirReembolso";
import emitReembolso from "../../services/ingreso/emitReembolso";
import FilterButton from "../FilterButton";
import Icon from "../Icon";
import getDoctoresQueHanRecibidoPagos from "../../services/expediente/getDoctoresQueHanRecibidoPagos";
import updateIngreso from "../../services/ingreso/updateIngreso";

export default function ExpedientePagos(props){

    let { modulosPermisos, folio } = props

    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    // VARIABLES PARA LOS INGRESOS
    const [loading, setLoading] = React.useState(true);
    const [ingresos, setIngresos] = React.useState([]);
    const [ingresosTotales, setIngresosTotales] = React.useState({})
    const [idIngresoReembolso, setIdIngresoReembolso] = React.useState(0);
    const [doctoresRecibieronPago, setDoctoresRecibieronPago] = React.useState([])
    
    const [idDoctorIngresos, setIdDoctorIngresos] = React.useState(null)
    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [loadingFilters, setLoadingFilters] = React.useState(false)
    const [elementToEdit, setElementToEdit] = React.useState(null)

    const rowsIngresos = [
        { id: 1, name: "No. de recibo" },
        { id: 2, name: "Proceso" },
        { id: 3, name: "Cantidad" },
        { id: 4, name: "Descripción" }
    ];

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    function getDoctoresQueHanRecibidoPagosService(){
        setLoading(true)
        let data = {
            folio: folio
        }
        getDoctoresQueHanRecibidoPagos({values:data}).then(res => {
            console.log(res)
            let _res = JSON.parse(res);
            // console.log(_res)
            if(_res.response === "success"){
                setDoctoresRecibieronPago(_res.data);
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
        })
    }

    function getPagosExpedienteService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            folio: folio
        }
        if(idDoctorIngresos !== null){
            data.id_empleado = idDoctorIngresos
        }
        getPagosExpediente({values:data}).then(res => {
            console.log(res)
            let _res = JSON.parse(res);
            // console.log(_res)
            if(_res.response === "success"){
                setIngresos(_res.data);
            } else {
                setIngresos([]);
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
        })
    }

    function getIngresosTotalesExpedienteService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            folio: folio
        }
        if(idDoctorIngresos !== null){
            data.id_empleado = idDoctorIngresos
        }
        getIngresosTotalesExpediente({values:data}).then(res => {
            console.log(res)
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setIngresosTotales(_res.data);
            } else {
                setIngresosTotales([]);
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
        })
    }

    const onSubmitModal = (e)=>{
        $('#btnAddIngresoExpediente').prop('disabled',true)
        e.preventDefault()
        let values = new FormData(e.target)
        values.append('folio', folio);
        let objectValues = Object.fromEntries(values);
        
        let id_proceso = objectValues.id_proceso.split("_")[0];
        let id_proceso_expediente = objectValues.id_proceso.split("_")[1];
        objectValues.id_proceso = id_proceso;
        objectValues.id_proceso_expediente = id_proceso_expediente

        console.log({objectValues})

        addIngreso({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Agregado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddIngresoExpediente").modal("hide")
                $("#formAddIngresoExpediente")[0].reset();
                $(".selectpicker").selectpicker('refresh')
                getPagosExpedienteService()
                getIngresosTotalesExpedienteService()
            }
            $('#btnAddIngresoExpediente').prop('disabled',false)
        })
    }

    const onUpdateModal = (e)=>{
        $('#btnAddIngresoExpediente').prop('disabled',true)
        e.preventDefault()
        let values = new FormData(e.target)
        values.append('folio', folio);
        values.append('id_ingreso', elementToEdit.id_ingreso);
        let objectValues = Object.fromEntries(values);
        let id_proceso = objectValues.id_proceso.split("_")[0];
        let id_proceso_expediente = objectValues.id_proceso.split("_")[1];
        objectValues.id_proceso = id_proceso;
        objectValues.id_proceso_expediente = id_proceso_expediente
        console.log({objectValues})
        updateIngreso({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Actualizado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddIngresoExpediente").modal("hide")
                $("#formAddIngresoExpediente")[0].reset();
                $(".selectpicker").selectpicker('refresh')
                getPagosExpedienteService()
                getIngresosTotalesExpedienteService()
            }
            $('#btnAddIngresoExpediente').prop('disabled',false)
        })
    }

    const onSubmitReembolso = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        values.append('id_ingreso', idIngresoReembolso);
        let objectValues = Object.fromEntries(values);
        emitReembolso({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Reembolso registrado',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalEmitirReembolso").modal("hide")
                $("#formEmitirReembolso")[0].reset();
                $(".selectpicker").selectpicker('refresh')
                getPagosExpedienteService()
                getIngresosTotalesExpedienteService()
            }
        })
    }

    const reloadIngresos = ()=>{
        getPagosExpedienteService()
        getIngresosTotalesExpedienteService()
    }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        setIdDoctorIngresos(object.id_empleado)
    }

    const onClickDeleteFilters = () => {
        setIdDoctorIngresos(null)
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(()=>{
            setLoadingFilters(false)
        }, 50)
    }

    React.useEffect(getPagosExpedienteService, [])
    React.useEffect(getIngresosTotalesExpedienteService, [])
    React.useEffect(getDoctoresQueHanRecibidoPagosService, [])
    React.useEffect(() => $('#fecha_inicial').val(fechaInicio), [])

    React.useEffect(() => {
        reloadIngresos()
    },[fechaInicio, fechaFinal, idDoctorIngresos]);

    return(
        <>
            <CardIngresosUnidad
                total_ingresos_efectivo={ingresosTotales.total_ingresos_efectivo}
                total_ingresos_banco={ingresosTotales.total_ingresos_banco}
                total_ingresos={ingresosTotales.total_ingresos}
            />

            <ModalAddIngresoExpediente 
                folio={folio}
                onSubmitModal={elementToEdit === null ? onSubmitModal : onUpdateModal}
                elementToEdit={elementToEdit}
            />

            <ModalEmitirReembolso
                onSubmitModal={onSubmitReembolso}
            />

            <div className="mt-5 mb-4">    
                <div className="d-flex align-items-center">
                    <h4 className="">Pagos del expdiente</h4>

                    {
                        modulosPermisos.ingresos.escribir === '1' &&
                            <AddButton text="Agregar ingreso" onClick={ async () => {
                                setElementToEdit(null)
                                $('#modalAddIngresoExpediente').modal('show')
                            } } />
                    }

                </div>

                <form onSubmit={onSubmitFechas} className={`d-flex justify-content-between align-items-center mt-3`}>
                    <input width={"20px"} id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                    <p className="mx-2">y</p>
                    <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                    <button className='btn btn btn-gris ml-2'><i className="fa-solid fa-search"></i></button>
                </form>
            </div>

            <hr className="my-2" />

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton elements={doctoresRecibieronPago} text="Doctor" type="id_empleado" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <button className={`btn btn-sm btn-light text-muted ${!showDeleteFilters && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                    <Icon icon='fa-solid fa-x' /> Eliminar
                </button>

            </div>

            <LoadingIndicator show={loading} />

            {
                ( ingresos.length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rowsIngresos} />

                        <TableBody 
                            setIdIngresoReembolso={setIdIngresoReembolso} 
                            reload={reloadIngresos} 
                            elements={ingresos} 
                            typeElement="ingresos_expediente" 
                            permisosUsuario={modulosPermisos}
                            setElementToEdit={setElementToEdit}
                        />
                    </Table>
            }

            {
                ( ingresos.length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }
        </>
    )
}