import React from 'react';
import $ from 'jquery';

export default function TableElementLog({ element, permisosUsuario, setElementToEdit }) {

    let { 
        id_history,
        accion,
        nombre_tabla,
        id_tabla,
        fecha_alta,
        nombre_usuario
    } = element;

    return (
        <tr key={id_history}>
            <th>
                <div className="table__name">
                    <button 
                        type="button" 
                        className="btn" 
                        onClick={
                            () => {
                                setElementToEdit(element)
                                $("#modalViewLog").modal('show')
                            }
                        } 
                    >
                        <i className="fa-solid fa-history" title="Logs"></i>    
                    </button>

                    {/* {
                        (foto_perfil) &&
                            <img src={foto_perfil} className="img-fluid" alt="Usuario" />
                    } */}

                    <div className="table__nameDescription">
                        <p>{accion}</p>
                        <small>Realizado por: {nombre_usuario}</small>
                    </div>
                </div>
            </th>

            <td>
                <p>{nombre_tabla}</p>
            </td>

            <td>
                <p>{id_tabla}</p>
            </td>

            <td>
                <p>{fecha_alta}</p>
            </td>
        </tr>
    )
    
}