import $ from 'jquery';
import config from './../../config';
const URL_API = config.urlApi+'controlador/proceso_expediente/updateNoFinalizadoProcesoExpediente.php';

export default function updateNoFinalizadoProcesoExpediente({values}){
    const idUsuario = localStorage.getItem("idUsuario")
    values = {...values, id: idUsuario}
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values
    })
}