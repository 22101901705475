import React from 'react';
// import getUnidades from '../services/unidades/getUnidades';
import { ConnectionError, handleCallApiError } from '../validators/errors';
import getUnidadEmpleados from '../services/unidades/getUnidadEmpleados';

export default function useEmpleados(){

    const [empleados, setEmpleados] = React.useState([]);
    const [loadingEmpleados, setLoadingEmpleados] = React.useState(true);

    const getEmpleadosByUnidadService = (id_unidad) => {
        setLoadingEmpleados(true)
        let data = { id_unidad }
        getUnidadEmpleados({values:data})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setEmpleados(_res.data)
                    setLoadingEmpleados(false)
                    // $("#id_empleado_responsable_unidad").attr("disabled",false)
                    // if(elementToEdit !== null){
                    //     document.getElementById("id_empleado_responsable_unidad").value = elementToEdit.id_empleado
                    //     $('.selectpicker').selectpicker('refresh');
                    // }
                }
            })
            .catch(e => {
                setLoadingEmpleados(false)
                // Intentar conectar de nuevo en unos segundos
                if(e instanceof ConnectionError){
                    handleCallApiError("ConnectionError")
                }
            })
    }

    return { 
        empleados, 
        loadingEmpleados,
        getEmpleadosByUnidadService 
    }

}