import React from "react";
import getGeneros from "../../services/empleado/getGeneros";
import $ from 'jquery'
import Icon from "../Icon";
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import LoadingIndicator from "../LoadingIndicator";
import getUnidades from "../../services/unidades/getUnidades";
import searchProcesos from "../../services/proceso/searchProcesos";
import Swal from "sweetalert2";

export default function FormularioExpediente({expediente, onSubmit, modulosPermisos}){

    const [generos, setGeneros] = React.useState([]);
    const [procesos, setProcesos] = React.useState([]);
    const [unidades, setUnidades] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const [tratamientos, setTratamientos] = React.useState([])

    function getGenerosService(){
        setLoading(true)
        getGeneros()
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setGeneros(_res.data)
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const getUnidadesService = ()=>{
        setLoading(true)
        getUnidades({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setUnidades(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    function getProcesosByUnidadService(id_unidad){
        let filtros = []
        filtros.push({ campo: 'id_unidad', data: {id_unidad: id_unidad} })
        let data = {
            filtros: filtros
        }
        searchProcesos({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setProcesos(_res.data)
                    $("#id_proceso").attr("disabled",false)
                } else {
                    setProcesos([])
                    $("#id_proceso").attr("disabled",true)
                }
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function onChangeUnidad(value){
        getProcesosByUnidadService(value)
    }

    const setDataFormulario = ()=>{
        document.getElementById('nombre').value = expediente.nombre;
        document.getElementById('apellido_paterno').value = expediente.apellido_paterno;
        document.getElementById('apellido_materno').value = expediente.apellido_materno;
        document.getElementById('fecha_nacimiento').value = expediente.fecha_nacimiento;
        document.getElementById('id_genero').value = expediente.id_genero;
        document.getElementById('numero_celular').value = expediente.numero_celular;
        document.getElementById('numero_local').value = expediente.numero_local;
        document.getElementById('correo').value = expediente.correo;
        document.getElementById('curp').value = expediente.curp;
        document.getElementById('rfc').value = expediente.rfc;
        document.getElementById('direccion').value = expediente.direccion;
        document.getElementById('numero_hijos').value = expediente.numero_hijos;
        document.getElementById('ocupacion').value = expediente.ocupacion;
        document.getElementById('folio').value = expediente.folio;
        document.getElementById('fecha_alta_primera_vez').value = expediente.fecha_alta_primera_vez;
        document.getElementById('ha_tenido_covid').value = expediente.ha_tenido_covid;
        document.getElementById('nombre_responsable_paciente').value = expediente.nombre_responsable_paciente;
        document.getElementById('numero_responsable_paciente').value = expediente.numero_responsable_paciente;
        document.getElementById('referencia').value = expediente.referencia;
        document.getElementById('ubicacion_fisica_expediente').value = expediente.ubicacion_fisica_expediente;
        document.getElementById('peso').value = expediente.peso;
        document.getElementById('talla').value = expediente.talla;
        document.getElementById('grupo_sanguineo').value = expediente.grupo_sanguineo;
        document.getElementById('temperatura').value = expediente.temperatura;
        document.getElementById('frecuencia_respiratoria').value = expediente.frecuencia_respiratoria;
        document.getElementById('pulso').value = expediente.pulso;
        document.getElementById('rh').value = expediente.rh;
        document.getElementById('alergias').value = expediente.alergias;
        document.getElementById('padecimientos').value = expediente.padecimientos;
        document.getElementById('motivo_consulta').value = expediente.motivo_consulta;

        $('.selectpicker').selectpicker('refresh');
        if(modulosPermisos.expedientes.editar === "0"){
            $("#formularioExpediente :input, :input[form]").prop("readonly", true);
        }
    }

    function handleAddProcesoExpediente(){
        let id_unidad = document.getElementById('id_unidad')
        let value_id_unidad = id_unidad.value
        
        let id_proceso = document.getElementById('id_proceso')
        let value_id_proceso = id_proceso.value

        let fecha_inicio_proceso = document.getElementById('fecha_inicio_proceso')
        let precio_final = document.getElementById('precio_final')

        if(value_id_unidad === ''){
            Swal.fire({text:'Selecciona una unidad', icon:'warning', timer:1000, showConfirmButton:false})
            return;
        }
        if(value_id_proceso === ''){
            Swal.fire({text:'Selecciona un proceso', icon:'warning', timer:1000, showConfirmButton:false})
            return;
        }
        if(fecha_inicio_proceso.value === ''){
            Swal.fire({text:'Elige la fecha en que comenzó este proceso', icon:'warning', timer:1000, showConfirmButton:false})
            return;
        }
        if(precio_final.value === ''){
            Swal.fire({text:'Escribe el precio total', icon:'warning', timer:1000, showConfirmButton:false})
            return;
        }

        let text_id_unidad = id_unidad.options[id_unidad.selectedIndex].text
        let text_id_proceso = id_proceso.options[id_proceso.selectedIndex].text

        let data = {
            id_unidad: value_id_unidad,
            text_id_unidad: text_id_unidad,
            id_proceso: value_id_proceso,
            text_id_proceso: text_id_proceso,
            fecha_inicio_proceso: fecha_inicio_proceso.value,
            precio_final: precio_final.value
        }

        id_unidad.value = ''
        id_proceso.value = ''
        precio_final.value = ''

        let nuevoTratamiento = [...tratamientos]
        nuevoTratamiento.push(data)
        setTratamientos(nuevoTratamiento)
        document.getElementById('tratamientos').value = JSON.stringify(nuevoTratamiento)
    }

    React.useEffect(getGenerosService, []);
    React.useEffect(getUnidadesService, []);

    React.useEffect(()=>{
        // Si existe un expediente por parametro, asignarle los valores al formulario
        // Solo una vez se haya cargado todo
        if(expediente !== undefined && !loading)
            setDataFormulario()
    },[loading])

    return(
        <>
            <LoadingIndicator show={loading} />

        {
            !loading &&
            
            <form className="formulario" id="formularioExpediente" onSubmit={ onSubmit }>

                {/* ******************************* */}
                {/* BLOQUE DE LOS DATOS PRINCIPALES */}
                {/* ******************************* */}

                <div className='mt-4'>
                    <h3 className='my-4'>
                        Datos del paciente
                    </h3>

                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="nombre">
                                Nombre(s):
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="nombre"
                                name="nombre"
                                required
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="apellido_paterno">
                                Apellido paterno:
                            </label>
                            <input 
                                type="text" 
                                className="input" 
                                id="apellido_paterno"
                                name="apellido_paterno"
                                required
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="apellido_materno">
                                Apellido materno:
                            </label>
                            <input 
                                type="text"
                                className="input" 
                                id="apellido_materno"
                                name="apellido_materno"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="fecha_nacimiento">
                                Fecha nacimiento:
                            </label>
                            <input 
                                type="date"
                                className="input"
                                id="fecha_nacimiento"
                                name="fecha_nacimiento"
                                />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="id_genero">
                                Género:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_genero"
                                name="id_genero"
                                required
                            >
                                {
                                    generos && 
                                    generos.map( genero => (
                                        <option key={ genero.id_genero } value={ genero.id_genero }>
                                            { genero.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="numero_celular">
                                Número de celular:
                            </label>
                            <input 
                                type="tel" 
                                placeholder="#########" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="numero_celular"
                                name="numero_celular"
                                required
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="numero_local">
                                Número local:
                            </label>
                            <input 
                                type="tel" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="numero_local"
                                name="numero_local"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="correo">
                                Correo electrónico:
                            </label>
                            <input 
                                type="email" 
                                className="input"
                                id="correo"
                                name="correo"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="curp">
                                CURP:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="curp"
                                name="curp"
                                maxLength="20"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="rfc">
                                RFC:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="rfc"
                                name="rfc"
                                maxLength="20"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                            <label htmlFor="direccion">
                                Dirección:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="direccion"
                                name="direccion"
                            />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label htmlFor="numero_hijos">
                                Número de hijos:
                            </label>
                            <input 
                                type="tel" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                id="numero_hijos"
                                name="numero_hijos"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="ocupacion">
                                Ocupación:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="ocupacion"
                                name="ocupacion"
                                placeholder="Opcional"
                            />
                        </div>
                    </div>
                </div>
                
                {/* ******************************* */}
                {/* BLOQUE DE LOS DATOS DEL EXPEDIENTE */}
                {/* ******************************* */}

                <div className='mt-4'>
                    <h3 className='my-4'>
                        Datos del expediente
                    </h3>

                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label htmlFor="folio">
                                Folio del expediente:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="folio"
                                name="folio"
                                required
                            />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label htmlFor="fecha_alta_primera_vez">
                                Fecha de alta primera vez:
                            </label>
                            <input 
                                type="date" 
                                className="input" 
                                id="fecha_alta_primera_vez"
                                name="fecha_alta_primera_vez"
                                required
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label htmlFor="ha_tenido_covid">
                                Tuvo COVID?
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="ha_tenido_covid"
                                name="ha_tenido_covid"
                            >
                                <option key={1} value={ 1 }>
                                    Si
                                </option>
                                <option key={0} value={ 0 }>
                                    No
                                </option>
                                <option key={0} value={ 2 }>
                                    No lo sé
                                </option>
                            </select>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="nombre_responsable_paciente">
                                Nombre de su responsable:
                            </label>
                            <input 
                                type="text"
                                className="input" 
                                id="nombre_responsable_paciente"
                                name="nombre_responsable_paciente"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="numero_responsable_paciente">
                                Número de su responsable:
                            </label>
                            <input 
                                type="tel"
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                id="numero_responsable_paciente"
                                name="numero_responsable_paciente"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="referencia">
                                Referencia:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="referencia"
                                name="referencia"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="ubicacion_fisica_expediente">
                                Ubicación del expediente:
                            </label>
                            <input 
                                type="text" 
                                placeholder="Ejemplo: En recepcion casillero X" 
                                className="input"
                                id="ubicacion_fisica_expediente"
                                name="ubicacion_fisica_expediente"
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label htmlFor="peso">
                                Peso en KG:
                            </label>
                            <input 
                                type="tel" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="peso"
                                name="peso"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label htmlFor="talla">
                                Talla en CM:
                            </label>
                            <input 
                                type="tel" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                id="talla"
                                name="talla"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="grupo_sanguineo">
                                Grupo sanguíneo:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="grupo_sanguineo"
                                name="grupo_sanguineo"
                                maxLength="20"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label htmlFor="temperatura">
                                Temperatura:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="temperatura"
                                name="temperatura"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label htmlFor="frecuencia_respiratoria">
                                Frec. respiratoria:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="frecuencia_respiratoria"
                                name="frecuencia_respiratoria"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label htmlFor="pulso">
                                Pulso:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="pulso"
                                name="pulso"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label htmlFor="rh">
                                RH:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="rh"
                                name="rh"
                                maxLength="20"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="alergias">
                                Alergias:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="alergias"
                                name="alergias"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <label htmlFor="padecimientos">
                                Padecimientos:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="padecimientos"
                                name="padecimientos"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <label htmlFor="motivo_consulta">
                                Motivo de la consulta:
                            </label>
                            <textarea 
                                rows={5}
                                cols={5}
                                type="text"
                                placeholder="Sé lo más detallado posible"
                                className="input textarea"
                                id="motivo_consulta"
                                name="motivo_consulta"
                                required
                            />
                        </div>
                    </div>
                </div>

                { 
                (expediente === undefined) &&
                    <div className='mt-4'>
                        <h3 className='my-4'>
                            Tratamientos del expediente
                        </h3>

                        <p>
                            Esto es opcional, no siempre hay pacientes que solicitan un tratamiento a la primera, tal vez sólo fueron a diagnóstico. Pero sí ya existen tratamientos asociados a este expediente agrégalos aquí:
                        </p>

                        <div className="row my-4">
                            <div className="col-xl-3 col-lg-3">
                                <label htmlFor="id_unidad">
                                    Unidad:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_unidad"
                                    name="id_unidad"
                                    data-live-search="true"
                                    onChange={(e) => onChangeUnidad(e.target.value)}
                                >
                                    <option value="">Elige una unidad</option>
                                    {
                                        unidades && 
                                        unidades.map( unidad => (
                                            <option key={ unidad.id_unidad } value={ unidad.id_unidad }>
                                                { unidad.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <label htmlFor="id_proceso">
                                    Proceso:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_proceso"
                                    name="id_proceso"
                                    disabled
                                    data-live-search="true"
                                >   
                                    {
                                        procesos && 
                                        procesos.map( proceso => (
                                            <option key={ proceso.id_proceso } value={ proceso.id_proceso }>
                                                { proceso.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <label htmlFor="fecha_inicio_proceso">
                                    Día que comenzó el proceso:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_inicio_proceso"
                                    name="fecha_inicio_proceso"
                                />
                            </div>
                            <div className="col-xl-2 col-lg-2">
                                <label htmlFor="precio_final">
                                    Precio total:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    onKeyPress={ validateNoNumbers }
                                    id="precio_final"
                                    name="precio_final"
                                    placeholder="$"
                                />
                            </div>
                            <div className="col-xl-1 col-lg-1 d-flex justify-content-center align-items-center">
                                <button 
                                    type="button" 
                                    className="btn btn-verde btn-lg"
                                    onClick={handleAddProcesoExpediente}
                                >
                                    <Icon icon="fa-solid fa-plus" />
                                </button>
                            </div>
                        </div>

                        <ul>
                            {
                            tratamientos &&
                                tratamientos.map(tratamiento => {
                                    return (
                                        <li key={tratamiento.id_proceso} className="d-flex align-items-center">
                                            <button type="button" className="btn color-rojo">
                                                <Icon icon="fa-solid fa-circle-minus" />
                                            </button>
                                            <p>
                                                {tratamiento.text_id_unidad} - {tratamiento.text_id_proceso} - ${tratamiento.precio_final} el {tratamiento.fecha_inicio_proceso}
                                            </p>
                                        </li>
                                    )
                                    
                                })
                            }
                        </ul>

                        <input type='text' defaultValue={JSON.stringify(tratamientos)} name='tratamientos' id='tratamientos' className="hidden" />
                    </div>
                }

                
                
                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr className='my-4' /> 

                <div className="d-flex justify-content-end">
                    
                    {
                        (expediente !== undefined && modulosPermisos.expedientes.editar === '1') &&
                            <button className="btn btn-azul noMargin">
                                <Icon icon="fa-solid fa-file-medical mr-3" />
                                Actualizar expediente
                            </button>
                    }

                    { 
                        (expediente === undefined && modulosPermisos.expedientes.escribir === '1') &&
                            <button className="btn btn-azul noMargin">
                                <Icon icon="fa-solid fa-plus mr-2" />
                                <Icon icon="fa-solid fa-file-medical mr-2" />
                                Agregar expediente
                            </button>
                    }
                </div>


            </form>

        }

        </>
    )
}