import React from 'react'
import getEmpleadosByComisiones from '../services/empleado/getEmpleadosByComisiones'
import { ConnectionError, handleCallApiError } from '../validators/errors'
import getMetaSemanalByEmpleado from '../services/empleado/getMetaSemanalByEmpleado'

export default function useTrabajador() {
    const idUsuario = localStorage.getItem('idUsuario')
    var curr = new Date()
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)))
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7))

    const [trabajadores, setTrabajadores] = React.useState([])
    const [loadingTrabajadores, setLoadingTrabajadores] = React.useState(true)
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    const [fechaMetaInicio, setFechaMetaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaMetaFinal, setFechaMetaFinal] = React.useState(lastday.toISOString().split('T')[0])

    const getTrabajadoresByComisionesService = () => {
        setLoadingTrabajadores(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_usuario: idUsuario,
        }
        getEmpleadosByComisiones({ values: data })
            .then(res => {
                let _res = JSON.parse(res)
                if (_res.response === 'success') {
                    setTrabajadores(_res.data)
                    setLoadingTrabajadores(false)
                }
            })
            .catch(e => {
                setLoadingTrabajadores(false)
                // Intentar conectar de nuevo en unos segundos
                if (e instanceof ConnectionError) {
                    handleCallApiError('ConnectionError')
                }
            })
    }

    const getMetaSemanalTrabajadorService = () => {
        setLoadingTrabajadores(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_usuario: idUsuario,
        }
        getMetaSemanalByEmpleado({ values: data })
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                // console.log(_res)
                if (_res.response === 'success') {
                    setTrabajadores(_res.data)
                    setFechaMetaInicio(_res.fecha_inicial)
                    setFechaMetaFinal(_res.fecha_final)
                }
                setLoadingTrabajadores(false)
            })
            .catch(e => {
                setLoadingTrabajadores(false)
                // Intentar conectar de nuevo en unos segundos
                if (e instanceof ConnectionError) {
                    handleCallApiError('ConnectionError')
                }
            })
    }

    return {
        trabajadores,
        loadingTrabajadores,
        fechaInicio,
        fechaFinal,
        setFechaInicio,
        setFechaFinal,
        getTrabajadoresByComisionesService,
        getMetaSemanalTrabajadorService,
        fechaMetaInicio,
        fechaMetaFinal,
    }
}
