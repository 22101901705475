import "./FormularioTrabajador.css";
import React from "react";
import getGeneros from "../../services/empleado/getGeneros";
import $ from 'jquery'
import Icon from "../Icon";
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import LoadingIndicator from "../LoadingIndicator";
import getUnidades from "../../services/unidades/getUnidades";
// import searchProcesos from "../../services/proceso/searchProcesos";

export default function FormularioTrabajador({trabajador, onSubmit, modulosPermisos}){

    const [generos, setGeneros] = React.useState([])
    const [unidades, setUnidades] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    function getGenerosService(){
        setLoading(true)
        getGeneros()
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setGeneros(_res.data)
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const getUnidadesService = ()=>{
        setLoading(true)
        getUnidades({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setUnidades(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    const setDataFormulario = ()=>{
        document.getElementById('nombre').value = trabajador.nombre;
        document.getElementById('apellido_paterno').value = trabajador.apellido_paterno;
        document.getElementById('apellido_materno').value = trabajador.apellido_materno;
        document.getElementById('fecha_nacimiento').value = trabajador.fecha_nacimiento;
        document.getElementById('id_genero').value = trabajador.id_genero;
        document.getElementById('numero_celular').value = trabajador.numero_celular;
        document.getElementById('numero_local').value = trabajador.numero_local;
        document.getElementById('correo').value = trabajador.correo;
        document.getElementById('curp').value = trabajador.curp;
        document.getElementById('rfc').value = trabajador.rfc;
        document.getElementById('direccion').value = trabajador.direccion;
        document.getElementById('nomina_semanal').value = trabajador.nomina_semanal;
        document.getElementById('especialidades').value = trabajador.especialidades;

        $('.selectpicker').selectpicker('refresh');
        if(modulosPermisos.trabajadores.editar === "0"){
            $("#formularioTrabajador :input, :input[form]").prop("readonly", true);
        }

    }

    const onChangeImageInput = async (e)=>{
        const file = e.target.files[0];
        document.getElementById("perfil__imagen").src = URL.createObjectURL(file);
        $('#perfil__imagen').removeClass("hidden")
    }

    React.useEffect(getGenerosService, []);
    React.useEffect(getUnidadesService, []);

    React.useEffect(()=>{
        // Si existe un expediente por parametro, asignarle los valores al formulario
        // Solo una vez se haya cargado todo
        if(trabajador !== undefined && !loading)
            setDataFormulario()
    },[loading])

    return(
        <>
            <LoadingIndicator show={loading} />

        {
            !loading &&
            
            <form className="formulario" id='formularioTrabajador' onSubmit={ onSubmit }>

                {
                trabajador === undefined && 
                    <div className="perfil__fotoPerfil">
                        <div className="perfil__miniatura">
                            <img id="perfil__imagen" className="perfil__imagen img-fluid" src="https://via.placeholder.com/500/00548C/fff?text=Foto perfil" />
                        </div>
                        <input type="file" id="fotoPerfil" className="hidden" accept="image/png, image/jpeg" onChange={ (e) => onChangeImageInput(e) } name="fotoPerfil" />
                        <div>
                            <label htmlFor="fotoPerfil" className="perfil__cambiarFoto">
                                <h3>
                                    Foto del trabajador
                                </h3>
                                <p>
                                    Da clic aquí para cargar una foto de perfil al trabajador
                                </p>
                            </label>
                        </div>
                    </div>
                }


                {/* ******************************* */}
                {/* BLOQUE DE LOS DATOS PRINCIPALES */}
                {/* ******************************* */}

                <div className='mt-4'>
                    <h3 className='my-4'>
                        Datos del trabajador
                    </h3>

                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="nombre">
                                Nombre(s):
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="nombre"
                                name="nombre"
                                required
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="apellido_paterno">
                                Apellido paterno:
                            </label>
                            <input 
                                type="text" 
                                className="input" 
                                id="apellido_paterno"
                                name="apellido_paterno"
                                required
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="apellido_materno">
                                Apellido materno:
                            </label>
                            <input 
                                type="text"
                                className="input" 
                                id="apellido_materno"
                                name="apellido_materno"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="fecha_nacimiento">
                                Fecha nacimiento:
                            </label>
                            <input 
                                type="date"
                                className="input"
                                id="fecha_nacimiento"
                                name="fecha_nacimiento"
                                />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="id_genero">
                                Género:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_genero"
                                name="id_genero"
                                required
                            >
                                {
                                    generos && 
                                    generos.map( genero => (
                                        <option key={ genero.id_genero } value={ genero.id_genero }>
                                            { genero.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="numero_celular">
                                Número de celular:
                            </label>
                            <input 
                                type="tel" 
                                placeholder="#########" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="numero_celular"
                                name="numero_celular"
                                required
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="numero_local">
                                Número local:
                            </label>
                            <input 
                                type="tel" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="numero_local"
                                name="numero_local"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="correo">
                                Correo electrónico:
                            </label>
                            <input 
                                type="email" 
                                className="input"
                                id="correo"
                                name="correo"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="curp">
                                CURP:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="curp"
                                name="curp"
                                maxLength="20"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <label htmlFor="rfc">
                                RFC:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="rfc"
                                name="rfc"
                                maxLength="20"
                                placeholder="Opcional"
                            />
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12">
                            <label htmlFor="direccion">
                                Dirección:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="direccion"
                                name="direccion"
                                placeholder="Opcional"
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                            <label htmlFor="nomina_semanal">
                                Nómina semanal:
                            </label>
                            <input 
                                type="tel" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                id="nomina_semanal"
                                name="nomina_semanal"
                                placeholder="$"
                                required
                            />
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-6 col-12">
                            <label htmlFor="especialidades">
                                Especialidades:
                            </label>
                            <input 
                                type="text"
                                className="input"
                                id="especialidades"
                                name="especialidades"
                                placeholder="Opcional"
                            />
                        </div>
                    </div>
                </div>
                
                
                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr className='my-4' /> 

                <div className="d-flex justify-content-end">
                    
                    {
                        (trabajador !== undefined && modulosPermisos.trabajadores.editar === '1') &&
                            <button className="btn btn-azul noMargin">
                                <Icon icon="fa-solid fa-file-medical mr-3" />
                                Actualizar trabajador
                            </button>
                    }

                    { 
                        (trabajador === undefined && modulosPermisos.trabajadores.escribir === '1') &&
                            <button className="btn btn-azul noMargin">
                                <Icon icon="fa-solid fa-plus mr-2" />
                                <Icon icon="fa-solid fa-file-medical mr-2" />
                                Agregar trabajador
                            </button>
                    }
                </div>


            </form>

        }

        </>
    )
}