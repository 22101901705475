import React from 'react'
import './CardCitasTotalesPorUnidad.css'

export default function CardCitasTotalesPorUnidad(props) {
    return (
        <div className="d-flex justify-content-evenly globalCardsIngresosUnidad">
            <div className="cardIngresosUnidad cardIngresosUnidad--no-border-left">
                <small className="font-weight-bold">Total citas:</small>
                <h2>{props.total_citas !== undefined ? props.total_citas : 0}</h2>
            </div>
            <div className="cardIngresosUnidad">
                <small className="color-gris font-weight-bold">Total con pago:</small>
                <h2>{props.total_citas_con_pago !== undefined ? props.total_citas_con_pago : 0}</h2>
            </div>
            <div className="cardIngresosUnidad">
                <small className="color-verde font-weight-bold">Total sin pago:</small>
                <h2>{props.total_citas_sin_pago !== undefined ? props.total_citas_sin_pago : 0}</h2>
            </div>
        </div>
    )
}
