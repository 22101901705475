import React from "react";
import getEmpleados from "../../services/empleado/getEmpleados";
import $ from 'jquery';

export default function ModalAddEmpleadoUnidad({id_unidad, onSubmitModal}){

    const [empleados, setEmpleados] = React.useState([])

    const getEmpleadosService = ()=>{
        getEmpleados({values:{}})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setEmpleados(_res.data)
                }
                $(".selectpicker").selectpicker('refresh')
            })
    }

    React.useEffect(getEmpleadosService,[])

    return(
        <div className="modal" id="modalAddEmpleadoUnidad" tabIndex="-1" role="dialog" aria-labelledby="modalAddEmpleadoUnidadLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddEmpleadoUnidadLabel">Asigna un empleado a la unidad</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="id_empleado">
                            Elige un empleado:
                        </label>
                        <br></br>
                        <select 
                            className="input selectpicker"
                            id="id_empleado"
                            name="id_empleado"
                            required
                        >
                            {
                                empleados.length > 0 &&
                                empleados.map( (empleado) => (
                                    <option key={ empleado.id_empleado } value={ empleado.id_empleado }>{ `${empleado.nombre} ${empleado.apellido_paterno}`}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary">Asignar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}