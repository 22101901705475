import React from "react";
import { useHistory } from "react-router";
import getIngresosUnidad from "../../services/unidades/getIngresosUnidad";
import AddButton from "../AddButton";
import AlertWarningSearch from "../AlertWarningSearch";
import LoadingIndicator from "../LoadingIndicator";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import $ from "jquery";
import getIngresosTotalesUnidad from "../../services/unidades/getIngresosTotalesUnidad";
import addIngreso from "../../services/ingreso/addIngreso";
import CardIngresosUnidad from "../CardIngresosUnidad";
import ModalAddIngresoUnidad from "../ModalAddIngresoUnidad";
import Swal from "sweetalert2";
import getUnidadEmpleados from "../../services/unidades/getUnidadEmpleados";
import FilterButton from "../FilterButton";
import Icon from "../Icon";
import updateIngreso from "../../services/ingreso/updateIngreso";
import config from "../../config";

export default function UnidadIngresos(props) {
  let { modulosPermisos } = props;

  const history = useHistory();

  var curr = new Date();
  var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)));
  var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
  const [fechaInicio, setFechaInicio] = React.useState(
    firstday.toISOString().split("T")[0]
  );
  const [fechaFinal, setFechaFinal] = React.useState(
    lastday.toISOString().split("T")[0]
  );

  // VARIABLES PARA LOS INGRESOS
  const [loading, setLoading] = React.useState(true);
  const [ingresos, setIngresos] = React.useState([]);
  const [ingresosTotales, setIngresosTotales] = React.useState({});

  const [empleadosUnidad, setEmpleadosUnidad] = React.useState([]);
  const [idDoctorIngresos, setIdDoctorIngresos] = React.useState(null);
  const [showDeleteFilters, setShowDeleteFilters] = React.useState(false);
  const [loadingFilters, setLoadingFilters] = React.useState(false);

  const [elementToEdit, setElementToEdit] = React.useState(null);

  const rowsIngresos = [
    { id: 1, name: "No. de recibo" },
    {
      id: 2,
      name:
        config.idUnidadFarmacia === props.id_unidad ? "Producto" : "Proceso",
    },
    { id: 3, name: "Cantidad" },
    { id: 4, name: "Paciente" },
  ];

  function onSubmitFechas(e) {
    e.preventDefault();
    let values = new FormData(e.target);
    let objectValues = Object.fromEntries(values);
    setFechaInicio(objectValues.fecha_inicial);
    setFechaFinal(objectValues.fecha_final);
  }

  function getUnidadEmpleadosService() {
    setLoading(true);
    let data = { id_unidad: props.id_unidad };
    getUnidadEmpleados({ values: data })
      .then((res) => {
        let _res = JSON.parse(res);
        if (_res.response === "success") {
          setEmpleadosUnidad(_res.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error)
        // console.log("ha petado")
      });
  }

  function getIngresosUnidadService() {
    setLoading(true);
    let data = {
      fecha_inicio: fechaInicio,
      fecha_final: fechaFinal,
      id_unidad: props.id_unidad,
    };
    if (idDoctorIngresos !== null) {
      data.id_empleado = idDoctorIngresos;
    }
    getIngresosUnidad({ values: data })
      .then((res) => {
        let _res = JSON.parse(res);
        console.log(_res);
        if (_res.response === "success") {
          setIngresos(_res.data);
        } else {
          setIngresos([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error)
        // console.log("ha petado")
      });
  }

  function getIngresosTotalesUnidadService() {
    setLoading(true);
    let data = {
      fecha_inicio: fechaInicio,
      fecha_final: fechaFinal,
      id_unidad: props.id_unidad,
    };
    if (idDoctorIngresos !== null) {
      data.id_empleado = idDoctorIngresos;
    }
    getIngresosTotalesUnidad({ values: data })
      .then((res) => {
        // console.log(res)
        let _res = JSON.parse(res);
        // console.log(_res)
        if (_res.response === "success") {
          setIngresosTotales(_res.data);
        } else {
          setIngresosTotales([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error)
        // console.log("ha petado")
      });
  }

  const onSubmitModal = (e) => {
    $("#btnAddIngresoUnidad").prop("disabled", true);
    e.preventDefault();
    let values = new FormData(e.target);
    values.append("id_unidad", props.id_unidad);
    let objectValues = Object.fromEntries(values);
    // console.log({objectValues})
    addIngreso({ values: objectValues }).then((res) => {
      // console.log({res})
      let _res = JSON.parse(res);
      if (_res.response === "success") {
        Swal.fire({
          title: "Agregado correctamente",
          timer: 1200,
          showConfirmButton: false,
          icon: "success",
        });
        $("#modalAddIngresoUnidad").modal("hide");
        $("#formAddIngresoUnidad")[0].reset();
        $(".selectpicker").selectpicker("refresh");
        getIngresosUnidadService();
        getIngresosTotalesUnidadService();
      }
      $("#btnAddIngresoUnidad").prop("disabled", false);
    });
  };

  const reloadIngresos = () => {
    setIngresos([]);
    setTimeout(() => {
      getIngresosUnidadService();
      getIngresosTotalesUnidadService();
    }, 300);
  };

  const onSubmitFilter = (tipoFiltro, e) => {
    e.preventDefault();
    setShowDeleteFilters(true);
    let values = new FormData(e.target);
    let object = Object.fromEntries(values);

    setIdDoctorIngresos(object.id_empleado);
  };

  const onUpdateModal = (e) => {
    $("#btnAddIngresoUnidad").prop("disabled", true);
    e.preventDefault();
    let values = new FormData(e.target);
    values.append("id_unidad", props.id_unidad);
    values.append("id_ingreso", elementToEdit.id_ingreso);
    let objectValues = Object.fromEntries(values);
    console.log(objectValues);
    updateIngreso({ values: objectValues }).then((res) => {
      console.log({ res });
      let _res = JSON.parse(res);
      if (_res.response === "success") {
        Swal.fire({
          title: "Actualizado correctamente",
          timer: 1200,
          showConfirmButton: false,
          icon: "success",
        });
        $("#modalAddIngresoUnidad").modal("hide");
        $("#formAddIngresoUnidad")[0].reset();
        $(".selectpicker").selectpicker("refresh");
        getIngresosUnidadService();
        getIngresosTotalesUnidadService();
      } else {
        Swal.fire({
          title: "No se actualizó nada",
          timer: 1200,
          showConfirmButton: false,
          icon: "info",
        });
      }
      $("#btnAddIngresoUnidad").prop("disabled", false);
    });
  };

  const onClickDeleteFilters = () => {
    setIdDoctorIngresos(null);
    setShowDeleteFilters(false);
    // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
    setLoadingFilters(true);
    setTimeout(() => {
      setLoadingFilters(false);
    }, 50);
  };

  React.useEffect(getIngresosUnidadService, []);
  React.useEffect(getIngresosTotalesUnidadService, []);
  React.useEffect(getUnidadEmpleadosService, []);
  React.useEffect(() => $("#fecha_inicial").val(fechaInicio), []);

  React.useEffect(() => {
    reloadIngresos();
  }, [fechaInicio, fechaFinal, idDoctorIngresos]);

  return (
    <>
      <CardIngresosUnidad
        total_ingresos_efectivo={ingresosTotales.total_ingresos_efectivo}
        total_ingresos_banco={ingresosTotales.total_ingresos_banco}
        total_ingresos={ingresosTotales.total_ingresos}
      />

      <ModalAddIngresoUnidad
        id_unidad={props.id_unidad}
        id_empleado_responsable_unidad={props.id_empleado_responsable_unidad}
        onSubmitModal={elementToEdit === null ? onSubmitModal : onUpdateModal}
        elementToEdit={elementToEdit}
      />

      <div className="mt-5 mb-4">
        <div className="d-flex align-items-center">
          <h4 className="">Ingresos de la unidad</h4>

          {modulosPermisos.ingresos.escribir === "1" && (
            <AddButton
              text="Agregar ingreso"
              onClick={async () => {
                setElementToEdit(null);
                $("#modalAddIngresoUnidad").modal("show");
              }}
            />
          )}
        </div>

        <form
          onSubmit={onSubmitFechas}
          className="mt-3 d-flex justify-content-between align-items-center"
        >
          <input
            id="fecha_inicial"
            name="fecha_inicial"
            className="input noMargin"
            type="date"
            defaultValue={fechaInicio}
            required
          />
          <p className="mx-2">y</p>
          <input
            id="fecha_final"
            name="fecha_final"
            className="input noMargin"
            type="date"
            defaultValue={fechaFinal}
            required
          />
          <button className="btn btn btn-gris ml-2">
            <i className="fa-solid fa-search"></i>
          </button>
        </form>
      </div>

      <hr className="my-2" />

      <div className="d-flex justify-content-between align-items-center">
        <div>
          {!loadingFilters && (
            <>
              <FilterButton
                elements={empleadosUnidad}
                text="Doctor"
                type="id_empleado"
                onSubmit={onSubmitFilter}
              />
            </>
          )}
        </div>

        <button
          className={`btn btn-sm btn-light text-muted ${
            !showDeleteFilters && "hidden"
          }`}
          onClick={() => onClickDeleteFilters()}
        >
          <Icon icon="fa-solid fa-x" /> Eliminar
        </button>
      </div>

      <LoadingIndicator show={loading} />

      {ingresos.length !== 0 && !loading && (
        <Table>
          <TableHead rows={rowsIngresos} />

          <TableBody
            reload={reloadIngresos}
            elements={ingresos}
            typeElement="ingresos_unidad"
            permisosUsuario={modulosPermisos}
            setElementToEdit={setElementToEdit}
          />
        </Table>
      )}

      {ingresos.length === 0 && !loading && <AlertWarningSearch />}
    </>
  );
}
