import React from 'react';
import $ from 'jquery';
import getAllOrdenesTrabajo from '../services/orden_trabajo/getAllOrdenesTrabajo';
import searchOrdenesTrabajo from '../services/orden_trabajo/searchOrdenesTrabajo';
import getOrdenTrabajo from '../services/orden_trabajo/getOrdenTrabajo';
import Swal from 'sweetalert2';
import getOrdenesTrabajoByUsuario from '../services/orden_trabajo/getOrdenesTrabajoByUsuario';

export default function useOrdenesTrabajo(){
    
    const [ordenesTrabajo, setOrdenesTrabajo] = React.useState([]);
    const [loadingOrdenesTrabajo, setLoadingOrdenesTrabajo] = React.useState(true);

    const [orderBy, setOrderBy] = React.useState({})
    const [terminoBusqueda, setTerminoBusqueda] = React.useState("")
    const [filters, setFilters] = React.useState([])

    const [ordenTrabajo, setOrdenTrabajo] = React.useState(null)

    const [ordenesTrabajoByUsuario, setOrdenesTrabajoByUsuario] = React.useState([])
    const [loadingOrdenesTrabajoByUsuario, setLoadingOrdenesTrabajoByUsuario] = React.useState(true)

    const getAllOrdenesTrabajoService = () => {
        setLoadingOrdenesTrabajo(true);
        setOrdenesTrabajo([]);

        if(filters.length !== 0 || Object.keys(orderBy).length !== 0 || terminoBusqueda !== ""){
            search()
            return;
        }

        getAllOrdenesTrabajo({values: {}})
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setOrdenesTrabajo(_res.data);
                }
                setLoadingOrdenesTrabajo(false)
                // $('.selectpicker').selectpicker('refresh');
            })
    }

    const search = () =>{
        setLoadingOrdenesTrabajo(true)

        let data = {}

        if(terminoBusqueda !== "")
            data.q = terminoBusqueda
        if(filters.length !== 0)
            data.filtros = filters
        if(Object.keys(orderBy).length !== 0)
            data.ordenar = orderBy

        searchOrdenesTrabajo({values:data})
            .then((response) =>{
                console.log(response)
                let _response = JSON.parse(response)
                if(_response.response === "success"){
                    setOrdenesTrabajo(_response.data)
                } else {
                    setOrdenesTrabajo([])
                }
                setLoadingOrdenesTrabajo(false)
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    const getOrdenTrabajoById = (id_orden_trabajo) => {
        let data = { id_orden_trabajo }
        getOrdenTrabajo({ values: data })
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'error'){
                    Swal.fire({
                        title: 'Error al obtener los detalles',
                        icon: 'warning',
                        text: 'Inténtalo de nuevo más tarde',
                        timer: 2000,
                        showConfirmButton: false,
                        showCancelButton: false,
                    })
                }

                setOrdenTrabajo(_res.data)
                Swal.close()
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    title: 'Error al obtener los detalles',
                    icon: 'warning',
                    text: 'Inténtalo de nuevo más tarde',
                    timer: 2000,
                    showConfirmButton: false,
                    showCancelButton: false,
                })
            })
    }

    const getOrdenesTrabajoByUsuarioService = () => {
        setOrdenesTrabajoByUsuario([])
        setLoadingOrdenesTrabajoByUsuario(true)

        let id_usuario = localStorage.getItem("idUsuario")
        const data = { id_usuario }

        getOrdenesTrabajoByUsuario({ values: data })
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if (_response.response === "success") {
                    setOrdenesTrabajoByUsuario(_response.data)
                }
                setLoadingOrdenesTrabajoByUsuario(false)
            })
            .catch(error => {
                console.log(error)
            })

    }

    React.useEffect(getAllOrdenesTrabajoService, [terminoBusqueda, orderBy, filters])

    return { 
        ordenesTrabajo, 
        loadingOrdenesTrabajo, 
        ordenTrabajo,
        filters,
        ordenesTrabajoByUsuario,
        loadingOrdenesTrabajoByUsuario,
        getAllOrdenesTrabajoService, 
        setOrderBy, 
        setTerminoBusqueda,
        setFilters,
        getOrdenTrabajoById,
        getOrdenesTrabajoByUsuarioService
    }

}