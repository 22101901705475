import React from 'react';
import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2'
import FormularioIngreso from '../../components/FormularioIngreso';
import addIngreso from '../../services/ingreso/addIngreso';

export default function AddIngreso(){

    const history = useHistory()

    function onSubmitIngreso(e){
        e.preventDefault();
        const formData = new FormData(e.target);
        let objectValues = Object.fromEntries(formData);
        // console.log(objectValues)
        addIngreso({values:objectValues})
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    Swal.fire({
                        title:'Ingreso agregado',
                        icon:'success',
                        timer:1200
                    }).then(res=>{
                        history.push('/corte')
                    })
                }
            })
    }

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btn btn-gris noMargin"
            />

            <h2 className="my-4">Agregar un nuevo ingreso</h2>

            <p>
                Para agregar un ingreso, tienes que completar el siguiente formulario:
            </p>

            <hr className='my-3' />

            <FormularioIngreso 
                onSubmit={onSubmitIngreso}
            />


        </section>
    );
}