import React from 'react';
import Swal from 'sweetalert2'
import deleteIngreso from '../../services/ingreso/deleteIngreso';
import $ from 'jquery'

export default function TableElementIngresosExpediente({ element, permisosUsuario, reload, setIdIngresoReembolso, setElementToEdit }) {

    let { id_ingreso, folio, descripcion, nombre_empleado, numero_recibo, nombre_proceso, cantidad, nombre_forma_pago, fecha_del_pago, reembolsado, cantidad_reembolso, fecha_del_reembolso, activo } = element;

    const onClickEstatus = () => {
        let msg = 'Quieres eliminar el pago?';
        let txt = 'Eliminar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_ingreso', id_ingreso)
                let objectValues = Object.fromEntries(values);
                deleteIngreso({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        Swal.fire({
                            title:'Eliminado correctamente',
                            timer:1200,
                            showConfirmButton:false,
                            icon:'success'
                        })
                        reload()
                    }
                    
                })
            }
        })
    }

    const onClickReembolso = () =>{
        setIdIngresoReembolso(id_ingreso)
        $('#modalEmitirReembolso').modal('show')
    }

    const onUpdateElement = ()=>{
        // setElementToEdit(null)
        setElementToEdit(element)
        $("#modalAddIngresoExpediente").modal('show')
    }

    return (
        <tr key={id_ingreso}>
            <th className='table-max-30'>
                <div className="table__name">
                    {
                        activo === '1' && (permisosUsuario.ingresos.eliminar === '1' || permisosUsuario.ingresos.editar === '1') &&
                            <div className="dropdown">
                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                        (permisosUsuario.ingresos.editar === '1') &&
                                            <a className="dropdown-item text-black" onClick={() => onUpdateElement()}>
                                                Editar
                                            </a>
                                    }
                                    {
                                        (permisosUsuario.ingresos.editar === '1') &&
                                            reembolsado === '0' &&
                                                <a className="dropdown-item text-black" onClick={onClickReembolso}>
                                                    Reembolsar
                                                </a>
                                    }
                                    {
                                        (permisosUsuario.ingresos.eliminar === '1') &&
                                            <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus()}>
                                                Eliminar
                                            </a>
                                    }
                                </div>
                            </div>
                    }

                    <div className="table__nameDescription">
                        <p>{numero_recibo}</p>
                        <small className='text-muted'>{fecha_del_pago}</small>
                        {
                            reembolsado === '1' &&
                            <div className="table__estatus mt-2">
                                <span className='table__estatus-inactive'>
                                    Reembolsado: $ {cantidad_reembolso}
                                </span>
                            </div>
                        }
                        {
                            reembolsado === '1' &&
                            <div className="table__estatus mt-2">
                                <span className='table__estatus-inactive'>
                                    Fecha: {fecha_del_reembolso}
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </th>

            <td>
                <p>{nombre_proceso ? nombre_proceso : '-'}</p>
                <small className='text-muted'>{nombre_empleado}</small>
            </td>

            <td>
                <p>$ {cantidad}</p>
                <small className='text-muted'>{nombre_forma_pago}</small>
            </td>
            
            <td>
                <p>{descripcion ? descripcion : '-'}</p>
            </td>
        </tr>
    )
    
}