import React from 'react';
import getUnidades from '../services/unidades/getUnidades';
import { ConnectionError, handleCallApiError } from '../validators/errors';

export default function useUnidad(){
    
    const idUsuario = localStorage.getItem('idUsuario')
    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay()-1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));

    const [unidades, setUnidades] = React.useState([]);
    const [loadingUnidades, setLoadingUnidades] = React.useState(true);
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])
    
    
    const getUnidadesService = ()=>{
        setLoadingUnidades(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_usuario: idUsuario
        }
        getUnidades({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setUnidades(_res.data)
                    setLoadingUnidades(false)
                }
            })
            .catch(e => {
                setLoadingUnidades(false)
                // Intentar conectar de nuevo en unos segundos
                if(e instanceof ConnectionError){
                    handleCallApiError("ConnectionError")
                }
            })
    }

    React.useEffect(() => {
        getUnidadesService()
    }, [fechaInicio, fechaFinal]);

    return { 
        unidades, 
        loadingUnidades, 
        fechaInicio, 
        fechaFinal, 
        setFechaInicio, 
        setFechaFinal, 
        getUnidadesService 
    }

}