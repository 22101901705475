import React from 'react';
import Swal from 'sweetalert2'
import deleteUnidadEmpleado from '../../services/unidades/deleteUnidadEmpleado';

export default function TableElementTrabajadorUnidad({ element, permisosUsuario }) {

    let { id_empleado, id_unidad, nombre, apellido_paterno, apellido_materno, foto_perfil, es_responsable, fecha_alta, activo } = element;
    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Deseas quitar a este trabajador de la unidad?' : 'Deseas quitar a este trabajador de la unidad?';
        let txt = (activo) ? 'Habilitar' : 'Si';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_empleado', id_empleado)
                values.append('id_unidad', id_unidad)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                // console.log(objectValues)
                deleteUnidadEmpleado({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire({
                            title:'Eliminado correctamente',
                            timer:1200,
                            showConfirmButton:false,
                            icon:'success'
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_empleado}>
            <th>
                <div className="table__name">
                    {
                        (estadoEstatus === '1') &&

                            (permisosUsuario.unidades.eliminar === '1' || permisosUsuario.unidades.editar === '1' ) &&
                            <div className="dropdown">
                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                        permisosUsuario.unidades.eliminar === '1' &&
                                            <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Quitar de la unidad</a>
                                    }
                                </div>
                            </div>
                    }
                    
                    {
                        (foto_perfil) &&
                        (<img src={foto_perfil} className="img-fluid rounded-circle "/>)
                    }

                    <div className="table__nameDescription">
                        <p>{nombre} {apellido_paterno} {apellido_materno}</p>
                        {
                            es_responsable === '1' && <small>Responsable de la unidad</small>
                        }
                    </div>
                </div>
            </th>

            <td>
                <p>{fecha_alta}</p>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus === '1' ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}