import React from "react";
import './CardEgresoUnidad.css'

export default function CardEgresoUnidad(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');

    return(
        <div className='d-flex flex-wrap justify-content-evenly globalCardsIngresosUnidad'>
            <div className='cardIngresosUnidad cardIngresosUnidad--no-border-left'>
                <small className='font-weight-bold text-left'>Egresos en efectivo:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_egresos_efectivo !== undefined ? dollarUSLocale.format(props.total_egresos_efectivo) : 0}
                </h2>
            </div>
            <div className='cardIngresosUnidad'>
                <small className='color-gris font-weight-bold text-left'>Egresos en banco:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_egresos_banco !== undefined ? dollarUSLocale.format(props.total_egresos_banco) : 0}
                </h2>
            </div>
            <div className='cardIngresosUnidad'>
                <small className='color-verde font-weight-bold text-left'>Egresos totales:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_egresos !== undefined ? dollarUSLocale.format(props.total_egresos) : 0}
                </h2>
            </div>
        </div>
    )
}