import React from 'react'
import LoadingIndicator from '../../components/LoadingIndicator'
import CardEmpleado from '../../components/CardEmpleado'
import useTrabajador from '../../hooks/useTrabajador'

export default function Metas(props) {
    const { modulosPermisos } = props

    const {
        trabajadores,
        loadingTrabajadores,
        fechaInicio,
        fechaFinal,
        setFechaInicio,
        setFechaFinal,
        getTrabajadoresByComisionesService,
    } = useTrabajador()

    function onSubmitFechas(e) {
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    React.useEffect(() => {
        getTrabajadoresByComisionesService()
    }, [fechaInicio, fechaFinal])

    return (
        <section>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <h1>Metas de trabajadores</h1>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-start justify-content-sm-start mt-md-4 mt-sm-4 mt-4">
                    <form onSubmit={onSubmitFechas} className="">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <input
                                id="fecha_inicial"
                                name="fecha_inicial"
                                className="input noMargin"
                                type="date"
                                defaultValue={fechaInicio}
                                required
                            />
                            <p className="mx-2">y</p>
                            <input
                                id="fecha_final"
                                name="fecha_final"
                                className="input noMargin"
                                type="date"
                                defaultValue={fechaFinal}
                                required
                            />
                            <button className="btn btn btn-gris ml-2">
                                <i className="fa-solid fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <LoadingIndicator show={loadingTrabajadores} />

            <div className="row my-5">
                {trabajadores.length !== 0 &&
                    !loadingTrabajadores &&
                    trabajadores.map((trabajador, index) => {
                        console.log('trabajador', trabajador)
                        return (
                            <div className="col-6" key={trabajador.id_empleado}>
                                <CardEmpleado
                                    foto_perfil={trabajador.foto_perfil}
                                    nombre={trabajador.nombre}
                                    total_pacientes_atendidos={trabajador.total_pacientes_atendidos}
                                    ingresos={trabajador.total_ingresos}
                                    egresos={trabajador.total_egresos}
                                    meta={trabajador.meta}
                                    comision={trabajador.comision}
                                    egreso_prestamos={trabajador.cantidad_prestamo_semanal}
                                    egreso_laboratorios={trabajador.gasto_laboratorio}
                                    id_empleado={trabajador.id_empleado}
                                />
                            </div>
                        )
                    })}
            </div>
        </section>
    )
}
