import React from "react";
import './CardCorteSemanalEfectivo.css'

export default function CardCorteSemanalEfectivo(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');

    return(
        <div className='d-flex flex-wrap justify-content-evenly globalCardsCorteSemanalEfectivo'>
            <div className='cardCorteSemanalEfectivo cardCorteSemanalEfectivo--no-border-left'>
                <small className='color-verde font-weight-bold'>Ingresos en efectivo:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_ingresos_efectivo !== undefined
                    ? dollarUSLocale.format(props.total_ingresos_efectivo)
                    : 0}
                </h2>
            </div>
            <div className='cardCorteSemanalEfectivo'>
                <small className='color-rojo font-weight-bold'>Gastos en efectivo:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_gastos_efectivo !== undefined
                    ? dollarUSLocale.format(props.total_gastos_efectivo)
                    : 0}
                </h2>
            </div>
            <div className='cardCorteSemanalEfectivo'>
                <small className='font-weight-bold'>Total efectivo en caja:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_efectivo_caja !== undefined
                    ? dollarUSLocale.format(props.total_efectivo_caja)
                    : 0}
                </h2>
            </div>
        </div>

    )
}