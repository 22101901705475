import React from "react";
import $ from 'jquery';
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import getMetodosPago from "../../services/ingreso/getMetodosPago";
import getAllExpedientes from "../../services/expediente/getAllExpedientes";
import getEmpleados from "../../services/empleado/getEmpleados";
import searchProcesos from "../../services/proceso/searchProcesos";
import getUnidadEmpleados from "../../services/unidades/getUnidadEmpleados";

export default function ModalAddIngresoUnidad({id_unidad, id_empleado_responsable_unidad, onSubmitModal, elementToEdit}){

    const [expedientes, setExpedientes] = React.useState([]);
    const [empleados, setEmpleados] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [procesos, setProcesos] = React.useState([]);
    const [loading, setLoading] = React.useState(true)

    const getMetodosPagoService = ()=>{
        setLoading(true)
        getMetodosPago({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMetodosPago(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                    setDataFormulario()
                }
            })
    }

    function getExpedientesService(){
        getAllExpedientes()
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setExpedientes(_res.data)
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function getEmpleadosByUnidadService(){
        let data = { id_unidad }
        getUnidadEmpleados({values:data})
            .then(res => {

                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setEmpleados(_res.data)
                    setLoading(false)
                    $("#id_empleado_responsable_unidad").attr("disabled",false)
                } else {
                    setEmpleados([])
                    $("#id_empleado_responsable_unidad").attr("disabled",true)
                }
                $('.selectpicker').selectpicker('refresh');
            })
    }
        
    const setDataFormulario = ()=>{
        if(elementToEdit !== null){
            $('#id_proceso option[value="'+ elementToEdit.id_proceso +'"]').prop('selected', true);
            $('#id_metodo_pago option[value="'+ elementToEdit.id_metodo_pago +'"]').prop('selected', true);
            $('#id_empleado_responsable_unidad option[value="'+ elementToEdit.id_empleado +'"]').prop('selected', true);
            $('#folio option[value="'+ elementToEdit.folio +'"]').prop('selected', true);
            // onChangeUnidad(elementToEdit.id_unidad);
            document.getElementById("numero_recibo").value = elementToEdit.numero_recibo
            document.getElementById("fecha_del_pago").value = elementToEdit.fecha_del_pago_sin_formato
            document.getElementById("cantidad").value = elementToEdit.cantidad
            document.getElementById("descripcion").value = elementToEdit.descripcion
        } else {
            $("#formAddIngresoUnidad")[0].reset();
            document.getElementById('fecha_del_pago').valueAsDate = new Date();
        }
        $('.selectpicker').selectpicker('refresh');
    }

    function getProcesosByUnidadFolioService(){
        let filtros = []
        filtros.push({ campo: 'id_unidad', data: {id_unidad: id_unidad} })
        let data = {
            filtros: filtros
        }
        searchProcesos({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setProcesos(_res.data)
                    $("#id_proceso").attr("disabled",false)
                } else {
                    setProcesos([])
                    $("#id_proceso").attr("disabled",true)
                }
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function onChangeFolio(e){
        let folio = e.target.value;
        // getProcesosByUnidadFolioService(folio)
    }

    React.useEffect(getMetodosPagoService, []);
    React.useEffect(getExpedientesService, []);
    React.useEffect(getProcesosByUnidadFolioService, []);
    React.useEffect(getEmpleadosByUnidadService, []);
    React.useEffect(setDataFormulario, [elementToEdit])

    return(
        <div className="modal" id="modalAddIngresoUnidad" tabIndex="-1" role="dialog" aria-labelledby="modalAddIngresoUnidadLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} id="formAddIngresoUnidad" className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddIngresoUnidadLabel">Agrega un ingreso a la unidad</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="numero_recibo">
                                    No. de recibo:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="numero_recibo"
                                    name="numero_recibo"
                                    placeholder="######"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="fecha_del_pago">
                                    Fecha del pago:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_del_pago"
                                    name="fecha_del_pago"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="cantidad">
                                    Cantidad del pago:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="cantidad"
                                    name="cantidad"
                                    placeholder="$"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_metodo_pago">
                                    Método de pago:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_metodo_pago"
                                    name="id_metodo_pago"
                                    required
                                >  
                                    <option value="">Elige un método de pago</option>
                                    {
                                        metodosPago && 
                                        metodosPago.map( metodo_pago => (
                                            <option key={ metodo_pago.id_metodo_pago } value={ metodo_pago.id_metodo_pago }>
                                                { metodo_pago.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción detallada del pago:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="Sé lo más detallado posible (Opcional)"
                                    className="input textarea"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <h3 className='my-4'>
                                Datos generales
                            </h3>

                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="folio">
                                        Paciente quien realiza el pago:
                                    </label>
                                    <br/>
                                    <select 
                                        className="input selectpicker"
                                        id="folio"
                                        name="folio"
                                        data-live-search="true"
                                        required
                                        onChange={onChangeFolio}
                                    >
                                        <option value="">Elige un folio</option>
                                        {
                                            expedientes &&
                                            expedientes.map( (expediente) => (
                                                <option key={ expediente.folio } value={ expediente.folio }>{ expediente.folio } : { expediente.nombrePaciente.trim() }</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="id_proceso">
                                        Proceso al que pertenece el pago:
                                    </label>
                                    <br/>
                                    <select 
                                        className="input selectpicker"
                                        id="id_proceso"
                                        name="id_proceso"
                                        data-live-search="true"
                                        required
                                    >
                                        <option value="">Elige un proceso</option>
                                        {
                                            procesos &&
                                            procesos.map( (proceso) => (
                                                <option key={ proceso.id_proceso } value={ proceso.id_proceso }>{ proceso.nombre }</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="col-12">
                                    <label htmlFor="id_empleado_responsable_unidad">
                                        Trabajador a quien pertenece el pago:
                                    </label>
                                    <br/>
                                    <select 
                                        className="input selectpicker"
                                        id="id_empleado_responsable_unidad"
                                        name="id_empleado"
                                        required
                                    >
                                        <option value="">Elige un trabajador</option>
                                        {
                                            empleados &&
                                            empleados.map( (empleado) => (
                                                <option key={ empleado.id_empleado } value={ empleado.id_empleado }>{ empleado.nombre }</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary" id="btnAddIngresoUnidad">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}