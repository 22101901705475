import React from "react"
import LogViewer from "../LogViewer"

export default function ModalViewLog({log}){

    return(
        <div className="modal" id="modalViewLog" tabIndex="-1" role="dialog" aria-labelledby="modalViewLogLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalViewLogLabel">Registro detallado</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <LogViewer 
                            log={log}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Salir</button>
                    </div>
                </div>
            </div>
        </div>
    )
}