import React from 'react'
import './Unidades.css'
import AddButton from '../../components/AddButton';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useHistory } from 'react-router';
import CardUnidad from '../../components/CardUnidad';
import getUnidades from '../../services/unidades/getUnidades';
import $ from 'jquery'
import { ConnectionError, handleCallApiError } from '../../validators/errors';

export default function Unidades({modulosPermisos}){

    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay()-1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const idUsuario = localStorage.getItem('idUsuario')

    const history = useHistory()

    const [unidades, setUnidades] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    const getUnidadesService = ()=>{
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_usuario: idUsuario
        }
        getUnidades({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setUnidades(_res.data)
                    setLoading(false)
                }
            })
            .catch(e => {
                // Intentar conectar de nuevo en unos segundos
                if(e instanceof ConnectionError){
                    handleCallApiError("ConnectionError")
                }
            })
    }

    React.useEffect(() => {
        getUnidadesService()
        $('#fecha_inicial').val(fechaInicio)
    },[fechaInicio, fechaFinal]);

    return (
        <section>

            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        <h1>Unidades</h1>

                        {
                            modulosPermisos.unidades.escribir === '1' &&
                                <AddButton text="Agregar unidad" onClick={ async () => {
                                    history.push("/unidades/agregar")
                                } } />
                        }
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-start justify-content-sm-start mt-md-4 mt-sm-4 mt-4">
                    <form onSubmit={onSubmitFechas} className="">
                        <div className={`d-flex justify-content-around align-items-center`}>
                            <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                            <p className="mx-2">y</p>
                            <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                            <button className='btn btn-gris ml-2 col-0'><i className="fa-solid fa-search"></i></button>
                        </div>
                        <button className='btn btn-block btn-gris my-2 col-xl-0 col-lg-0 col-md-0 col-sm-0'><i className="fa-solid fa-search"></i></button>
                    </form>
                </div>
            </div>

            <LoadingIndicator show={loading} />

            <div className='row my-5'>


                {
                    unidades.length !== 0 && !loading &&
                        unidades.map(unidad => {
                            return(
                                <div className='col-xl col-lg col-md col-sm col-6 justify-content-center d-flex'>
                                    <CardUnidad 
                                        nombre={unidad.nombre}
                                        cantidad_ingresos={unidad.cantidad_ingresos}
                                        cantidad_ingresos_efectivo={unidad.cantidad_ingresos_efectivo}
                                        cantidad_ingresos_banco={unidad.cantidad_ingresos_banco}
                                        cantidad_egresos={unidad.cantidad_egresos}
                                        foto_perfil_responsable={unidad.foto_perfil_responsable}
                                        id_unidad={unidad.id_unidad}
                                        total_pacientes_atendidos={unidad.total_pacientes_atendidos}
                                    />
                                </div>
                            )
                        })
                }
            </div>
        </section>
    );
}