import React from "react";
import $ from 'jquery';
import searchProcesos from "../../services/proceso/searchProcesos";
import getUnidades from "../../services/unidades/getUnidades";
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import useDientes from "../../hooks/useDientes";

export default function ModalAddTratamientoExpediente({onSubmitModal}){

    const { dientes, loading } = useDientes();

    const [procesos, setProcesos] = React.useState([]);
    const [unidades, setUnidades] = React.useState([]);

    function getProcesosByUnidadService(id_unidad){
        let filtros = []
        filtros.push({ campo: 'id_unidad', data: {id_unidad: id_unidad} })
        let data = {
            filtros: filtros
        }
        searchProcesos({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setProcesos(_res.data)
                    $("#id_proceso").attr("disabled",false)
                } else {
                    setProcesos([])
                    $("#id_proceso").attr("disabled",true)
                }
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const getUnidadesService = ()=>{
        getUnidades({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setUnidades(_res.data)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    function onChangeUnidad(value){
        getProcesosByUnidadService(value)
    }

    function onChangeDiente(){
        let dientes = $("#dientes_involucrados").val();
        const selectElement = document.getElementById("dientes_involucrados");

        if (dientes.includes("1")) {
            // Desactivar todas las opciones excepto la opción 1
            for (let i = 0; i < selectElement.options.length; i++) {
                const option = selectElement.options[i];
                option.selected = option.value === "1";
              }
        }

        // Actualizar el valor del select
        $(selectElement).selectpicker("refresh");
    }

    React.useEffect(getUnidadesService,[])

    return(
        <div className="modal" id="modalAddTratamientoExpediente" tabIndex="-1" role="dialog" aria-labelledby="modalAddTratamientoExpedienteLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddTratamientoExpedienteLabel">Agrega un tratamiento al paciente</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <label htmlFor="id_unidad">
                                    Unidad:
                                </label>
                                <br></br>
                                <select 
                                    className="input selectpicker"
                                    id="id_unidad"
                                    name="id_unidad"
                                    data-live-search="true"
                                    onChange={(e) => onChangeUnidad(e.target.value)}
                                    required
                                >
                                    <option value="">Elige una unidad</option>
                                    {
                                        unidades && 
                                        unidades.map( unidad => (
                                            <option key={ unidad.id_unidad } value={ unidad.id_unidad }>
                                                { unidad.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <label htmlFor="id_proceso">
                                    Elige un tratamiento:
                                </label>
                                <br></br>
                                <select 
                                    className="input selectpicker"
                                    id="id_proceso"
                                    name="id_proceso"
                                    disabled
                                    data-live-search="true"
                                    required
                                >   
                                    {
                                        procesos && 
                                        procesos.map( proceso => (
                                            <option key={ proceso.id_proceso } value={ proceso.id_proceso }>
                                                { proceso.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <label htmlFor="precio_final">
                                    Precio final del tratamiento:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="precio_final"
                                    name="precio_final"
                                    placeholder="$"
                                    required
                                />
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <label htmlFor="dientes_involucrados">
                                    <small className="text-muted font-weight-bold">Opcional</small> Dientes involucrados:
                                </label>
                                <select
                                    className="input selectpicker"
                                    id="dientes_involucrados"
                                    name="dientes_involucrados"
                                    multiple
                                    data-live-search="true"
                                    onChange={(e) => onChangeDiente()}
                                >
                                    {/* <option selected value="">Opcional</option> */}
                                    {
                                        dientes &&
                                        dientes.map( diente => (
                                            <option key={ diente.id_diente } value={ diente.id_diente }>
                                                { diente.numero && `${diente.numero} - ` }
                                                { `${diente.nombre}` }
                                            </option>
                                        ))

                                    }   
                                </select>
                            </div>

                            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-7 col-12">
                                <label htmlFor="fecha_inicio_proceso">
                                    Día en que comenzó el tratamiento:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_inicio_proceso"
                                    name="fecha_inicio_proceso"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary" id="btnModalAddTratamientoExpedienteSubmit">Asignar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}