import React from 'react'
import "./AlertWarningSearch.css"

export default function AlertWarningSearch(){
    return(
        <div className="d-flex justify-content-center align-items-center flex-column" id="noDataWarning">
            🧐
            <p>Sin resultados</p>
        </div>
    )
}