import React from "react";
import AddButton from "../AddButton";
import AlertWarningSearch from "../AlertWarningSearch";
import LoadingIndicator from "../LoadingIndicator";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import $ from 'jquery';
import addEgreso from "../../services/egreso/addEgreso";
import CardEgresoUnidad from "../CardEgresoUnidad";
import Swal from "sweetalert2";
import getEgresosEmpleado from "../../services/empleado/getEgresosEmpleado";
import getEgresosTotalesEmpleado from "../../services/empleado/getEgresosTotalesEmpleado";
import ModalAddEgresoTrabajador from "../ModalAddEgresoTrabajador";

export default function TrabajadorEgresos(props){

    let { modulosPermisos } = props

    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    // VARIABLES PARA LOS INGRESOS
    const [loading, setLoading] = React.useState(true);
    const [egresos, setEgresos] = React.useState([]);
    const [egresosTotales, setEgresosTotales] = React.useState({})

    const rowsEgresos = [
        { id: 1, name: "No. de egreso" },
        { id: 2, name: "Tipo de gasto" },
        { id: 3, name: "Cantidad" },
        { id: 4, name: "Descripción" }
    ];

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    function getEgresosTrabajadorService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_trabajador: props.id_trabajador
        }
        getEgresosEmpleado({values:data}).then(res => {
            console.log(res)
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setEgresos(_res.data);
            } else {
                setEgresos([])
            }
            setTimeout(()=>{
                setLoading(false)
            }, 1000)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }

    function getEgresosTotalesTrabajadorService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_trabajador: props.id_trabajador
        }
        getEgresosTotalesEmpleado({values:data}).then(res => {
            console.log(res)
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setEgresosTotales(_res.data);
            } else {
                setEgresosTotales({})
            }
            setTimeout(()=>{
                setLoading(false)
            }, 1000)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }

    const onSubmitModal = (e)=>{
        e.preventDefault()

        // Desactivamos el botón submit del formulario
        let btnSubmit = document.getElementById('btnModalAddEgresoTrabajadorSubmit');
        btnSubmit.disabled = true;
        btnSubmit.innerHTML = 'Agregando...';

        let values = new FormData(e.target)
        values.append('id_empleado', props.id_trabajador);
        let objectValues = Object.fromEntries(values);
        addEgreso({values:objectValues}).then(res=>{
            // console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Agregado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddEgresoTrabajador").modal("hide")
                $("#formAddEgresoTrabajador")[0].reset();
                $(".selectpicker").selectpicker('refresh');
                getEgresosTrabajadorService()
                getEgresosTotalesTrabajadorService()
            }
            
            // Activamos el botón submit del formulario de nuevo
            btnSubmit.disabled = false;
            btnSubmit.innerHTML = 'Agregar';
        })
    }

    const reloadEgresos = ()=>{
        getEgresosTrabajadorService()
        getEgresosTotalesTrabajadorService()
    }

    React.useEffect(getEgresosTrabajadorService, [])
    React.useEffect(getEgresosTotalesTrabajadorService, [])

    React.useEffect(() => {
        reloadEgresos()
        $('#fecha_inicial').val(fechaInicio)
    },[fechaInicio, fechaFinal]);

    return(
        <>
            <CardEgresoUnidad
                total_egresos_efectivo={egresosTotales.total_egresos_efectivo}
                total_egresos_banco={egresosTotales.total_egresos_banco}
                total_egresos={egresosTotales.total_egresos}
            />

            <ModalAddEgresoTrabajador 
                id_trabajador={props.id_trabajador}
                id_empleado_responsable_unidad={props.id_empleado_responsable_unidad}
                onSubmitModal={onSubmitModal}
            />

            <div className="row my-5">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        <h4 className="">Egresos del trabajador</h4>

                        {
                            modulosPermisos.egresos.escribir === '1' &&
                                <AddButton text="Agregar egreso" onClick={ async () => {
                                    $('#modalAddEgresoTrabajador').modal('show')
                                } } />
                        }
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-sm-start mt-4">
                    <form onSubmit={onSubmitFechas}>
                        <div className={`d-flex justify-content-between align-items-center`}>    
                            <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                            <p className="mx-2">y</p>
                            <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                            <button className='btn btn-gris ml-2'><i className="fa-solid fa-search"></i></button>
                        </div>
                    </form>
                </div>
            </div>
            
            <LoadingIndicator show={loading} />

            {
                ( egresos.length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rowsEgresos} />

                        <TableBody reload={reloadEgresos} elements={egresos} typeElement="egresos_unidad" permisosUsuario={modulosPermisos} />
                    </Table>
            }

            {
                ( egresos.length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }
        </>
    )
}