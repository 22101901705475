import React from "react"
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import $ from 'jquery';
import getEmpleados from "../../services/empleado/getEmpleados";
import getUnidades from "../../services/unidades/getUnidades";

export default function ModalAddSalidaMaterial({onSubmitModal, material, id_unidad}){

    const [loading, setLoading] = React.useState(true)
    const [empleados, setEmpleados] = React.useState([])
    const [unidades, setUnidades] = React.useState([]);

    function getEmpleadosService(){
        getEmpleados({values:{}})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setEmpleados(_res.data)
                } 
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const getUnidadesService = ()=>{
        setLoading(true)
        getUnidades({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setUnidades(_res.data)

                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    React.useEffect(getEmpleadosService, [id_unidad])
    React.useEffect(getUnidadesService,[])

    React.useEffect(()=>{
        document.getElementById('fecha_salida').valueAsDate = new Date();
    }, [material])

    return(
        <div className="modal" id="modalAddSalidaMaterial" tabIndex="-1" role="dialog" aria-labelledby="modalAddSalidaMaterialLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} className="modal-content" id="formAddSalidaMaterial">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddSalidaMaterialLabel">
                            Marcar salida de material
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Al marcar una salida, es porque el material se lo entregaste a un doctor o personal.
                        </p>
                        <hr className="my-2" />
                        <div className="row mt-3">
                            <div className="col-7">
                                <label htmlFor="fecha_salida">
                                    Material:
                                </label>
                                <p className="font-weight-bold">
                                    {material.nombre}
                                </p>
                            </div>
                            <div className="col-5">
                                <label htmlFor="cantidad_salida">
                                    Cantidad a entregar:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="cantidad_salida"
                                    name="cantidad_salida"
                                    placeholder="1"
                                    required
                                />
                            </div>
                            <div className="col-7">
                                <label htmlFor="id_empleado">
                                    A quién lo entregas:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_empleado"
                                    name="id_empleado"
                                    required
                                >  
                                    <option value="">Elige al personal a quién se entrega</option>
                                    {
                                        empleados && 
                                        empleados.map( empleado => (
                                            <option key={ empleado.id_empleado } value={ empleado.id_empleado }>
                                                { empleado.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-5">
                                <label htmlFor="id_unidad_entrega">
                                    A qué unidad:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_unidad_entrega"
                                    name="id_unidad_entrega"
                                    data-live-search="true"
                                    required
                                >
                                    <option value="">Elige una unidad</option>
                                    {
                                        unidades && 
                                        unidades.map( unidad => {
                                            if( unidad.id_unidad === '17' ||
                                                unidad.id_unidad === '18' || 
                                                unidad.id_unidad === '19'){
                                                    return null;
                                                }
                                            
                                            return (
                                                <option key={ unidad.id_unidad } value={ unidad.id_unidad }>
                                                    { unidad.nombre }
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-7">
                                <label htmlFor="fecha_salida">
                                    Cuándo lo entregas:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_salida"
                                    name="fecha_salida"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción detallada:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="(Opcional) Agrega detalles sobre esta salida de material"
                                    className="input textarea"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary">
                            Marcar salida
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}