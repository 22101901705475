import React from 'react'
import $ from 'jquery'
import Icon from '../../components/Icon'
import getUnidades from '../../services/unidades/getUnidades'
import getMetodosPago from '../../services/ingreso/getMetodosPago'
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import Swal from 'sweetalert2'
import LoadingIndicator from '../../components/LoadingIndicator'
import getProductosActive from '../../services/productos/getProductosActive'
import addProducto from '../../services/productos/addProducto'
import addProductoGasto from '../../services/productos/addProductoGasto'
import config from '../../config'

export default function AddInventarioFarmacia(props){

    const [loading, setLoading] = React.useState(true)
    // const [unidades, setUnidades] = React.useState([]);
    const [productos, setProductos] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);

    // const getUnidadesService = ()=>{
    //     setLoading(true)
    //     getUnidades({values:{}})
    //         .then(res=>{
    //             // console.log(res)
    //             let _res = JSON.parse(res)
    //             if(_res.response === 'success'){
    //                 // console.log(_res.data)
    //                 setUnidades(_res.data)
    //                 setLoading(false)
    //                 $('.selectpicker').selectpicker('refresh');
    //             }
    //         })
    // }

    const getMetodosPagoService = ()=>{
        setLoading(true)
        getMetodosPago({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMetodosPago(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    const getProductosService = ()=>{
        setLoading(true)
        getProductosActive({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setProductos(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    const onChangeProducto = async(value)=>{
        if(value === 'new'){
            document.getElementById('id_producto').value = ''
            $('.selectpicker').selectpicker('refresh');
            let nombre_producto;
            const { value: nombre } = await Swal.fire({
                title: 'Nombre del producto:',
                input: 'text',
                inputPlaceholder: 'Escríbelo aquí',
                confirmButtonText: 'Agregar'
            })
            
            if (nombre) {
                nombre_producto = nombre;
                addProductoService(nombre_producto)
            }
        }
    }

    const addProductoService = (nombre) =>{
        Swal.showLoading()
        let data = {
            nombre: nombre
        }

        addProducto({values:data})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    let newProducto = {
                        nombre: nombre,
                        id_producto: _res.id_producto
                    }
                    setProductos([...productos, newProducto])
                    document.getElementById('id_producto').value = _res.id_producto
                    Swal.fire({
                        title: 'Agregado',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false
                    })
                } else {
                    Swal.fire({
                        title: 'Error al agregar el producto',
                        text: 'Repórtalo con el desarrollador',
                        icon: 'error'
                    })
                }
                $('.selectpicker').selectpicker('refresh');
            })
            .catch(err =>{
                console.log(err)
                Swal.fire({
                    title: 'Error al agregar el producto',
                    text: 'Repórtalo con el desarrollador',
                    icon: 'error'
                })
            })
    }

    const onSubmitAddProducto = (e) =>{
        e.preventDefault()
        let formData = new FormData(e.target);
        let objectValues = Object.fromEntries(formData);
        objectValues.id_unidad = config.idUnidadFarmacia
        addProductoGasto({values:objectValues})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    Swal.fire({
                        title: 'Producto agregado correctamente',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false
                    })
                    $("#formAddProducto")[0].reset();
                    $(".selectpicker").selectpicker('refresh')
                } else {
                    Swal.fire({
                        title: 'Producto no se agregó',
                        text: 'Repórtalo con el desarrollador',
                        icon: 'error',
                        timer: 1000,
                        showConfirmButton: false
                    })
                }
            })
    }

    React.useEffect(()=>$('.selectpicker').selectpicker(),[])
    React.useEffect(getMetodosPagoService,[])
    React.useEffect(getProductosService,[])

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btn btn-gris noMargin"
            />
            
            <h1 className='my-3'>
                Alta de productos a farmacia
            </h1>
            <small className='text-muted'>
                Para agregar el producto debes ingresar: <span className='font-weight-bold'>la cantidad que se compró, cuánto costó en total, método de pago y cuándo se compró.</span>.
            </small>

            <hr />

            <LoadingIndicator show={loading} />
            {
                !loading &&
                <form onSubmit={onSubmitAddProducto} id='formAddProducto'>

                    <div className='row mt-3'>
                        <div className='col-4'>
                            <label htmlFor='id_tipo_gasto'>
                                Proveedor:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_tipo_gasto"
                                name="id_tipo_gasto"
                                required
                                // onChange={(e) => onChangeUnidad(e.target.value)}
                            >
                                <option value="">Elige un proveedor</option>
                                <option value="1">Material Dental Villa</option>
                                <option value="2">Depósito Azteca</option>
                                <option value="51">Depósito Xola</option>
                                <option value="52">Otro (desconocido)</option>
                            </select>
                        </div>
                        <div className='col-4'>
                            <label htmlFor='id_producto'>
                                Producto:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_producto"
                                name="id_producto"
                                data-live-search="true"
                                required
                                onChange={(e) => onChangeProducto(e.target.value)}
                            >
                                <option value="">Elige un producto</option>
                                <option value="new">+ Agregar nuevo producto</option>
                                {
                                    productos && 
                                    productos.map( producto => (
                                        <option key={ producto.id_producto } value={ producto.id_producto }>
                                            { producto.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-2'>
                            <label htmlFor='cantidad'>
                                Cantidad:
                            </label>
                            <input 
                                type="num" 
                                className="input"
                                id="cantidad"
                                name="cantidad"
                                maxLength="999999"
                                onKeyPress={ validateNoNumbers }
                                placeholder='1'
                                required
                            />
                        </div>
                        <div className='col-2'>
                            <label htmlFor='costo'>
                                Costo:
                            </label>
                            <input 
                                type="num" 
                                className="input"
                                id="costo"
                                name="costo"
                                maxLength="999999"
                                onKeyPress={ validateNoNumbers }
                                placeholder='$'
                                required
                            />
                        </div>
                        
                        <div className='col-4'>
                            <label htmlFor='id_metodo_pago'>
                                Método de pago:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_metodo_pago"
                                name="id_metodo_pago"
                                data-live-search="true"
                                required
                                // onChange={(e) => onChangeUnidad(e.target.value)}
                            >
                                <option value="">Elige un método de pago</option>
                                {
                                    metodosPago && 
                                        metodosPago.map( metodo_pago => (
                                            <option key={ metodo_pago.id_metodo_pago } value={ metodo_pago.id_metodo_pago }>
                                                { metodo_pago.nombre }
                                            </option>
                                        ))
                                }
                            </select>
                        </div>
                        
                        {/* <div className='col-4'>
                            <label htmlFor='id_unidad'>
                                Unidad:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_unidad"
                                name="id_unidad"
                                data-live-search="true"
                                required
                            >
                                <option value="">Elige una unidad</option>
                                {
                                    unidades && 
                                    unidades.map( unidad => (
                                        <option key={ unidad.id_unidad } value={ unidad.id_unidad }>
                                            { unidad.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div> */}

                        <div className="col-3">
                                <label htmlFor="fecha_compra">
                                    Fecha compra:
                                </label>
                                <input 
                                    type="date"
                                    className="input"
                                    id="fecha_compra"
                                    name="fecha_compra"
                                    // la ñerísima
                                    value={
                                        new Date(
                                            new Date()
                                                .toLocaleString("en-US", {timeZone: "America/Mexico_City"})
                                                .split(',')[0])
                                        .toISOString()
                                        .split('T')[0]
                                    }
                                    />
                            </div>
                    </div>

                    <hr className='my-4' /> 
                    
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-azul noMargin">
                            <Icon icon="fa-solid fa-plus mr-2" />
                            <Icon icon="fa-solid fa-file-medical mr-2" />
                            Agregar producto
                        </button>
                    </div>
                </form>
            }
        </section>
    )
}