import React from 'react'
import $ from 'jquery'
import Icon from '../../components/Icon'
import getUnidades from '../../services/unidades/getUnidades'
import getMetodosPago from '../../services/ingreso/getMetodosPago'
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import Swal from 'sweetalert2'
import addMaterial from '../../services/materiales/addMaterial'
import LoadingIndicator from '../../components/LoadingIndicator'
import addMaterialGasto from '../../services/materiales/addMaterialGasto'
import getMaterialesActive from '../../services/materiales/getMaterialesActive'
import config from '../../config'

export default function AddInventarioMateriales(props){

    const [loading, setLoading] = React.useState(true)
    const [unidades, setUnidades] = React.useState([]);
    const [materiales, setMateriales] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);

    const getUnidadesService = ()=>{
        setLoading(true)
        getUnidades({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setUnidades(_res.data)

                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    const getMetodosPagoService = ()=>{
        setLoading(true)
        getMetodosPago({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMetodosPago(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    const getMaterialesService = ()=>{
        setLoading(true)
        getMaterialesActive({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMateriales(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    const onChangeMaterial = async(value)=>{
        if(value === 'new'){
            document.getElementById('id_material').value = ''
            $('.selectpicker').selectpicker('refresh');
            let nombre_material;
            const { value: nombre } = await Swal.fire({
                title: 'Nombre del material:',
                input: 'text',
                inputPlaceholder: 'Escríbelo aquí',
                confirmButtonText: 'Agregar'
            })
            
            if (nombre) {
                nombre_material = nombre;
                addMaterialService(nombre_material)
            }
        }
    }

    const addMaterialService = (nombre) =>{
        Swal.showLoading()
        let data = {
            nombre: nombre
        }

        addMaterial({values:data})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    let newMaterial = {
                        nombre: nombre,
                        id_material: _res.id_material
                    }
                    setMateriales([...materiales, newMaterial])
                    document.getElementById('id_material').value = _res.id_material
                    Swal.fire({
                        title: 'Agregado',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false
                    })
                } else {
                    Swal.fire({
                        title: 'Error al agregar el material',
                        text: 'Repórtalo con el desarrollador',
                        icon: 'error'
                    })
                }
                $('.selectpicker').selectpicker('refresh');
            })
            .catch(err =>{
                console.log(err)
                Swal.fire({
                    title: 'Error al agregar el material',
                    text: 'Repórtalo con el desarrollador',
                    icon: 'error'
                })
            })
    }

    const onSubmitAddMaterial = (e) =>{
        e.preventDefault()
        let formData = new FormData(e.target);
        let objectValues = Object.fromEntries(formData);

        // Adjuntamos el id_unidad de Administracion
        objectValues.id_unidad = config.idUnidadAdministracion
        addMaterialGasto({values:objectValues})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    Swal.fire({
                        title: 'Material agregado correctamente',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false
                    })
                    $("#formAddMaterial")[0].reset();
                    $(".selectpicker").selectpicker('refresh')
                } else {
                    Swal.fire({
                        title: 'Material no se agregó',
                        text: 'Repórtalo con el desarrollador',
                        icon: 'error',
                        timer: 1000,
                        showConfirmButton: false
                    })
                }
            })
    }

    React.useEffect(()=>$('.selectpicker').selectpicker(),[])
    React.useEffect(getUnidadesService,[])
    React.useEffect(getMetodosPagoService,[])
    React.useEffect(getMaterialesService,[])

    return (
        <section>

            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btn btn-gris noMargin"
            />

            <h1 className='my-3'>
                Alta de materiales
            </h1>

            <LoadingIndicator show={loading} />
            {
                !loading &&
                <form onSubmit={onSubmitAddMaterial} id='formAddMaterial'>

                    <div className="row">
                        <div className='col-12'>
                            <label htmlFor='id_unidad'>
                                Unidad:
                            </label>
                            <br/>
                            <small>
                                Si eliges "Administración", se manejará la existencia del material en el inventario.
                            </small>
                            <br/>
                            <select 
                                className="input selectpicker mt-2"
                                id="id_unidad"
                                name="id_unidad"
                                data-live-search="true"
                                required
                            >
                                <option value="">Elige una unidad</option>
                                {
                                    unidades && 
                                    unidades.map( unidad => {
                                        if( unidad.id_unidad === '17' ||
                                            unidad.id_unidad === '18' || 
                                            unidad.id_unidad === '19'){
                                                return null;
                                            }
                                        
                                        return (
                                            <option key={ unidad.id_unidad } value={ unidad.id_unidad }>
                                                { unidad.nombre }
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <hr className='my-3'/>

                    <p>
                        Debes elegir un proveedor y agregar los materiales comprados uno por uno. 
                    </p>
                    <small className='text-muted'>
                        De cada material debes agregar <span className='font-weight-bold'>la cantidad que se compró, cuánto costó en total, método de pago y a qué unidad pertenece</span>.
                    </small>

                    <div className='row mt-3'>
                        <div className='col-4'>
                            <label htmlFor='id_tipo_gasto'>
                                Proveedor:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_tipo_gasto"
                                name="id_tipo_gasto"
                                required
                                // onChange={(e) => onChangeUnidad(e.target.value)}
                            >
                                <option value="">Elige un proveedor</option>
                                <option value="1">Material Dental Villa</option>
                                <option value="2">Depósito Azteca</option>
                                <option value="51">Depósito Xola</option>
                                <option value="54">Proveedor Gardeent</option>
                                <option value="52">Otro (desconocido)</option>
                            </select>
                        </div>
                        <div className='col-4'>
                            <label htmlFor='id_material'>
                                Material:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_material"
                                name="id_material"
                                data-live-search="true"
                                required
                                onChange={(e) => onChangeMaterial(e.target.value)}
                            >
                                <option value="">Elige un material</option>
                                <option value="new">+ Agregar nuevo material</option>
                                {
                                    materiales && 
                                    materiales.map( material => (
                                        <option key={ material.id_material } value={ material.id_material }>
                                            { material.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-2'>
                            <label htmlFor='cantidad'>
                                Cantidad:
                            </label>
                            <input 
                                type="num" 
                                className="input"
                                id="cantidad"
                                name="cantidad"
                                maxLength="999999"
                                onKeyPress={ validateNoNumbers }
                                placeholder='1'
                                required
                            />
                        </div>
                        <div className='col-2'>
                            <label htmlFor='costo'>
                                Costo:
                            </label>
                            <input 
                                type="num" 
                                className="input"
                                id="costo"
                                name="costo"
                                maxLength="999999"
                                onKeyPress={ validateNoNumbers }
                                placeholder='$'
                                required
                            />
                        </div>
                        
                        <div className='col-4'>
                            <label htmlFor='id_metodo_pago'>
                                Método de pago:
                            </label>
                            <br/>
                            <select 
                                className="input selectpicker"
                                id="id_metodo_pago"
                                name="id_metodo_pago"
                                data-live-search="true"
                                required
                                // onChange={(e) => onChangeUnidad(e.target.value)}
                            >
                                <option value="">Elige un método de pago</option>
                                {
                                    metodosPago && 
                                        metodosPago.map( metodo_pago => (
                                            <option key={ metodo_pago.id_metodo_pago } value={ metodo_pago.id_metodo_pago }>
                                                { metodo_pago.nombre }
                                            </option>
                                        ))
                                }
                            </select>
                        </div>

                        <div className="col-3">
                                <label htmlFor="fecha_compra">
                                    Fecha compra:
                                </label>
                                <input 
                                    type="date"
                                    className="input"
                                    id="fecha_compra"
                                    name="fecha_compra"
                                    />
                            </div>
                    </div>

                    <hr className='my-4' /> 
                    
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-azul noMargin">
                            <Icon icon="fa-solid fa-plus mr-2" />
                            <Icon icon="fa-solid fa-file-medical mr-2" />
                            Agregar material
                        </button>
                    </div>
                </form>
            }
        </section>
    )
}