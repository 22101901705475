import React from 'react';
import Swal from 'sweetalert2';
import $ from 'jquery';

export default function TableElementMaterialUnidad({ element, permisosUsuario, setElementToEdit }) {

    let { id_material, id_unidad, nombre, total_cantidad_disponible, fecha_ultima_compra} = element;

    const onCheckSalidaMaterial = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el proceso?' : 'Quieres deshabilitar el proceso?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                // let values = new FormData()
                // values.append('folio', folio)
                // values.append('activo', activo)
                // let objectValues = Object.fromEntries(values);
                // // console.log({objectValues})
                // changeEstatusPermisionarioService({values:objectValues}).then(response => {
                //     // console.log(response)
                //     let _respuesta = JSON.parse(response);
                //     if (_respuesta.response === "success") {
                //         setEstadoEstatus(activo)
                //         Swal.fire(
                //             'Estatus actualizado',
                //             '',
                //             'success'
                //         )
                //     }
                    
                // })
            }
        })
    }

    return (
        <tr key={id_material}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* <Link to={`/inventario/${id_unidad}/${id_material}`} className="dropdown-item">
                                Ver
                            </Link> */}
                            {/* <a href className="dropdown-item" onClick={() => onClickEstatus(false)}>
                                Historial de salidas
                            </a> */}
                            {
                                (permisosUsuario.inventarioMateriales.escribir === '1') &&
                                    <a href className="dropdown-item text-black" onClick={() => {
                                        if(total_cantidad_disponible >= 1){
                                            setElementToEdit(element)
                                            $("#modalAddSalidaMaterial").modal('show')
                                            return;
                                        }

                                        Swal.fire({
                                            title: "No hay materiales",
                                            text: 'Primero agrega más al inventario pulsando el botón de "+"',
                                            icon: "info"
                                        })
                                    }}>
                                        Marcar una salida de material
                                    </a>
                            }
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{nombre}</p>
                        {
                            (total_cantidad_disponible <= 2 && total_cantidad_disponible > 0) &&
                                <div className="table__estatus">
                                    <span className={ `mt-2 table__estatus-warning` }>
                                        Casi por agotarse
                                    </span>
                                </div>
                        }
                        {
                            (total_cantidad_disponible === 0) &&
                                <div className="table__estatus">
                                    <span className={ `mt-2 table__estatus-inactive` }>
                                        Agotado
                                    </span>
                                </div>
                        }
                    </div>
                </div>
            </th>

            <td>
                <p>{parseFloat(total_cantidad_disponible).toFixed(0)}</p>
            </td>

            <td>
                {
                    fecha_ultima_compra
                }
            </td>

        </tr>
    )
    
}