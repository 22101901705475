import React from "react";
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import getUltimasSalidasProductoByUnidad from "../../services/inventario_productos/getUltimasSalidasProductoByUnidad";

export default function ModalUltimasSalidasProducto({id_unidad, loading, modulosPermisos, getData}){

    const [ultimasSalidas, setUltimasSalidas] = React.useState([])
    
    const rows = [
        { id: 1, name: "Producto" },
        { id: 2, name: "A quién se le dio" },
        { id: 3, name: "Cantidad" }
    ];

    const getUltimasSalidasProducto = ()=>{
        let data = { id_unidad }

        getUltimasSalidasProductoByUnidad({values:data})
            .then(respuesta => {
                console.log(respuesta)
                let _res = JSON.parse(respuesta)
                if(_res.response === 'success'){
                    setUltimasSalidas(_res.data)
                } else {
                    setUltimasSalidas([])
                }
            })
            .catch(err => console.log(err))
    }

    const reload = ()=>{
        getUltimasSalidasProducto()
        getData()
    }

    React.useEffect(getUltimasSalidasProducto, [id_unidad, loading])

    return(
        <div className="modal" id="modalUltimasSalidasProducto" tabIndex="-1" role="dialog" aria-labelledby="modalUltimasSalidasProductoLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content" id="formEmitirReembolso">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalUltimasSalidasProductoLabel">
                            Últimas salidas de productos de la unidad
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            ( ultimasSalidas.length === 0 ) &&
                            <p>
                                No hay salidas recientes de productos.
                            </p>
                        }
                        {
                            ( ultimasSalidas.length !== 0 ) &&
                            <p>
                                Estas han sido las salidas más recientes de productos de la unidad:
                            </p>
                        }
                        {
                            ( ultimasSalidas.length !== 0 ) &&

                                <Table>
                                    <TableHead rows={rows} />

                                    <TableBody 
                                        elements={ultimasSalidas} 
                                        typeElement="salida_producto_unidad" 
                                        permisosUsuario={modulosPermisos} 
                                        reload={reload}
                                        // setElementToEdit={setMaterialSelected}
                                    />
                                </Table>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Salir</button>
                    </div>
                </div>
            </div>
        </div>
    )
}