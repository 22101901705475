import React from 'react';
import getMetodosPago from '../services/ingreso/getMetodosPago';

export default function useMetodosPago(){
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const getMetodosPagoService = () => {
        setLoading(true);
        getMetodosPago({values:{}})
            .then(res => {
                let _res = JSON.parse(res);
                if(_res.response === 'success'){
                    setMetodosPago(_res.data);
                }
                setLoading(false);
            })
    }

    React.useEffect(() => {
        getMetodosPagoService();
    }, [])

    return { metodosPago, loading, getMetodosPagoService }
}
