import React from 'react';
import './InventarioMateriales.css';
import Icon from "./../../components/Icon";
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import InputSearch from "./../../components/InputSearch";
import AddButton from "./../../components/AddButton";
import LoadingIndicator from "../../components/LoadingIndicator";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import $ from 'jquery';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import getUnidades from '../../services/unidades/getUnidades';
import getMaterialesUnidadByUnidad from '../../services/inventario_materiales/getMaterialesUnidadByUnidad';
import searchMaterialesUnidadByUnidad from '../../services/inventario_materiales/searchMaterialesUnidadByUnidad';
import ModalAddSalidaMaterial from '../../components/ModalAddSalidaMaterial';
import addSalidaMaterialUnidad from '../../services/inventario_materiales/addSalidaMaterialUnidad';
import ModalUltimasSalidasMaterial from '../../components/ModalUltimasSalidasMaterial';
import ModalComprasRecientesMaterial from '../../components/ModalComprasRecientesMaterial';

export default function InventarioMateriales({modulosPermisos}){

    // const [unidades, setUnidades] = React.useState([]);
    const [unidadSelected, setUnidadSelected] = React.useState("16")
    const [materialSelected, setMaterialSelected] = React.useState(null)
    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [orderBy, setOrderBy] = React.useState({})
    const [terminoBusqueda, setTerminoBusqueda] = React.useState("")

    const history = useHistory();

    const rows = [
        { id: 1, name: "Material" },
        { id: 2, name: "Cantidad total" },
        { id: 3, name: "Fecha de última compra" }
    ];

    // const getUnidadesService = ()=>{
    //     setLoading(true)
    //     getUnidades({values:{}})
    //         .then(res=>{
    //             let _res = JSON.parse(res)
    //             if(_res.response === 'success'){
    //                 setUnidades(_res.data)
    //                 setLoading(false)
    //                 $('.selectpicker').selectpicker('refresh');
    //             }
    //         })
    // }
    
    const getInventarioMaterialesUnidad = ()=>{
        setElements([])
        setLoading(true)
        
        if(unidadSelected === ""){
            setElements([])
            setLoading(false)
            return;
        }

        if(Object.keys(orderBy).length !== 0 || terminoBusqueda !== ""){
            search()
            return;
        }

        let data = { id_unidad: unidadSelected }

        getMaterialesUnidadByUnidad({values:data}).then(response => {
            console.log(response)
            let _respuesta = JSON.parse(response);
            if(_respuesta.response === "error"){
                setElements([])
            } else {
                setElements(_respuesta.data);
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })

    }

    const search = () =>{
        setLoading(true)
        let data = { id_unidad: unidadSelected }

        if(terminoBusqueda !== "")
            data.q = terminoBusqueda
        if(Object.keys(orderBy).length !== 0)
            data.ordenar = orderBy

        searchMaterialesUnidadByUnidad({values:data})
            .then((response) =>{
                console.log(response)
                let _response = JSON.parse(response)
                if(_response.response === "success"){
                    setElements(_response.data)
                } else {
                    setElements([])
                }
                setLoading(false)
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    const onSubmitSearch = (e) =>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        let terminoBusqueda = objectValues.q
        setTerminoBusqueda(terminoBusqueda)
    }

    const onClickOrderBy = (campo, orden) => {
        let data = {
            campo: campo,
            orden: orden
        }
        setOrderBy(data)
    }

    const onSubmitModalAddSalidaMaterial = (e)=>{
        e.preventDefault()
        let form = new FormData(e.target)
        let object = Object.fromEntries(form)

        if( object.cantidad_salida > materialSelected.total_cantidad_disponible ){
            Swal.fire({
                title: `No puedes entregar ${object.cantidad_salida}, sólo hay ${materialSelected.total_cantidad_disponible} ${(materialSelected.total_cantidad_disponible === 1 ? "disponible" : "disponibles")}`,
                timer: 2000,
                showConfirmButton: false,
                icon: "error"
            })
            return;
        }

        object.id_unidad = unidadSelected
        object.id_material = materialSelected.id_material
        console.log(object)
        addSalidaMaterialUnidad({values:object})
            .then(respuesta => {
                console.log(respuesta)
                let _res = JSON.parse(respuesta);
                setMaterialSelected(null)
                if(_res.response === "success"){
                    Swal.fire({
                        title: "Salida de material marcadda",
                        timer: 1200,
                        showConfirmButton: false,
                        icon: "success"
                    })
                    getInventarioMaterialesUnidad()
                    $("#modalAddSalidaMaterial").modal("hide")
                    $("#formAddSalidaMaterial")[0].reset();
                    $('.selectpicker').selectpicker('refresh');
                } else {
                    Swal.fire({
                        title: "No se marcó la salida",
                        timer: 1200,
                        showConfirmButton: false,
                        icon: "error"
                    })
                }
            })
    }

    // React.useEffect(getUnidadesService, []);

    React.useEffect(getInventarioMaterialesUnidad, [unidadSelected, terminoBusqueda, orderBy])

    return(
        <section>

            <div className="d-flex justify-content-between">

                <div className="d-flex justify-content-between">
                    <h1>
                        Inventario
                    </h1>

                    {
                        modulosPermisos.inventarioMateriales.escribir === '1' && modulosPermisos.materiales.escribir === '1' &&
                            <AddButton text="Nuevo material" onClick={ async () => {
                                // setElementToEdit(null)
                                // $("#modalAddTipoEgreso").modal('show')
                                history.push('/inventario/agregar')
                            } } />
                    }
                </div>

            </div>

            {/* <h4 className='mt-4'>
                Elige una unidad para ver sus materiales
            </h4> */}

            <div className="row mt-2">
                <div className="col-lg-8 col-md-9 col-sm-12 col-12 mt-md-0 mt-3">
                    <InputSearch textPlaceholder="Buscar material" onSubmit={onSubmitSearch}/>
                </div>
                <div className="col-lg-4 col-md-3 col-sm-12 col-12 d-flex justify-content-md-end mt-md-0 mt-3">
                    <div className="dropdown mx-2">
                        <button className="btn btn-sm btn-light dropdown-toggle text-muted" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-arrow-down-short-wide mr-2"></i>
                                Ordenar
                        </button>

                        <div className="dropdown-menu">
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','ASC')}>Nombre de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','DESC')}>Nombre de Z-A</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('total_cantidad_disponible','ASC')}>Cantidad de 1-9</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('total_cantidad_disponible','DESC')}>Cantidad de 9-1</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_ultima_compra','ASC')}>Fecha compra de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_ultima_compra','DESC')}>Fecha compra de Z-A</button>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="my-3" />
            
            <div className='d-flex'>
                {
                    ( Object.keys(elements).length !== 0 && !loading ) &&
                        <button className='btn btn-sm btn-gris mr-3'
                            onClick={()=>{
                                $("#modalUltimasSalidasMaterial").modal('show')
                            }}
                        >
                            Ver últimas salidas
                        </button>
                }
                {
                    ( Object.keys(elements).length !== 0 && !loading ) &&
                        <button className='btn btn-sm btn-gris'
                            onClick={()=>{
                                $("#modalComprasRecientesMaterial").modal('show')
                            }}
                        >
                            Ver compras recientes
                        </button>
                }
            </div>

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="material_unidad" 
                            permisosUsuario={modulosPermisos} 
                            setElementToEdit={setMaterialSelected}
                        />
                    </Table>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

            <ModalAddSalidaMaterial 
                onSubmitModal={onSubmitModalAddSalidaMaterial}
                id_unidad={unidadSelected}
                material={(materialSelected !== null) && materialSelected}
            />

            <ModalUltimasSalidasMaterial
                id_unidad={unidadSelected}
                loading={loading}
                modulosPermisos={modulosPermisos}
                getData={getInventarioMaterialesUnidad}
            />

            <ModalComprasRecientesMaterial
                id_unidad={unidadSelected}
                loading={loading}
                modulosPermisos={modulosPermisos}
                getData={getInventarioMaterialesUnidad}
            />

        </section>
    );
}