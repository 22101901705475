import React from "react"
import getTipoUsers from "../../services/user/getTipoUsers"
import $ from 'jquery'
import getTodosLosPermisos from "../../services/user/getTodosLosPermisos"

export default function ModalAddUsuario({onSubmitModal, elementToEdit}){

    const [tiposUsuario, setTipoUsuario] = React.useState([])
    const [permisosSistema, setPermisosSistema] = React.useState([])

    const getTiposUsuarioService = ()=>{
        getTipoUsers({values:{}})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setTipoUsuario(_res.data)
                    $('.selectpicker').selectpicker('refresh');
                    // setDataFormulario()
                }
            })
    }
    
    const getPermisosSistemaService = ()=>{
        getTodosLosPermisos({values:{}})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setPermisosSistema(_res.data)
                    $('.selectpicker').selectpicker('refresh');
                    // setDataFormulario()
                }
            })
    }

    const setElementToEdit = () =>{

        if(elementToEdit !== null){
            document.getElementById('nombre_usuario').value = elementToEdit.nombre_usuario;
            document.getElementById('nombre').value = elementToEdit.nombre;
            if(elementToEdit.id_tipo_usuario !== undefined){
                document.getElementById('id_tipo_usuario').value = elementToEdit.id_tipo_usuario;
            } else {
                document.getElementById('id_tipo_usuario').value = ""
            }

            $('#permisosSistema option').prop('selected', false);
            
            if(elementToEdit.permisos){
                elementToEdit.permisos.forEach(permiso => {
                    $('#permisosSistema option[value="' + permiso.id_permiso +'_leer"]').prop('selected', (permiso.leer === "1"));
                    $('#permisosSistema option[value="' + permiso.id_permiso +'_escribir"]').prop('selected', (permiso.escribir === "1"));
                    $('#permisosSistema option[value="' + permiso.id_permiso +'_editar"]').prop('selected', (permiso.editar === "1"));
                    $('#permisosSistema option[value="' + permiso.id_permiso +'_eliminar"]').prop('selected', (permiso.eliminar === "1"));
                })
            }
        }

        if(elementToEdit === null){
            document.getElementById('nombre_usuario').value = "";
            document.getElementById('id_tipo_usuario').value = "";
            document.getElementById('nombre').value = "";
            $('#permisosSistema option').prop('selected', false);
        }

        $('.selectpicker').selectpicker('refresh');
    }

    React.useEffect(setElementToEdit, [elementToEdit])
    React.useEffect(getTiposUsuarioService, [])
    React.useEffect(getPermisosSistemaService, [])

    return(
        <div className="modal" id="modalAddUsuario" tabIndex="-1" role="dialog" aria-labelledby="modalAddUsuarioLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} className="modal-content" id="formAddUsuario">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddUsuarioLabel">Agregar usuario</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="nombre_usuario">
                                    Nombre de usuario:
                                </label>
                                <input 
                                    type="text"
                                    className="input" 
                                    id="nombre_usuario"
                                    name="nombre_usuario"
                                    placeholder="Nombre de usuario con el que entra al sistema"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="nombre">
                                    Nombre completo:
                                </label>
                                <input 
                                    type="text"
                                    className="input" 
                                    id="nombre"
                                    name="nombre"
                                    placeholder="Nombre de quién usará el usuario"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_tipo_usuario">
                                    Tipo de usuario:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_tipo_usuario"
                                    name="id_tipo_usuario"
                                    required
                                >  
                                    <option value="">Elige un método de pago</option>
                                    {
                                        tiposUsuario && 
                                        tiposUsuario.map( tipo_usuario => (
                                            <option key={ tipo_usuario.id_tipo_usuario } value={ tipo_usuario.id_tipo_usuario }>
                                                { tipo_usuario.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="permisosSistema">
                                    Permisos del sistema:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="permisosSistema"
                                    name="permisosSistema"
                                    multiple
                                    required
                                >  
                                    {
                                        permisosSistema && 
                                        permisosSistema.map( (permiso) => (
                                            <optgroup key={ permiso.id_permiso } label={ permiso.nombre_modulo }>
                                                <option value={ `${permiso.id_permiso}_leer` }>Leer</option>
                                                <option value={ `${permiso.id_permiso}_escribir` }>Escribir</option>
                                                <option value={ `${permiso.id_permiso}_editar` }>Editar</option>
                                                <option value={ `${permiso.id_permiso}_eliminar` }>Eliminar</option>
                                            </optgroup>
                                        ))
                                    }
                                </select>
                            </div>
                            {
                                (elementToEdit === undefined || elementToEdit === null) &&
                                    <React.Fragment>
                                        <div className="col-12">
                                            <label htmlFor="password">
                                                Contraseña:
                                            </label>
                                            <div className="input-group mb-3">
                                                <input 
                                                    className="form-control" 
                                                    type="password"
                                                    id="password" 
                                                    name="password" 
                                                    placeholder="Asigna una contraseña"
                                                    required
                                                />
                                                <button 
                                                    onClick={() => {
                                                        if($('#password').attr("type") === "text"){
                                                            $('#password').attr('type', 'password');
                                                            $('.iconTogglePassword').removeClass( "fa-eye-slash" );
                                                            $('.iconTogglePassword').addClass( "fa-eye" );
                                                        }else if($('#password').attr("type") === "password"){
                                                            $('#password').attr('type', 'text');
                                                            $('.iconTogglePassword').addClass( "fa-eye-slash" );
                                                            $('.iconTogglePassword').removeClass( "fa-eye" );
                                                        }
                                                    }}
                                                    className="input-group-text"
                                                    type="button" 
                                                    id="button-addon1"
                                                >
                                                    <i className="far fa-eye-slash iconTogglePassword" id="togglePassword"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary">
                            {
                                elementToEdit !== null ? "Actualizar" : "Agregar"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}