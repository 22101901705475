import React from "react";
import AlertWarningSearch from "../AlertWarningSearch";
import LoadingIndicator from "../LoadingIndicator";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import $ from 'jquery';
import CardIngresosUnidad from "../CardIngresosUnidad";
import getIngresosEmpleado from "../../services/empleado/getIngresosEmpleado";
import getIngresosTotalesEmpleado from "../../services/empleado/getIngresosTotalesEmpleado";
import FilterButton from "../FilterButton";
import Icon from "../Icon";
import getEmpleadoUnidades from "../../services/empleado/getEmpleadoUnidades";

export default function TrabajadorIngresos(props){

    let { modulosPermisos, id_trabajador } = props

    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    // VARIABLES PARA LOS INGRESOS
    const [loading, setLoading] = React.useState(true);
    const [ingresos, setIngresos] = React.useState([]);
    const [ingresosTotales, setIngresosTotales] = React.useState({})
    // const [unidadesTrabajador, setUnidadesTrabajador] = React.useState([])
    
    const [idUnidadIngreso, setIdUnidadIngreso] = React.useState(null)
    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [loadingFilters, setLoadingFilters] = React.useState(false)

    const rowsEgresos = [
        { id: 1, name: "No. de recibo" },
        { id: 2, name: "Proceso" },
        { id: 3, name: "Cantidad" },
        { id: 4, name: "Paciente" }
    ];

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    function getIngresosTrabajadorService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_trabajador: props.id_trabajador
        }
        if(idUnidadIngreso !== null){
            data.id_unidad = idUnidadIngreso
        }
        getIngresosEmpleado({values:data}).then(res => {
            // console.log(res)
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setIngresos(_res.data);
            } else {
                setIngresos([])
            }
            setTimeout(()=>{
                setLoading(false)
            }, 1000)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }

    function getIngresosTotalesTrabajadorService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_trabajador: props.id_trabajador
        }
        if(idUnidadIngreso !== null){
            data.id_unidad = idUnidadIngreso
        }
        getIngresosTotalesEmpleado({values:data}).then(res => {
            // console.log(res)
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setIngresosTotales(_res.data);
            } else {
                setIngresosTotales({})
            }
            setTimeout(()=>{
                setLoading(false)
            }, 1000)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }

    const reloadIngresos = ()=>{
        getIngresosTrabajadorService()
        getIngresosTotalesTrabajadorService()
    }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        setIdUnidadIngreso(object.id_unidad)
    }

    const onClickDeleteFilters = () => {
        setIdUnidadIngreso(null)
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(()=>{
            setLoadingFilters(false)
        }, 50)
    }

    React.useEffect(getIngresosTrabajadorService, [])
    React.useEffect(getIngresosTotalesTrabajadorService, [])
    React.useEffect(()=>$('#fecha_inicial').val(fechaInicio), [])

    React.useEffect(() => {
        reloadIngresos()
    },[fechaInicio, fechaFinal, idUnidadIngreso]);

    return(
        <>
            <CardIngresosUnidad
                total_ingresos_efectivo={ingresosTotales.total_ingresos_efectivo}
                total_ingresos_banco={ingresosTotales.total_ingresos_banco}
                total_ingresos={ingresosTotales.total_ingresos}
            />

            <div className="mt-5 mb-4">    
                <h4 className="">Ingresos del trabajador</h4>

                <form onSubmit={onSubmitFechas} className={`d-flex justify-content-between align-items-center mt-3`}>
                    <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                    <p className="mx-2">y</p>
                    <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                    <button className='btn btn-gris'><i className="fa-solid fa-search"></i></button>
                </form>
            </div>

            <hr className="my-2" />

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton text="Unidad" type="id_unidad" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <button className={`btn btn-sm btn-light text-muted ${!showDeleteFilters && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                    <Icon icon='fa-solid fa-x' /> Eliminar
                </button>

            </div>


            <LoadingIndicator show={loading} />

            {
                ( ingresos.length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rowsEgresos} />

                        <TableBody reload={reloadIngresos} elements={ingresos} typeElement="ingresos_empleado" permisosUsuario={modulosPermisos} />
                    </Table>
            }

            {
                ( ingresos.length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }
        </>
    )
}