import React from 'react'
import { useHistory } from 'react-router';
import AddButton from '../../components/AddButton'
import $, { error } from 'jquery'
import InputSearch from '../../components/InputSearch';
import LoadingIndicator from '../../components/LoadingIndicator';
import Table from '../../components/Table';
import TableHead from '../../components/Table/TableHead';
import TableBody from '../../components/Table/TableBody';
import AlertWarningSearch from '../../components/AlertWarningSearch';
import useOrdenesTrabajo from '../../hooks/useOrdenesTrabajo';
import FilterButton from '../../components/FilterButton';
import Icon from '../../components/Icon';
import Swal from 'sweetalert2';
import getOrdenTrabajo from '../../services/orden_trabajo/getOrdenTrabajo';
import ModalViewOrdenTrabajo from '../../components/ModalViewOrdenTrabajo';
import ModalEnviarTrabajoLaboratorio from '../../components/ModalEnviarTrabajoLaboratorio';
import addHistorialOrdenTrabajo from '../../services/historial_orden_trabajo/addHistorialOrdenTrabajo';

export default function Laboratorios({modulosPermisos}){

    // const [elements, setElements] = React.useState([]);
    // const [loading, setLoading] = React.useState(true);
    const { 
        getAllOrdenesTrabajoService,
        setOrderBy,
        setTerminoBusqueda,
        setFilters,
        getOrdenTrabajoById,
        ordenTrabajo,
        ordenesTrabajo,
        loadingOrdenesTrabajo,
        filters
    } = useOrdenesTrabajo();

    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [loadingFilters, setLoadingFilters] = React.useState(false)
    const [elementSelected, setElementSelected] = React.useState(null)

    const history = useHistory();

    const rows = [
        { id: 1, name: "Paciente" },
        { id: 2, name: "Fechas" },
        { id: 3, name: "Laboratorio" },
        { id: 4, name: "Estado" }
    ];

    const onClickOrderBy = (campo, orden) => {
        let data = {
            campo: campo,
            orden: orden
        }
        setOrderBy(data)
    }

    const onSubmitSearch = (e) =>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        let terminoBusqueda = objectValues.q
        setTerminoBusqueda(terminoBusqueda)
    }

    const reload = () => {
        getAllOrdenesTrabajoService()
    }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        let nuevosFiltros = [...filters]

        let newFiltros = nuevosFiltros.filter((value) =>{
            return value.campo !== tipoFiltro
        })

        newFiltros.push({ campo: tipoFiltro, data: object })
        setFilters(newFiltros)
    }

    const onClickDeleteFilters = () => {
        setFilters([])
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(()=>{
            setLoadingFilters(false)
        }, 50)
    }

    const onClickVerDetalles = (id_orden_trabajo) => {
        // Mostramos el loading
        Swal.fire({
            title: 'Cargando',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false
        })

        // Obtenemos los detalles de la orden de trabajo
        getOrdenTrabajoById(id_orden_trabajo)
    }

    const onSubmitModalEnviarTrabajoLaboratorio = (e) => {
        e.preventDefault()

        // Desactivamos el botón submit del formulario
        let btnSubmit = document.getElementById('btnModalEnviarTrabajoLaboratorioSubmit')
        btnSubmit.disabled = true
        btnSubmit.innerHTML = 'Asignando...'

        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)

        let es_orden_final = 0;
        if(objectValues.es_orden_final === "on"){
            es_orden_final = 1;
        }
        objectValues.es_orden_final = es_orden_final;
        objectValues.id_orden_trabajo = elementSelected.id_orden_trabajo;
        console.log({ objectValues })

        addHistorialOrdenTrabajo({values: objectValues})
            .then((response)=>{
                console.log(response)
                let _response = JSON.parse(response)
                console.log(_response)
                if (_response.response === "success") {
                    Swal.fire({
                        title: 'Se envió correctamente',
                        icon: 'success',
                        timer: 2000,
                    })
                    $("#modalEnviarTrabajoLaboratorio").modal('hide')
                    $("#formModalEnviarTrabajoLaboratorio")[0].reset()
                    reload()
                }
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    title: 'Error al enviar',
                    icon: 'warning',
                    text: 'Inténtalo de nuevo más tarde',
                    timer: 2000,
                })
            })

        // Habilitamos el botón submit del formulario
        btnSubmit.disabled = false
        btnSubmit.innerHTML = 'Enviar'
    }

    React.useEffect(()=>{
        if(ordenTrabajo !== null){
            $("#modalViewOrdenTrabajo").modal('show')
        }
    }, [ordenTrabajo])

    return(
        <section>

            <div className="d-flex justify-content-between">

                <div className="d-flex justify-content-between">
                    <h1>Trabajos de laboratorio</h1>

                    {
                        modulosPermisos.laboratorios.escribir === '1' &&
                            <AddButton text="Agregar trabajo" onClick={ async () => {
                                // setElementToEdit(null)
                                // $("#modalAddTrabajoLaboratorio").modal('show')
                                history.push('/laboratorios/agregar')
                            } } />
                    }
                </div>

            </div>

            <div className="row mt-2">
                <div className="col-lg-8 col-md-9 col-sm-12 col-12 mt-md-0 mt-3">
                    <InputSearch textPlaceholder="Buscar trabajo de laboratorio" onSubmit={onSubmitSearch}/>
                </div>
                <div className="col-lg-4 col-md-3 col-sm-12 col-12 d-flex justify-content-md-end mt-md-0 mt-3">
                    <div className="dropdown mx-2">
                        <button className="btn btn-sm btn-light dropdown-toggle text-muted" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-arrow-down-short-wide mr-2"></i>
                                Ordenar
                        </button>

                        <div className="dropdown-menu">
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_entrega','ASC')}>Fecha entrega de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_entrega','DESC')}>Fecha entrega de Z-A</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_recoleccion','ASC')}>Fecha recolección de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_recoleccion','DESC')}>Fecha recolección de Z-A</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','ASC')}>Fecha alta de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','DESC')}>Fecha alta de Z-A</button>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="my-3" />

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton text="Estado orden" type="id_estado_orden_trabajo" onSubmit={onSubmitFilter} />
                                <FilterButton text="Terminado" type="terminado" onSubmit={onSubmitFilter} />
                                <FilterButton text="Fecha alta" type="fecha_alta" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <button className={`btn btn-sm btn-light text-muted ${!showDeleteFilters && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                    <Icon icon='fa-solid fa-x' /> Eliminar
                </button>

            </div>

            <LoadingIndicator show={loadingOrdenesTrabajo} />

            {
                ( Object.keys(ordenesTrabajo).length !== 0 && !loadingOrdenesTrabajo ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={ordenesTrabajo} 
                            typeElement="orden_trabajo" 
                            permisosUsuario={modulosPermisos} 
                            reload={reload}
                            onClickVerDetalles={onClickVerDetalles}
                            setElementToEdit={setElementSelected}
                        />
                    </Table>
            }

            {
                ( Object.keys(ordenesTrabajo).length === 0 && !loadingOrdenesTrabajo ) &&
                    
                    <AlertWarningSearch />
            }

            <ModalViewOrdenTrabajo 
                elementToEdit={ordenTrabajo}
                permisosUsuario={modulosPermisos} 
                onClickVerDetalles={onClickVerDetalles}
            />

            <ModalEnviarTrabajoLaboratorio
                onSubmitModal={onSubmitModalEnviarTrabajoLaboratorio}
            />

        </section>
    )
}