import React from "react";
import $ from "jquery";
import Icon from "../../components/Icon";
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import Swal from "sweetalert2";
import LoadingIndicator from "../../components/LoadingIndicator";
import useExpedientesPacientes from "../../hooks/useExpedientesPacientes";
import useUnidad from "../../hooks/useUnidad";
import useEmpleados from "../../hooks/useEmpleados";
import useLaboratorios from "../../hooks/laboratorio/useLaboratorios";
import useDientes from "../../hooks/useDientes";
import addOrdenTrabajo from "../../services/orden_trabajo/addOrdenTrabajo";
import ToggleButton from "../../components/ToggleButton";
import useMetodosPago from "../../hooks/useMetodosPago";

export default function AddTrabajoLaboratorio() {
  const [idLaboratorioSelected, setIdLaboratorioSelected] =
    React.useState(null);

  const { expedientes, loadingExpedientes } = useExpedientesPacientes();
  const { unidades, loadingUnidades } = useUnidad();
  const { empleados, loadingEmpleados, getEmpleadosByUnidadService } =
    useEmpleados();
  const {
    laboratorios,
    loadingLaboratorios,
    addLaboratorioService,
    getTrabajosByLaboratorioService,
    trabajosLaboratorio,
    loadingTrabajosLaboratorio,
    addTrabajoLaboratorioService,
  } = useLaboratorios();
  const { dientes } = useDientes();

  const { metodosPago } = useMetodosPago();

  React.useEffect(() => {
    $(".selectpicker").selectpicker();
  }, []);

  React.useEffect(() => {
    $(".selectpicker").selectpicker("refresh");
  }, [
    loadingExpedientes,
    loadingUnidades,
    loadingEmpleados,
    loadingLaboratorios,
    loadingTrabajosLaboratorio,
    laboratorios,
    trabajosLaboratorio,
  ]);

  function onChangeUnidad(value) {
    getEmpleadosByUnidadService(value);
  }

  const onChangeLaboratorio = async (value) => {
    if (value === "new") {
      document.getElementById("id_laboratorio").value = "";
      $(".selectpicker").selectpicker("refresh");
      let nombre_laboratorio;

      const { value: nombre } = await Swal.fire({
        title: "Nombre del laboratorio:",
        input: "text",
        inputPlaceholder: "Escríbelo aquí",
        confirmButtonText: "Agregar",
        showCancelButton: true,
      });

      if (nombre) {
        nombre_laboratorio = nombre;
        addLaboratorioService(nombre_laboratorio);
      }
      return;
    }

    // Si no es un nuevo laboratorio, obtenemos los trabajos del laboratorio elegido
    if (value !== "") {
      // Marcamos el id de laboratorio como marcado
      setIdLaboratorioSelected(value);
      // Obtenemos sus trabajos de laboratorio
      getTrabajosByLaboratorioService(value);
    }
  };

  const onChangeTrabajoLaboratorio = async (value) => {
    if (value === "new") {
      document.getElementById("id_trabajo_laboratorio").value = "";
      $(".selectpicker").selectpicker("refresh");
      let nombre_trabajo_laboratorio;

      const { value: nombre } = await Swal.fire({
        title: "Nombre del trabajo:",
        input: "text",
        inputPlaceholder: "Escríbelo aquí",
        confirmButtonText: "Agregar",
        showCancelButton: true,
      });

      if (nombre) {
        nombre_trabajo_laboratorio = nombre;
        addTrabajoLaboratorioService(
          nombre_trabajo_laboratorio,
          idLaboratorioSelected
        );
      }
      return;
    }
  };

  function onChangeDiente() {
    let dientes = $("#organos_dentales").val();
    const selectElement = document.getElementById("organos_dentales");

    if (dientes.includes("1")) {
      // Desactivar todas las opciones excepto la opción 1
      for (let i = 0; i < selectElement.options.length; i++) {
        const option = selectElement.options[i];
        option.selected = option.value === "1";
      }
    }

    // Actualizar el valor del select
    $(selectElement).selectpicker("refresh");
  }

  const onSubmitAddTrabajoLaboratorio = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    let objectValues = Object.fromEntries(formData);
    let dientes = $("#organos_dentales").val();
    objectValues.organos_dentales = dientes;

    let es_orden_final = 0;
    if (objectValues.es_orden_final === "on") {
      es_orden_final = 1;
    }
    objectValues.es_orden_final = es_orden_final;

    console.log(objectValues);

    // Adjuntamos el id_unidad de Administracion
    addOrdenTrabajo({ values: objectValues }).then((res) => {
      console.log(res);
      let _res = JSON.parse(res);
      if (_res.response === "success") {
        Swal.fire({
          title: "Orden agregada correctamente",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        $("#formAddTrabajoLaboratorio")[0].reset();
        $(".selectpicker").selectpicker("refresh");
      } else {
        Swal.fire({
          title: "La orden no se agregó",
          text: "Repórtalo con el desarrollador",
          icon: "error",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    });
  };

  return (
    <section>
      <input
        action="action"
        onClick={() => {
          window.history.go(-1);
          return false;
        }}
        type="submit"
        value="Regresar"
        className="btn btn-gris noMargin"
      />

      <h1 className="my-3">Agregar trabajo de laboratorio (orden)</h1>

      <LoadingIndicator show={loadingExpedientes} />
      {!loadingExpedientes && (
        <form
          onSubmit={onSubmitAddTrabajoLaboratorio}
          id="formAddTrabajoLaboratorio"
        >
          <div className="row">
            {/* Nombre de la unidad */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="id_unidad" className="formulario__label">
                  Unidad que envía el trabajo:
                </label>
                <br />
                <select
                  className="input selectpicker"
                  id="id_unidad"
                  name="id_unidad"
                  data-live-search="true"
                  required
                  onChange={(e) => onChangeUnidad(e.target.value)}
                >
                  <option value="">Elige una unidad</option>
                  {unidades &&
                    unidades.map((unidad) => (
                      <option key={unidad.id_unidad} value={unidad.id_unidad}>
                        {unidad.nombre}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* Nombre del doctor */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="id_unidad" className="formulario__label">
                  Trabajador que lo solicita:
                </label>
                <br />
                <select
                  className="input selectpicker"
                  id="id_empleado_responsable_unidad"
                  name="id_empleado"
                  required
                >
                  {!loadingEmpleados && (
                    <>
                      {empleados &&
                        empleados.map((empleado) => (
                          <option
                            key={empleado.id_empleado}
                            value={empleado.id_empleado}
                          >
                            {empleado.nombre}
                          </option>
                        ))}
                    </>
                  )}
                </select>
              </div>
            </div>
            {/* Nombre del paciente */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="folio" className="formulario__label">
                  Folio del paciente:
                </label>
                <br />
                <select
                  className="input selectpicker"
                  id="folio"
                  name="folio"
                  data-live-search="true"
                  required
                  // onChange={onChangeFolio}
                >
                  {loadingExpedientes && (
                    <option value="">Cargando folios...</option>
                  )}
                  <option value="">Elige un folio</option>
                  {expedientes &&
                    expedientes.map((expediente) => (
                      <option key={expediente.folio} value={expediente.folio}>
                        {expediente.folio} : {expediente.nombrePaciente.trim()}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <hr />

          {/* Sección de datos del trabajo */}
          <div className="row">
            {/* Laboratorio */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <label htmlFor="id_laboratorio">Laboratorio:</label>
              <br />
              <select
                className="input selectpicker"
                id="id_laboratorio"
                name="id_laboratorio"
                data-live-search="true"
                required
                onChange={(e) => onChangeLaboratorio(e.target.value)}
              >
                {!loadingLaboratorios && (
                  <>
                    <option value="">Elige un laboratorio</option>
                    <option value="new">+ Agregar nuevo laboratorio</option>
                    {laboratorios &&
                      laboratorios.map((laboratorio) => (
                        <option
                          key={laboratorio.id_laboratorio}
                          value={laboratorio.id_laboratorio}
                        >
                          {laboratorio.nombre}
                        </option>
                      ))}
                  </>
                )}
                {loadingLaboratorios && (
                  <option value="">Cargando laboratorios...</option>
                )}
              </select>
            </div>
            {/* Tipo de trabajo */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <label htmlFor="id_trabajo_laboratorio">
                ¿Qué trabajo va a realizar?:
              </label>
              <br />
              <select
                className="input selectpicker"
                id="id_trabajo_laboratorio"
                name="id_trabajo_laboratorio"
                onChange={(e) => onChangeTrabajoLaboratorio(e.target.value)}
                required
              >
                {!loadingTrabajosLaboratorio && (
                  <>
                    <option value="">Elige un trabajo de laboratorio</option>
                    <option value="new">
                      + Agregar nuevo trabajo de laboratorio
                    </option>
                    {trabajosLaboratorio &&
                      trabajosLaboratorio.map((trabajo_laboratorio) => (
                        <option
                          key={trabajo_laboratorio.id_trabajo_laboratorio}
                          value={trabajo_laboratorio.id_trabajo_laboratorio}
                        >
                          {trabajo_laboratorio.nombre}
                        </option>
                      ))}
                  </>
                )}
              </select>
            </div>
            {/* Órgano dental */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <label htmlFor="organos_dentales">
                <small className="text-muted font-weight-bold">Opcional </small>
                Órganos dentales involucrados:
              </label>
              <br />
              <select
                className="input selectpicker"
                id="organos_dentales"
                name="organos_dentales"
                multiple
                data-live-search="true"
                onChange={(e) => onChangeDiente()}
              >
                {dientes &&
                  dientes.map((diente) => (
                    <option key={diente.id_diente} value={diente.id_diente}>
                      {diente.numero && `${diente.numero} - `}
                      {`${diente.nombre}`}
                    </option>
                  ))}
              </select>
            </div>
            {/* Costo total del trabajo */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="costo_total" className="formulario__label">
                  Costo total del trabajo:
                </label>
                <input
                  type="tel"
                  placeholder="$"
                  className="input"
                  onKeyPress={validateNoNumbers}
                  id="costo_total"
                  name="costo_total"
                  required
                />
              </div>
            </div>

            {/* Número de orden */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="numero_orden" className="formulario__label">
                  Número de orden:
                </label>
                <input
                  type="text"
                  placeholder="Ingrese el número de orden"
                  className="input"
                  id="numero_orden"
                  name="numero_orden"
                  required
                />
              </div>
            </div>

            {/* Colorimetría */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="colorimetria" className="formulario__label">
                  Colorimetría:
                </label>
                <input
                  type="text"
                  placeholder="Ingrese la colorimetría"
                  className="input"
                  id="colorimetria"
                  name="colorimetria"
                  required
                />
              </div>
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            {/* Cantidad a cuenta  */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label
                  htmlFor="cantidad_a_cuenta"
                  className="formulario__label"
                >
                  Cantidad a cuenta:
                  {/* <br />
                  <small className="text-muted font-weight-bold">
                    Opcional
                  </small> */}
                </label>
                <input
                  type="tel"
                  placeholder="$"
                  className="input"
                  onKeyPress={validateNoNumbers}
                  id="cantidad_a_cuenta"
                  name="cantidad_a_cuenta"
                  required
                />
              </div>
              <div className="formulario__grupo-input">
                <label
                  htmlFor="cantidad_a_cuenta"
                  className="formulario__label"
                >
                  Método de pago:
                  {/* <br />
                  <small className="text-muted font-weight-bold">
                    Opcional
                  </small> */}
                </label>
                <select
                  className="input selectpicker"
                  id="id_metodo_pago"
                  name="id_metodo_pago"
                  required
                >
                  <option value="">Elige un método de pago</option>
                  {metodosPago &&
                    metodosPago.map((metodo_pago) => (
                      <option
                        key={metodo_pago.id_metodo_pago}
                        value={metodo_pago.id_metodo_pago}
                      >
                        {metodo_pago.nombre}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <div className="mr-4">
                  <label className="m-0">Se pagará el total del trabajo:</label>
                  <br />
                  <small className="text-muted">
                    Sí al enviar este trabajo se paga en su totalidad, marca
                    esta opción.
                  </small>
                </div>
                <ToggleButton id="es_orden_final" name="es_orden_final" />
              </div>
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            {/* Días para entrega */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="fecha_entrega" className="formulario__label">
                  Fecha de entrega:
                  <br />
                  <small className="text-muted font-weight-bold">
                    Cuándo te lo entregará el laboratorio
                  </small>
                </label>
                <input
                  onKeyPress={(event) => event.preventDefault()}
                  id="fecha_entrega"
                  name="fecha_entrega"
                  className="input"
                  placeholder="#"
                  type="date"
                  required
                />
              </div>
            </div>
            {/* Fecha de registro */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="fecha_registro" className="formulario__label">
                  Fecha de recolección:
                  <br />
                  <small className="text-muted font-weight-bold">
                    Cuándo el laboratorio se llevó el trabajo
                  </small>
                </label>
                <input
                  id="fecha_registro"
                  name="fecha_registro"
                  className="input"
                  type="date"
                  required
                />
              </div>
            </div>
            {/* Descripción */}
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="formulario__grupo-input">
                <label htmlFor="descripcion" className="formulario__label">
                  Descripción:
                </label>
                <textarea
                  id="descripcion"
                  name="descripcion"
                  className="input"
                  rows="3"
                  required
                />
              </div>
            </div>
          </div>

          <hr className="my-4" />

          <div className="d-flex justify-content-end">
            <button className="btn btn-azul noMargin">
              <Icon icon="fa-solid fa-plus mr-2" />
              <Icon icon="fa-solid fa-teeth mr-2" />
              Agregar trabajo de laboratorio
            </button>
          </div>
        </form>
      )}
    </section>
  );
}
