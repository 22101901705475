import React from 'react'
// import getUnidades from '../services/unidades/getUnidades';
import { ConnectionError, handleCallApiError } from '../validators/errors'
import getCitasByEmpleado from '../services/empleado/getCitasByEmpleado'
import getUnidadesConCitas from '../services/empleado/getUnidadesConCitas'

export default function useCitas() {
    var curr = new Date()
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)))
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7))
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    const [loading, setLoading] = React.useState(true)
    const [citas, setCitas] = React.useState([])
    const [trabajadorCitas, setTrabajadorCitas] = React.useState({})

    const [unidadesCitas, setUnidadesCitas] = React.useState([])

    const getCitasByEmpleadoService = data => {
        setLoading(true)
        data.fecha_inicio = fechaInicio
        data.fecha_final = fechaFinal

        getCitasByEmpleado({ values: data })
            .then(res => {
                let _res = JSON.parse(res)
                console.log(_res)
                if (_res.response === 'success') {
                    setCitas(_res.data)
                    setLoading(false)
                    return
                }
                setLoading(false)
                setCitas([])
            })
            .catch(e => {
                setLoading(false)
                // Intentar conectar de nuevo en unos segundos
                if (e instanceof ConnectionError) {
                    handleCallApiError('ConnectionError')
                }
            })
    }

    const getUnidadesConCitasService = data => {
        setLoading(true)
        data.fecha_inicio = fechaInicio
        data.fecha_final = fechaFinal

        getUnidadesConCitas({ values: data })
            .then(res => {
                let _res = JSON.parse(res)
                console.log(_res)
                if (_res.response === 'success') {
                    setUnidadesCitas(_res.data)
                    setLoading(false)
                    return
                }
                setLoading(false)
                setUnidadesCitas([])
            })
            .catch(e => {
                setLoading(false)
                // Intentar conectar de nuevo en unos segundos
                if (e instanceof ConnectionError) {
                    handleCallApiError('ConnectionError')
                }
            })
    }

    return {
        citas,
        loading,
        getCitasByEmpleadoService,
        setFechaInicio,
        setFechaFinal,
        fechaInicio,
        fechaFinal,
        getUnidadesConCitasService,
        unidadesCitas,
    }
}
