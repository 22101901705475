import React from 'react';
import Swal from 'sweetalert2'
import $ from 'jquery'
import deleteCita from '../../services/cita/deleteCita';

export default function TableElementCitaExpediente({ element, permisosUsuario, reload, setIdIngresoReembolso, setElementToEdit }) {

    let { id_cita, folio, descripcion, nombre_empleado, apellido_paterno_empleado, apellido_materno_empleado, nombre_unidad, nombre_proceso, fecha_alta, fecha_visita } = element;

    const onClickEstatus = () => {
        let msg = 'Quieres eliminar el registro de la cita?';
        let txt = 'Eliminar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_cita', id_cita)
                let objectValues = Object.fromEntries(values);
                deleteCita({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        Swal.fire({
                            title:'Eliminado correctamente',
                            timer:1200,
                            showConfirmButton:false,
                            icon:'success'
                        })
                        reload()
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_cita}>
            <th className='table-max-30'>
                <div className="table__name">
                    {
                        (permisosUsuario.ingresos.eliminar === '1' || permisosUsuario.ingresos.editar === '1') &&
                            <div className="dropdown">
                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                        (permisosUsuario.ingresos.eliminar === '1') &&
                                            <a href className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus()}>
                                                Eliminar
                                            </a>
                                    }
                                </div>
                            </div>
                    }

                    <div className="table__nameDescription">
                        <p>{nombre_empleado}</p>
                        <small className='text-muted'>{nombre_unidad}</small>
                    </div>
                </div>
            </th>

            <td>
                <p>{fecha_visita}</p>
                <small className='text-muted'>
                    Se trabajó en: {nombre_proceso}
                </small>
            </td>
            
            <td>
                <p>{descripcion ? descripcion : '-'}</p>
            </td>
        </tr>
    )
    
}