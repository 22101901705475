import React from 'react';
import './Trabajadores.css';
import { useHistory } from "react-router-dom";
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import AddButton from "./../../components/AddButton";
import LoadingIndicator from "../../components/LoadingIndicator";
import getEmpleados from '../../services/empleado/getEmpleados';
import { setFormatNumberToMoney } from '../../hooks/setFormatNumberToMoney';

export default function Trabajadores({modulosPermisos}){

    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    // History de la navegación
    let history = useHistory();
    
    var curr = new Date;
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Unidad asignada" },
        { id: 3, name: "Nómina semanal" },
        { id: 4, name: "Estatus" }
    ];
    
    function getEmpleadosService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal
        }

        getEmpleados({values:data}).then(res => {
            // console.log(res)
            let _res = JSON.parse(res);
            // console.log(_res)
            if(_res.response === "success"){
                setElements(_res.data);
            }
            setLoading(false)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }

    const calculateTotalSueldos = (empleados) => {
        if(empleados.length === 0)
            return 0;

        let total = 0;
        empleados.forEach(element => {
            total = total + parseFloat(element.nomina_semanal)
        });

        return total;
    }

    React.useEffect(getEmpleadosService,[]);

    return(
        <section>

            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className='d-flex align-items-center'>
                        <h1 className="">Trabajadores</h1>

                        {
                            modulosPermisos.trabajadores.escribir === '1' &&
                                <AddButton text="Agregar trabajador" onClick={ async () => {
                                    history.push("/trabajadores/agregar")
                                } } />
                        }
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-md-0 mt-sm-3 mt-3 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end align-items-center">
                    <h4>
                        Nómina semanal total: {setFormatNumberToMoney(calculateTotalSueldos(elements))}
                    </h4>
                </div>
            </div>

            <hr className="my-3" />

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody elements={elements} typeElement="trabajadores" permisosUsuario={modulosPermisos} />
                    </Table>
            }

        </section>
    );
}