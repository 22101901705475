import React from 'react';
import Swal from 'sweetalert2'
import $ from 'jquery';
import deleteMaterial from '../../services/materiales/deleteMaterial';
import { Link } from 'react-router-dom';
import updateEstatusUser from '../../services/user/updateEstatusUser';

export default function TableElementUsuario({ element, permisosUsuario, reload, setElementToEdit }) {

    let { id_usuario, nombre_usuario, celular, fecha_alta, nombre, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres activar el usuario?' : 'Quieres desactivar el usuario?';
        let txt = (activo) ? 'Activar' : 'Desactivar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_usuario', id_usuario)
                values.append('activo', activo ? '1' : '0')
                let objectValues = Object.fromEntries(values);
                // console.log({objectValues})
                updateEstatusUser({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo ? '1' : '0')
                        Swal.fire({
                            timer: 1200,
                            title: 'Estatus actualizado',
                            showConfirmButton: false,
                            icon: 'success'
                        })
                    }
                    
                })
            }
        })
    }

    const onUpdateElement = ()=>{
        // setElementToEdit(null)
        setElementToEdit(element)
        $("#modalAddUsuario").modal('show')
    }

    // const onClickDelete = () => {
    //     let msg = 'Seguro que quieres eliminarlo?';
    //     let txt = 'Si';
    //     Swal.fire({
    //         title: msg,
    //         showCancelButton: true,
    //         confirmButtonText: txt,
    //         showLoaderOnConfirm: true,
    //         allowOutsideClick: () => !Swal.isLoading()
    //     }).then((result) => {
    //         if(result.isConfirmed){
    //             let values = new FormData()
    //             values.append('id_usuario', id_usuario)
    //             let objectValues = Object.fromEntries(values);
    //             // console.log({objectValues})
    //             delete({values:objectValues}).then(response => {
    //                 console.log(response)
    //                 let _respuesta = JSON.parse(response);
    //                 if (_respuesta.response === "success") {
    //                     reload()
    //                     Swal.fire({
    //                         timer: 1200,
    //                         title: 'Eliminado',
    //                         showConfirmButton: false,
    //                         icon: 'success'
    //                     })
    //                 } else {
    //                     Swal.fire({
    //                         title: 'No se puede eliminar',
    //                         text: 'Ya existen registros que están vinculados a este material, para eliminarlo, primero elimina todos los registros al que está vinculado.',
    //                         icon: 'info'
    //                     })
    //                 }
                    
    //             })
    //         }
    //     })
    // }

    return (
        <tr key={id_usuario}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* <Link to={`/usuarios/${id_usuario}`} className="dropdown-item" >Ver</Link> */}
                            {
                                (permisosUsuario.tipoEgresos.editar === '1') &&
                                    (estadoEstatus === '1' ?
                                    <a className="dropdown-item text-black" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                    :
                                    <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            }

                            {
                                (permisosUsuario.tipoEgresos.editar === '1') &&
                                    <a className="dropdown-item text-black" onClick={() => onUpdateElement()}>Editar</a>
                            }

                            {/* {
                                (permisosUsuario.tipoEgresos.eliminar === '1') &&
                                    <a className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>Eliminar</a>
                            } */}
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{nombre_usuario}</p>
                        <small>Nombre: {nombre}</small>
                    </div>
                </div>
            </th>

            <td>
                <p>{fecha_alta}</p>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus === '1' ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}