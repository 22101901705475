import React from 'react'
import getEgresosUnidad from '../../services/unidades/getEgresosUnidad'
import AddButton from '../AddButton'
import AlertWarningSearch from '../AlertWarningSearch'
import LoadingIndicator from '../LoadingIndicator'
import Table from '../Table'
import TableBody from '../Table/TableBody'
import TableHead from '../Table/TableHead'
import $ from 'jquery'
import getEgresosTotalesUnidad from '../../services/unidades/getEgresosTotalesUnidad'
import addEgreso from '../../services/egreso/addEgreso'
import CardEgresoUnidad from '../CardEgresoUnidad'
import ModalAddEgresoUnidad from '../ModalAddEgresoUnidad'
import Swal from 'sweetalert2'
import updateEgreso from '../../services/egreso/updateEgreso'

export default function UnidadEgresos(props) {
    let { modulosPermisos } = props

    var curr = new Date()
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)))
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7))
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    // VARIABLES PARA LOS INGRESOS
    const [loading, setLoading] = React.useState(true)
    const [egresos, setEgresos] = React.useState([])
    const [egresosTotales, setEgresosTotales] = React.useState({})

    const [elementToEdit, setElementToEdit] = React.useState(null)

    const rowsEgresos = [
        { id: 1, name: 'No. de egreso' },
        { id: 2, name: 'Tipo de gasto' },
        { id: 3, name: 'Cantidad' },
        { id: 4, name: 'Descripción' },
    ]

    function onSubmitFechas(e) {
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    function getEgresosUnidadService() {
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_unidad: props.id_unidad,
        }
        getEgresosUnidad({ values: data })
            .then(res => {
                let _res = JSON.parse(res)
                console.log(_res)
                if (_res.response === 'success') {
                    setEgresos(_res.data)
                } else {
                    setEgresos([])
                }
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            })
            .catch(error => {
                // console.log(error)
                // console.log("ha petado")
            })
    }

    function getEgresosTotalesUnidadService() {
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_unidad: props.id_unidad,
        }
        getEgresosTotalesUnidad({ values: data })
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                if (_res.response === 'success') {
                    setEgresosTotales(_res.data)
                } else {
                    setEgresosTotales({})
                }
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            })
            .catch(error => {
                // console.log(error)
                // console.log("ha petado")
            })
    }

    const onSubmitModal = e => {
        // Desactivamos el botón submit del formulario
        $('#btnAddEgresoUnidad').prop('disabled', true)

        e.preventDefault()
        let values = new FormData(e.target)
        values.append('id_unidad', props.id_unidad)
        let objectValues = Object.fromEntries(values)
        addEgreso({ values: objectValues }).then(res => {
            // console.log({res})
            let _res = JSON.parse(res)
            if (_res.response === 'success') {
                Swal.fire({
                    title: 'Agregado correctamente',
                    timer: 1200,
                    showConfirmButton: false,
                    icon: 'success',
                })
                $('#modalAddEgresoUnidad').modal('hide')
                $('#formAddEgresoUnidad')[0].reset()
                $('.selectpicker').selectpicker('refresh')
                getEgresosUnidadService()
                getEgresosTotalesUnidadService()
            }
            $('#btnAddEgresoUnidad').prop('disabled', false)
        })
    }

    const onUpdateModal = e => {
        $('#btnAddEgresoUnidad').prop('disabled', true)
        e.preventDefault()
        let values = new FormData(e.target)
        values.append('id_unidad', props.id_unidad)
        values.append('id_egreso', elementToEdit.id_egreso)
        let objectValues = Object.fromEntries(values)
        console.log(objectValues)
        updateEgreso({ values: objectValues }).then(res => {
            console.log({ res })
            let _res = JSON.parse(res)
            if (_res.response === 'success') {
                Swal.fire({
                    title: 'Actualizado correctamente',
                    timer: 1200,
                    showConfirmButton: false,
                    icon: 'success',
                })
                $('#modalAddEgresoUnidad').modal('hide')
                $('#formAddEgresoUnidad')[0].reset()
                $('.selectpicker').selectpicker('refresh')
                getEgresosUnidadService()
                getEgresosTotalesUnidadService()
            } else {
                Swal.fire({
                    title: 'No se actualizó nada',
                    timer: 1200,
                    showConfirmButton: false,
                    icon: 'info',
                })
            }
            $('#btnAddEgresoUnidad').prop('disabled', false)
        })
    }

    const reloadEgresos = () => {
        getEgresosUnidadService()
        getEgresosTotalesUnidadService()
    }

    React.useEffect(getEgresosUnidadService, [])
    React.useEffect(getEgresosTotalesUnidadService, [])

    React.useEffect(() => {
        reloadEgresos()
        $('#fecha_inicial').val(fechaInicio)
    }, [fechaInicio, fechaFinal])

    return (
        <>
            <CardEgresoUnidad
                total_egresos_efectivo={egresosTotales.total_egresos_efectivo}
                total_egresos_banco={egresosTotales.total_egresos_banco}
                total_egresos={egresosTotales.total_egresos}
            />

            <ModalAddEgresoUnidad
                id_unidad={props.id_unidad}
                id_empleado_responsable_unidad={props.id_empleado_responsable_unidad}
                onSubmitModal={elementToEdit === null ? onSubmitModal : onUpdateModal}
                elementToEdit={elementToEdit}
            />

            <div className="mt-5 mb-4">
                <div className="d-flex align-items-center">
                    <h4 className="">Egresos de la unidad</h4>

                    {modulosPermisos.unidadesEgresos.escribir === '1' && (
                        <AddButton
                            text="Agregar egreso"
                            onClick={async () => {
                                setElementToEdit(null)
                                $('#modalAddEgresoUnidad').modal('show')
                            }}
                        />
                    )}
                </div>

                <form onSubmit={onSubmitFechas} className="mt-3 d-flex justify-content-between align-items-center">
                    <input
                        id="fecha_inicial"
                        name="fecha_inicial"
                        className="input noMargin"
                        type="date"
                        defaultValue={fechaInicio}
                        required
                    />
                    <p className="mx-2">y</p>
                    <input
                        id="fecha_final"
                        name="fecha_final"
                        className="input noMargin"
                        type="date"
                        defaultValue={fechaFinal}
                        required
                    />
                    <button className="btn btn btn-gris ml-2">
                        <i className="fa-solid fa-search"></i>
                    </button>
                </form>
            </div>

            <LoadingIndicator show={loading} />

            {egresos.length !== 0 && !loading && (
                <Table>
                    <TableHead rows={rowsEgresos} />

                    <TableBody
                        reload={reloadEgresos}
                        elements={egresos}
                        typeElement="egresos_unidad"
                        permisosUsuario={modulosPermisos}
                        setElementToEdit={setElementToEdit}
                    />
                </Table>
            )}

            {egresos.length === 0 && !loading && <AlertWarningSearch />}
        </>
    )
}
