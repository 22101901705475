import React from "react";
import './CardIngresosUnidad.css'

export default function CardIngresosUnidad(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');

    return(
        <div className='d-flex flex-wrap justify-content-evenly globalCardsIngresosUnidad'>
            <div className='cardIngresosUnidad cardIngresosUnidad--no-border-left'>
                <small className='font-weight-bold text-left'>Ingreso en efectivo:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_ingresos_efectivo !== undefined ? dollarUSLocale.format(props.total_ingresos_efectivo) : 0}
                </h2>
            </div>
            <div className='cardIngresosUnidad'>
                <small className='color-gris font-weight-bold text-left'>Ingreso en banco:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_ingresos_banco !== undefined ? dollarUSLocale.format(props.total_ingresos_banco) : 0}
                </h2>
            </div>
            <div className='cardIngresosUnidad'>
                <small className='color-verde font-weight-bold text-left'>Ingresos totales:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_ingresos !== undefined ? dollarUSLocale.format(props.total_ingresos) : 0}
                </h2>
            </div>
        </div>
    )
}