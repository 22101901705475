import React from "react";
import './FormularioIngreso.css'
import getEmpleados from "../../services/empleado/getEmpleados";
import $ from 'jquery'
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import Icon from "../Icon";
import LoadingIndicator from "../LoadingIndicator";
import getUnidades from "../../services/unidades/getUnidades";
import searchProcesos from "../../services/proceso/searchProcesos";
import getAllExpedientes from "../../services/expediente/getAllExpedientes";
import getMetodosPago from "../../services/ingreso/getMetodosPago";

export default function FormularioIngreso({ingreso, onSubmit}){

    const [empleados, setEmpleados] = React.useState([]);
    const [unidades, setUnidades] = React.useState([]);
    const [procesos, setProcesos] = React.useState([]);
    const [expedientes, setExpedientes] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    
    // Si existe un ingreso por parametro, asignarle los valores al formulario
    // Solo una vez se haya cargado todo
    if(ingreso !== undefined && !loading)
        setDataFormulario()
    
    function getExpedientesService(){
        getAllExpedientes()
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setExpedientes(_res.data)
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }
    
    function getEmpleadosService(){
        getEmpleados({values:{}})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setEmpleados(_res.data)
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const getUnidadesService = ()=>{
        setLoading(true)
        getUnidades({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setUnidades(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    const getMetodosPagoService = ()=>{
        setLoading(true)
        getMetodosPago({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMetodosPago(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    function getProcesosByUnidadService(id_unidad){
        let filtros = []
        filtros.push({ campo: 'id_unidad', data: {id_unidad: id_unidad} })
        let data = {
            filtros: filtros
        }
        searchProcesos({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setProcesos(_res.data)
                    $("#id_proceso").attr("disabled",false)
                } else {
                    setProcesos([])
                    $("#id_proceso").attr("disabled",true)
                }
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function onChangeUnidad(value){
        getProcesosByUnidadService(value)
    }

    const setDataFormulario = ()=>{
        document.getElementById("nombre").value = ingreso.nombre;
        document.getElementById("descripcion").value = ingreso.descripcion;
        document.getElementById("id_trabajador").value = ingreso.id_empleado;
    }

    React.useEffect(getEmpleadosService, []);
    React.useEffect(getUnidadesService, []);
    React.useEffect(getExpedientesService, []);
    React.useEffect(getMetodosPagoService, []);

    return(
        <>
            <LoadingIndicator show={loading} />

        {
            !loading &&

            <form className="formulario" onSubmit={onSubmit}>
            {/* ******************************* */}
            {/* BLOQUE DE LOS DATOS PRINCIPALES */}
            {/* ******************************* */}

                <div className='mt-4'>
                    <h3 className='my-4'>
                    Datos de pago
                    </h3>

                    <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="numero_recibo">
                        No. de recibo:
                        </label>
                        <input
                        type="text"
                        className="input"
                        id="numero_recibo"
                        name="numero_recibo"
                        placeholder="######"
                        required
                        />
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="fecha_del_pago">
                        Fecha del pago:
                        </label>
                        <input
                        type="date"
                        className="input"
                        id="fecha_del_pago"
                        name="fecha_del_pago"
                        required
                        />
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="cantidad">
                        Cantidad del pago:
                        </label>
                        <input
                        type="tel"
                        className="input"
                        onKeyPress={validateNoNumbers}
                        id="cantidad"
                        name="cantidad"
                        placeholder="$"
                        required
                        />
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="id_metodo_pago">
                        Método de pago:
                        </label>
                        <br />
                        <select
                        className="input selectpicker"
                        id="id_metodo_pago"
                        name="id_metodo_pago"
                        required
                        >
                        <option value="">Elige un método de pago</option>
                        {metodosPago &&
                            metodosPago.map((metodo_pago) => (
                            <option
                                key={metodo_pago.id_metodo_pago}
                                value={metodo_pago.id_metodo_pago}
                            >
                                {metodo_pago.nombre}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12">
                        <label htmlFor="descripcion">
                        Descripción detallada del pago:
                        </label>
                        <textarea
                        rows={5}
                        cols={5}
                        type="text"
                        placeholder="Sé lo más detallado posible (Opcional)"
                        className="input textarea"
                        id="descripcion"
                        name="descripcion"
                        />
                    </div>
                    </div>
                </div>

                <div className='mt-4'>
                    <h3 className='my-4'>
                    Datos generales
                    </h3>

                    <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <label htmlFor="folio">
                        Paciente:
                        </label>
                        <br />
                        <select
                        className="input selectpicker"
                        id="folio"
                        name="folio"
                        data-live-search="true"
                        required
                        >
                        <option value="">Elige un folio</option>
                        {expedientes &&
                            expedientes.map((expediente) => (
                            <option
                                key={expediente.folio}
                                value={expediente.folio}
                            >
                                {expediente.folio} : {expediente.nombrePaciente.trim()}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <label htmlFor="id_unidad">
                        Unidad:
                        </label>
                        <br />
                        <select
                        className="input selectpicker"
                        id="id_unidad"
                        name="id_unidad"
                        data-live-search="true"
                        onChange={(e) => onChangeUnidad(e.target.value)}
                        required
                        >
                        <option value="">Elige una unidad</option>
                        {unidades &&
                            unidades.map((unidad) => (
                            <option
                                key={unidad.id_unidad}
                                value={unidad.id_unidad}
                            >
                                {unidad.nombre}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <label htmlFor="id_empleado">
                        Trabajador a quién pertenece el pago:
                        </label>
                        <br />
                        <select
                        className="input selectpicker"
                        id="id_empleado"
                        name="id_empleado"
                        >
                        <option value="">Elige un trabajador</option>
                        {empleados &&
                            empleados.map((empleado) => (
                            <option
                                key={empleado.id_empleado}
                                value={empleado.id_empleado}
                            >
                                {empleado.nombre}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <label htmlFor="id_proceso">
                        Proceso:
                        </label>
                        <br />
                        <select
                        className="input selectpicker"
                        id="id_proceso"
                        name="id_proceso"
                        data-live-search="true"
                        disabled
                        >
                        <option value="">Elige un proceso</option>
                        {procesos &&
                            procesos.map((proceso) => (
                            <option
                                key={proceso.id_proceso}
                                value={proceso.id_proceso}
                            >
                                {proceso.nombre}
                            </option>
                            ))}
                        </select>
                    </div>
                    </div>
                </div>

                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr className='my-4' />

                <div className="d-flex justify-content-end">
                    {(ingreso !== undefined) &&
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-money-bill mr-3" />
                        Actualizar ingreso
                    </button>
                    }

                    {(ingreso === undefined) &&
                    <button className="btn btn-azul noMargin">
                        <Icon icon="fa-solid fa-plus mr-2" />
                        <Icon icon="fa-solid fa-money-bill mr-2" />
                        Agregar ingreso
                    </button>
                    }
                </div>
            </form>


        }

        </>
        
    )
}