import React from "react";
import AddButton from "../AddButton";
import LoadingIndicator from "../LoadingIndicator";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import $ from 'jquery'
import Swal from "sweetalert2";
import getCitas from "../../services/cita/getCitas";
import AlertWarningSearch from "../AlertWarningSearch";
import ModalAddCitaCompletadaExpediente from "../ModalAddCitaCompletadaExpediente";
import addCita from "../../services/cita/addCita";

export default function ExpedienteCitas(props){

    let { modulosPermisos, expediente } = props

    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const rows = [
        { id: 1, name: "Quién lo atendió" },
        { id: 2, name: "Fecha" },
        { id: 3, name: "Descripción" }
    ];

    function getCitasExpedienteService(){
        setLoading(true)
        let data = { folio: props.folio }
        getCitas({values: data}).then(res => {
            console.log(res)
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setElements(_res.data);
            }
            setTimeout(()=>setLoading(false), 200)
        }).catch((error) => {
            console.log(error)
            // console.log("ha petado")
        })
    }

    const onSubmitAddCitaCompletada = (e) => {
        e.preventDefault();
        
        // Desactivamos el botón submit del formulario
        let btnSubmit = document.getElementById('btnModalAddCitaCompletadaExpedienteSubmit');
        btnSubmit.disabled = true;
        btnSubmit.innerHTML = 'Agregando...';

        let form = new FormData(e.target)
        let data = Object.fromEntries(form)
        data.folio = props.folio
        let termino_tratamiento = 0;
        if(data.termino_tratamiento === "on"){
            termino_tratamiento = 1;
        }
        data.termino_tratamiento = termino_tratamiento;
        console.log({data})
        addCita({values:data})
            .then(res => {
                console.log(res)
                let _res = JSON.parse(res);
                if(_res.response === "success"){
                    Swal.fire({
                        title: "Se agregó correctamente",
                        timer: 1200,
                        showConfirmButton: false,
                        icon: "success"
                    })
                    reload()

                    // Cerramos el modal
                    $("#modalAddCitaCompletadaExpediente").modal("hide")

                } else {
                    Swal.fire({
                        title: "No se agregó",
                        timer: 1200,
                        showConfirmButton: false,
                        icon: "info"
                    })
                }

                // Activamos el botón submit del formulario de nuevo
                btnSubmit.disabled = false;
                btnSubmit.innerHTML = 'Agregar';
                
            }).catch((error) => {
                console.log(error)
                // console.log("ha petado")
            })
    }

    function reload(){
        setElements([])
        getCitasExpedienteService()
    }

    React.useEffect(getCitasExpedienteService,[]);

    return(
        <div>

            <div className='d-flex align-items-center mb-4'>
                <h4>
                    Citas realizadas del paciente
                </h4>

                {
                    modulosPermisos.expedientesCitas.escribir === '1' &&
                        <AddButton text="Agregar cita" onClick={ async () => {
                            $('#modalAddCitaCompletadaExpediente').modal('show')
                        } } />
                }
            </div>

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="cita_expediente" 
                            reload={reload} 
                            permisosUsuario={modulosPermisos}
                        />
                    </Table>
            }

            { 
                ( elements.length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

            <ModalAddCitaCompletadaExpediente
                folio={expediente}
                onSubmitModal={onSubmitAddCitaCompletada}
            />

        </div>
    )
}