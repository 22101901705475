import React from 'react';
import Swal from 'sweetalert2'
import deleteProcesoExpediente from '../../services/expediente/deleteProcesoExpediente';
// import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElementProcesoExpedienteAsignado({ element, permisosUsuario, reload }) {

    let { id_proceso, folio, id_proceso_expediente, fecha_alta, nombre, apellido_paterno, apellido_materno, fecha_inicio_proceso, precio_final, activo } = element;

    const onClickEstatus = (activo) => {
        let msg = 'Quieres eliminar el proceso del expediente?';
        let txt = 'Eliminar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_proceso_expediente', id_proceso_expediente)
                let objectValues = Object.fromEntries(values);
                deleteProcesoExpediente({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        Swal.fire(
                            'Eliminado correctamente',
                            '',
                            'success'
                        )
                        reload()
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_proceso}>
            <th>
                <div className="table__name">
                    {
                    activo === '1' && (permisosUsuario.expedientes.eliminar === '1' || permisosUsuario.expedientes.editar === '1') &&
                        <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {
                                    permisosUsuario.expedientes.eliminar === '1' &&
                                        <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Eliminar</a>
                                }
                            </div>
                        </div>
                    }

                    <div className="table__nameDescription">
                        <small>
                            {`Alta en sistema: ${fecha_alta}`}
                        </small>
                        <p>{`${nombre} ${apellido_paterno} ${apellido_materno}`}</p>
                        <small>
                            {`Folio: ${folio}`}
                        </small>
                    </div>
                </div>
            </th>

            <td>
                <p>{fecha_inicio_proceso}</p>
            </td>

            <td>
                {
                    (precio_final > 0) ? `$ ${precio_final}` : '-'
                }
            </td>
        </tr>
    )
    
}