import React from 'react';
import Swal from 'sweetalert2';
import deleteCompraProductoUnidad from '../../services/inventario_productos/deleteCompraProductoUnidad';

export default function TableElementCompraProductoUnidad({ element, permisosUsuario, reload}) {

    let { 
        id_producto_unidad,
        id_material,
        id_unidad,
        nombre,
        cantidad_disponible,
        fecha_compra,
        cantidad_pago,
        nombre_tipo_gasto,
        nombre_metodo_pago,
        fecha_alta
    } = element;

    const onClickDelete = () => {
        let msg = 'Seguro que quieres eliminarlo?';
        let txt = 'Si';
        Swal.fire({
            title: msg,
            text: "También se eliminará el egreso de la unidad",
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_producto_unidad', id_producto_unidad)
                let objectValues = Object.fromEntries(values);
                // console.log({objectValues})
                deleteCompraProductoUnidad({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        reload()
                        Swal.fire({
                            timer: 1200,
                            title: 'Eliminado',
                            showConfirmButton: false,
                            icon: 'success'
                        })
                    } else {
                        Swal.fire({
                            title: 'No se puede eliminar',
                            text: 'Ya existen registros que están vinculados a este tipo de egreso, para eliminarlo, primero elimina todos los registros al que está vinculado.',
                            icon: 'info'
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_producto_unidad}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {
                                (permisosUsuario.inventarioMateriales.eliminar === '1') &&
                                (permisosUsuario.unidadesEgresos.eliminar === '1') &&
                                    <a href 
                                        className="dropdown-item bg-danger text-white" 
                                        onClick={() => onClickDelete()}
                                    >
                                        Eliminar
                                    </a>
                            }
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{nombre}</p>
                        {
                            nombre_tipo_gasto !== null && 
                                <small className='text-muted'>
                                    {`${nombre_tipo_gasto}`}
                                </small>
                        }
                        <br />
                        <small className='text-muted'>
                            Fecha alta: {`${fecha_alta}`}
                        </small>
                    </div>
                </div>
            </th>

            <td>
                <p>
                    {Math.floor(cantidad_disponible)}
                </p>
            </td>

            <td>
                <p className='font-weight-bold'>$ {cantidad_pago}</p>
                <small>
                    Método de pago: {nombre_metodo_pago}
                </small>
                <br />
                <small className='text-muted'>
                    Fecha compra: {`${fecha_compra}`}
                </small>
            </td>

        </tr>
    )
    
}