import React, { useState } from 'react'
import Icon from '../Icon'
import "./FilterButton.css"
import getUnidades from '../../services/unidades/getUnidades'
import $ from 'jquery';
import getUsers from '../../services/user/getUsers';
import getEstadosOrdenTrabajoActivo from '../../services/estado_orden_trabajo/getEstadosOrdenTrabajoActivo';

export default function FilterButton({ text, type, selectedValues, elements, onSubmit, children }){

    const [selected, setSelected] = React.useState(selectedValues)
    const idUsuario = localStorage.getItem('idUsuario')

    const [elementsSelect, setElementsSelect] = React.useState([])
    const getUnidadesService = () => {
        let data = {
            id_usuario: idUsuario
        }
        getUnidades({values:data}).then((respuesta) =>{
            // console.log(respuesta)
            let _respuesta = JSON.parse(respuesta)
            setElementsSelect(_respuesta.data)
        })
    }
    
    const getUsuariosService = () => {
        getUsers({values:{}}).then((respuesta) =>{
            // console.log(respuesta)
            let _respuesta = JSON.parse(respuesta)
            setElementsSelect(_respuesta.data)
        })
    }
    
    const getEstadosOrdenTrabajosActivoService = () => {
        getEstadosOrdenTrabajoActivo({values:{}}).then((respuesta) =>{
            // console.log(respuesta)
            let _respuesta = JSON.parse(respuesta)
            setElementsSelect(_respuesta.data)
        })
    }

    const onChangeOptionsDate = (e) => {
        if(e.target.value === "2"){
            setShowInputsDate(true)
            $("#fecha_a_filtrar").attr("required",false)
            $("#fecha_inicial").attr("required",true)
            $("#fecha_final").attr("required",true)
        } else {
            setShowInputsDate(false)
            $("#fecha_a_filtrar").attr("required",true)
            $("#fecha_inicial").attr("required",false)
            $("#fecha_final").attr("required",false)
        }
    }
    const [showInputsDate, setShowInputsDate] = React.useState(false)
    let optionsDate = [
        { id: "1", name: "Es igual a" },
        { id: "2", name: "Entre" },
        { id: "3", name: "Es posterior a" },
        { id: "4", name: "En esa fecha o después" },
        { id: "5", name: "Es anterior a" },
        { id: "6", name: "Es anterior o igual a" }
    ]

    let optionsAction = [
        { id: "create", name: "Alta (create)" },
        { id: "update", name: "Actualización (update)" },
        { id: "delete", name: "Baja (delete)" }
    ]

    let inputs;

    // SÍ ES DE fecha alta AGREGAMOS SUS INPUT
    if(type === "fecha_alta"){

        inputs = 
        <>
            <select className="input" name="tipoBusqueda" onChange={onChangeOptionsDate}>
                {
                    optionsDate.map((option) => {
                        return <option key={option.id} value={option.id}>{option.name}</option>
                    })
                }
            </select>
            <div className={`${!showInputsDate && 'hidden'} d-flex justify-content-between align-items-center`}>
                <input id="fecha_inicial" name="fecha_inicial" className="input" type="date" onChange={(e) => setSelected(e.target.value)} />
                <p className="mx-2">y</p>
                <input id="fecha_final" name="fecha_final" className="input" type="date" onChange={(e) => setSelected(e.target.value)} />
            </div>
            <div className={`${showInputsDate && 'hidden'} d-flex justify-content-between align-items-center`}>
                <input id="fecha_a_filtrar" name="fecha" className="input" type="date" onChange={(e) => setSelected(e.target.value)} />
            </div>
        </>
    }

    // SÍ ES DE ESTATUS AGREGAMOS SUS INPUT
    if(type === "estatus"){
        inputs = 
        <>
            <select className="input" name="activo" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
            </select>
        </>
    }

    // SÍ ES DE "TERMINADO" AGREGAMOS SUS INPUT
    if(type === "terminado"){
        inputs = 
        <>
            <select className="input" name="terminado" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                <option value="1">Si</option>
                <option value="0">No</option>
            </select>
        </>
    }

    // SÍ ES DE id_unidad AGREGAMOS SUS INPUT
    if(type === "id_unidad"){
        inputs = 
        <>
            <select className="input" name="id_unidad" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    elementsSelect.length > 0 &&
                        elementsSelect.map((unidad) => {
                            return <option value={unidad.id_unidad} key={unidad.id_unidad}>{unidad.nombre}</option>
                        })
                }
            </select>
        </>
    }

    // SÍ ES DE id_estado_orden_trabajo AGREGAMOS SUS INPUT
    if(type === "id_estado_orden_trabajo"){
        inputs = 
        <>
            <select className="input" name="id_estado_orden_trabajo" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    elementsSelect.length > 0 &&
                        elementsSelect.map((estado_orden) => {
                            return <option value={estado_orden.id_estado_orden_trabajo} key={estado_orden.id_estado_orden_trabajo}>{estado_orden.nombre}</option>
                        })
                }
            </select>
        </>
    }

    // Si es que es de 'id_empleado' agregamos los doctores
    if(type === "id_empleado"){
        inputs = 
        <>
            <select className="input" name="id_empleado" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    elements.length > 0 &&
                        elements.map((empleado) => {
                            return <option value={empleado.id_empleado} key={empleado.id_empleado}>{empleado.nombre} {empleado.apellido_paterno}</option>
                        })
                }
            </select>
        </>
    }
    
    // Si es que es de 'id_usuario' agregamos los doctores
    if(type === "id_usuario"){
        inputs = 
        <>
            <select className="input" name="id_usuario" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    elementsSelect.length > 0 &&
                        elementsSelect.map((usuario) => {
                            return <option value={usuario.id_usuario} key={usuario.id_usuario}>ID: {usuario.id_usuario} - {usuario.nombre} {usuario.apellido_paterno}</option>
                        })
                }
            </select>
        </>
    }
    
    // Si es que es de 'accion' agregamos los doctores
    if(type === "accion"){
        inputs = 
        <>
            <select className="input" name="accion" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    optionsAction.map((option) => {
                        return <option key={option.id} value={option.id}>{option.name}</option>
                    })
                }
            </select>
        </>
    }


    React.useEffect(()=>{
        if(type === "id_unidad"){ getUnidadesService() }
        if(type === "id_usuario"){ getUsuariosService() }
        if(type === "id_estado_orden_trabajo"){ getEstadosOrdenTrabajosActivoService() }
    },[])

    return(
        <div className="dropdown mx-2 dropdownFilter d-inline">
            <button className="dropdown-button" id={`${text.replace(/\s/g, '')}-dropdown`} data-toggle="dropdown">
                <Icon icon="fa-solid fa-circle-plus mr-2"/>
                { text } 
                <span>
                    {
                        (selected !== undefined && selected !== null) &&
                        ` | ${selected}`
                    }
                </span>
                {
                    (selected !== undefined && selected !== null) &&
                        <Icon icon="fa-solid fa-chevron-down mx-2"/>
                }
            </button>
            <div className="dropdown-menu">
                <p className="font-weight-bold tituloFilter">
                    Filtrar por { text } 
                </p>
                <form onSubmit={ (e) => onSubmit(type, e) }>
                    { inputs }
                    <button onClick={() => { $(`#${text.replace(/\s/g, '')}-dropdown`).dropdown("toggle"); }} className="btn btn-azul btn-block btn-sm btnAplicarFiltro">
                        Aplicar
                    </button>
                </form>
            </div>
        </div>
    )
}