import React from 'react';
import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2'
import FormularioExpediente from '../../components/FormularioExpediente';
import addExpediente from '../../services/expediente/addExpediente';

export default function AddExpediente(props){

    let { modulosPermisos } = props

    // console.log({modulosPermisos})

    const history = useHistory()

    function onSubmitExpediente(e){
        e.preventDefault();
        const formData = new FormData(e.target);
        let objectValues = Object.fromEntries(formData);
        // console.log(objectValues);
        addExpediente({values:objectValues})
            .then(res => {
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    Swal.fire({
                        title:'Expediente agregado',
                        icon:'success',
                        timer:1500
                    }).then(res=>{
                        history.push('/expedientes')
                    })
                }

                if(_res.response === 'existe_folio'){
                    Swal.fire({
                        title: 'Folio repetido',
                        text: 'Ya existe un folio '+objectValues.folio+', registra otro',
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            })
    }

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btn btn-gris noMargin"
            />

            <h2 className="my-4">Agregar un nuevo expediente</h2>

            <p>
                Para agregar un nuevo expediente, tienes que completar el siguiente formulario:
            </p>

            <hr className='my-3' />

            <FormularioExpediente 
                onSubmit={onSubmitExpediente}
                modulosPermisos={modulosPermisos}
            />


        </section>
    );
}