import React from "react";
import $ from 'jquery';
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import getMetodosPago from "../../services/ingreso/getMetodosPago";
import getTipoEgresosActive from "../../services/tipo_egreso/getTipoEgresosActive";
import getUnidades from "../../services/unidades/getUnidades";

export default function ModalAddEgresoTrabajador({onSubmitModal}){

    const [unidades, setUnidades] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [tipoGasto, setTipoGasto] = React.useState([]);

    const getMetodosPagoService = ()=>{
        getMetodosPago({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMetodosPago(_res.data)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    function getTipoEgresosService(){
        getTipoEgresosActive()
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setTipoGasto(_res.data)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function getUnidadesService(){
        getUnidades({values:{}})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setUnidades(_res.data)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    const setDataFormulario = ()=>{
        document.getElementById('fecha_del_pago_egreso').valueAsDate = new Date();
        $('.selectpicker').selectpicker('refresh');
    }

    React.useEffect(getMetodosPagoService, []);
    React.useEffect(getTipoEgresosService, []);
    React.useEffect(getUnidadesService, []);
    React.useEffect(setDataFormulario, []);

    return(
        <div className="modal" id="modalAddEgresoTrabajador" tabIndex="-1" role="dialog" aria-labelledby="modalAddEgresoTrabajadorLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} id="formAddEgresoTrabajador" className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddEgresoTrabajadorLabel">Agrega un egreso al trabajador</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="fecha_del_pago_egreso">
                                    Fecha del pago:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_del_pago_egreso"
                                    name="fecha_del_pago"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="cantidad">
                                    Cantidad del pago:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="cantidad"
                                    name="cantidad"
                                    placeholder="$"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_metodo_pago">
                                    Método de pago:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_metodo_pago"
                                    name="id_metodo_pago"
                                    required
                                >  
                                    <option value="">Elige un método de pago</option>
                                    {
                                        metodosPago && 
                                        metodosPago.map( metodo_pago => (
                                            <option key={ metodo_pago.id_metodo_pago } value={ metodo_pago.id_metodo_pago }>
                                                { metodo_pago.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_tipo_gasto">
                                    Tipo de gasto:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_tipo_gasto"
                                    name="id_tipo_gasto"
                                    data-live-search="true"
                                    required
                                >  
                                    <option value="">Elige un concepto</option>
                                    {
                                        tipoGasto && 
                                        tipoGasto.map( tipo_gasto => (
                                            <option key={ tipo_gasto.id_tipo_gasto } value={ tipo_gasto.id_tipo_gasto }>
                                                { tipo_gasto.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="id_unidad">
                                    A qué unidad pertenece el gasto?
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_unidad"
                                    name="id_unidad"
                                    required
                                >
                                    <option value="">Elige una unidad</option>
                                    {
                                        unidades &&
                                        unidades.map( (unidad) => (
                                            <option key={ unidad.id_unidad } value={ unidad.id_unidad }>{ unidad.nombre }</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción detallada del pago:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="Sé lo más detallado posible (Opcional)"
                                    className="input textarea"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary" id="btnModalAddEgresoTrabajadorSubmit">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}