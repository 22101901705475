import React from "react";
import $ from 'jquery'
import Icon from "../Icon";
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import getUnidades from "../../services/unidades/getUnidades";

export default function FormularioProceso({proceso, onSubmit, modulosPermisos}){

    // const [empleados, setEmpleados] = React.useState([]);
    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const fechaInicio = React.useState(firstday.toISOString().split('T')[0])
    const fechaFinal = React.useState(lastday.toISOString().split('T')[0])
    const idUsuario = localStorage.getItem('idUsuario')

    const [unidad, setUnidades] = React.useState([]);

    function getUnidadesService(){
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_usuario: idUsuario,
        }
        getUnidades({values:data})
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                setUnidades(_res.data)
                $('.selectpicker').selectpicker();
                if(proceso !== undefined)
                    setDataFormulario()
            })
    }

    const setDataFormulario = ()=>{
        document.getElementById("nombre").value = proceso.nombre;
        document.getElementById("numero_citas").value = proceso.numero_citas;
        document.getElementById("costo").value = proceso.costo;
        document.getElementById("id_unidad").value = proceso.id_unidad;
        $('.selectpicker').selectpicker('refresh');
        if(modulosPermisos.procesos.editar === "0"){
            $("#formularioProceso :input, :input[form]").prop("readonly", true);
        }
    }

    React.useEffect(getUnidadesService, []);

    return(
        <form className="formulario" id="formularioProceso" onSubmit={ onSubmit }>

            {/* ******************************* */}
            {/* BLOQUE DE LOS DATOS PRINCIPALES */}
            {/* ******************************* */}

            <div className='mt-4'>
                <h3 className='my-4'>
                    Datos de la unidad
                </h3>

                <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                        <label htmlFor="nombre">
                            Nombre:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="nombre"
                            name="nombre"
                            placeholder="Dato obligatorio"
                            required
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <label htmlFor="numero_citas">
                            Número de citas:
                        </label>
                        <input 
                            type="num"
                            onKeyPress={ validateNoNumbers }
                            className="input" 
                            id="numero_citas"
                            name="numero_citas"
                            placeholder='Dato obligatorio'
                            required
                        />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <label htmlFor="costo">
                            Costo:
                        </label>
                        <input 
                            type="num"
                            onKeyPress={ validateNoNumbers }
                            className="input" 
                            id="costo"
                            name="costo"
                            placeholder='Dato obligatorio'
                            required
                        />
                    </div>
                </div>
            </div>
            
            <div className='mt-4'>
                <h3 className='my-4'>
                    ¿Qué unidad realiza el proceso?
                </h3>

                <div className="row">
                    <div className="col-5">
                        <label htmlFor="id_unidad">
                            Unidad:
                        </label>
                        <br></br>
                        <select 
                            className="input selectpicker"
                            id="id_unidad"
                            name="id_unidad"
                            required
                        >
                            {
                                unidad &&
                                unidad.map( (unidad) => (
                                    <option key={ unidad.id_unidad } value={ unidad.id_unidad }>{ unidad.nombre }</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            
            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            <hr className='my-4' /> 

            <div className="d-flex justify-content-end">
                
                {
                    (proceso !== undefined && modulosPermisos.procesos.editar === '1') &&
                        <button className="btn btn-azul noMargin">
                            <Icon icon="fa-solid fa-book-medical mr-3" />
                            Actualizar proceso
                        </button>
                }

                { 
                (proceso === undefined && modulosPermisos.procesos.escribir === '1') &&
                    <button className="btn btn-azul noMargin">
                        <Icon icon="fa-solid fa-plus mr-2" />
                        <Icon icon="fa-solid fa-book-medical mr-2" />
                        Agregar proceso
                    </button>
                }
            </div>


        </form>
    )
}