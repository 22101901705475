import React from 'react';
import getAllExpedientes from '../services/expediente/getAllExpedientes';
import $ from 'jquery';

export default function useExpedientesPacientes(){
    const [expedientes, setExpedientes] = React.useState([]);
    const [loadingExpedientes, setLoadingExpedientes] = React.useState(true);

    const getExpedientesService = () => {
        setLoadingExpedientes(true);

        getAllExpedientes()
            .then(res => {
                let _res = JSON.parse(res)
                console.log(_res)
                if(_res.response === 'success'){
                    setExpedientes(_res.data);
                }
                setLoadingExpedientes(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    React.useEffect(() => {
        getExpedientesService();
    }, [])

    return { expedientes, loadingExpedientes, getExpedientesService }

}