import React from "react";
import getComprasRecientesMaterialByUnidad from "../../services/inventario_materiales/getComprasRecientesMaterialByUnidad";
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";

export default function ModalComprasRecientesMaterial({id_unidad, loading, modulosPermisos, getData}){

    const [comprasRecientes, setComprasRecientes] = React.useState([])
    
    const rows = [
        { id: 1, name: "Material" },
        { id: 2, name: "Cantidad" },
        { id: 3, name: "Costo" }
    ];

    const getUltimasSalidasMaterial = ()=>{
        let data = { id_unidad }

        getComprasRecientesMaterialByUnidad({values:data})
            .then(respuesta => {
                console.log(respuesta)
                let _res = JSON.parse(respuesta)
                if(_res.response === 'success'){
                    setComprasRecientes(_res.data)
                } else {
                    setComprasRecientes([])
                }
            })
            .catch(err => console.log(err))
    }

    const reload = ()=>{
        getUltimasSalidasMaterial()
        getData()
    }

    React.useEffect(getUltimasSalidasMaterial, [id_unidad, loading])

    return(
        <div className="modal" id="modalComprasRecientesMaterial" tabIndex="-1" role="dialog" aria-labelledby="modalComprasRecientesMaterialLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content" id="formEmitirReembolso">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalComprasRecientesMaterialLabel">
                            Compras recientes de materiales para la unidad
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            ( comprasRecientes.length === 0 ) &&
                            <p>
                                No hay compras recientes de materiales.
                            </p>
                        }
                        {
                            ( comprasRecientes.length !== 0 ) &&
                            <p>
                                Estas son las compras más recientes de materiales de la unidad:
                            </p>
                        }
                        {
                            ( comprasRecientes.length !== 0 ) &&

                                <Table>
                                    <TableHead rows={rows} />

                                    <TableBody 
                                        elements={comprasRecientes} 
                                        typeElement="compra_material_unidad" 
                                        permisosUsuario={modulosPermisos} 
                                        reload={reload}
                                        // setElementToEdit={setMaterialSelected}
                                    />
                                </Table>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Salir</button>
                    </div>
                </div>
            </div>
        </div>
    )
}