import React from 'react'
import './CardUnidad.css'
import { useHistory } from 'react-router';

export default function CardUnidad(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');
    let history = useHistory()

    const onClick = ()=>{
        history.push(`/unidades/${props.id_unidad}`)
    }

    return (
        <div className='cardUnidad shadow' onClick={onClick}>
            {
                props.foto_perfil_responsable !== null && props.foto_perfil_responsable !== undefined &&
                    <img src={props.foto_perfil_responsable} className='img-fluid' />
            }
            {
                (props.foto_perfil_responsable === null || props.foto_perfil_responsable === undefined) &&
                <div className='no_foto_perfil'>
                    ?
                </div>
            }
            <p className='cardUnidad__nombre'>{props.nombre}</p>

            <hr className='my-3' />

            <small className='cardUnidad__cantidad'>Ingresos totales:</small>
            <h2 className='mb-3'>$&nbsp;
                {
                    props.cantidad_ingresos !== undefined ?
                        dollarUSLocale.format(props.cantidad_ingresos) : 0
                }
                
            </h2>

            <small>Ingresos en efectivo:</small>
            <h2 className='mb-3'>$&nbsp;
                {
                    props.cantidad_ingresos_efectivo !== undefined ?
                        dollarUSLocale.format(props.cantidad_ingresos_efectivo) : 0
                }
            </h2>

            <small>Ingresos por banco:</small>
            <h2 className='mb-3'>$&nbsp;
                {
                    props.cantidad_ingresos_banco !== undefined ?
                        dollarUSLocale.format(props.cantidad_ingresos_banco) : 0
                }
            </h2>

            <hr className='my-2' />

            <small>Egresos totales:</small>
            <h2 className='mb-3'>$&nbsp;
                {
                    props.cantidad_egresos !== undefined ?
                        dollarUSLocale.format(props.cantidad_egresos) : 0
                }
            </h2>

            <small>Px atendidos:</small>
            <h2>
                {
                    props.total_pacientes_atendidos !== undefined ?
                        props.total_pacientes_atendidos : 0
                }
            </h2>
        </div>
    )
}