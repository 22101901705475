import React from 'react';
import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2'
import FormularioTrabajador from '../../components/FormularioTrabajador';
import addEmpleado from '../../services/empleado/addEmpleado';
import compression from "browser-image-compression";

export default function AddTrabajador(props){

    let { modulosPermisos } = props

    const history = useHistory()

    const onSubmitTrabajador = async (e) => {
        e.preventDefault();
        
        Swal.showLoading();

        try{
            let formData = new FormData(e.target);
            // Validamos que tenga una foto de perfil
            let fotoPerfil = document.getElementById('fotoPerfil').files;

            if(fotoPerfil.length === 0 || fotoPerfil === null){
                Swal.fire({
                    title: 'Sube una foto de perfil, es obligatorio',
                    icon: 'warning',
                    timer: 2000,
                    showConfirmButton: false
                })
                return;
            }

            const compressedImage = await compression(fotoPerfil.item(0), {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            });
            formData.append('foto_perfil', compressedImage)
            // Agregamos el id del usuario que lo está realizando.
            const idUsuario = localStorage.getItem("idUsuario")
            formData.append('id', idUsuario)
            Swal.close();

            let objectValues = Object.fromEntries(formData);
            // console.log(objectValues);

            addEmpleado({values:formData})
                .then(res=>{
                    // console.log(res)
                    let _res = JSON.parse(res);
                    // console.log(_res)
                    if(_res.response === 'success'){
                        Swal.hideLoading();
                        Swal.fire({
                            title:"Trabajador agregado",
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function(){
                            history.push(`/trabajadores`)
                        })
                    } else {
                        Swal.fire(
                            'Ocurrió un error',
                            'Vuelve a intentarlo',
                            'error'
                        )
                    }
                })
        } catch (err){
            Swal.fire({
                icon: 'error',
                title: 'Ocurrió un error, reportar al desarrollador',
                text: err
            })
        }
    }

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btn btn-gris noMargin"
            />

            <h2 className="my-4">Agregar un nuevo trabajador</h2>

            <p>
                Para agregar un nuevo trabajador, tienes que completar el siguiente formulario:
            </p>

            <hr className='my-3' />

            <FormularioTrabajador 
                onSubmit={onSubmitTrabajador}
                modulosPermisos={modulosPermisos}
            />


        </section>
    );
}