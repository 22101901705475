import React from 'react';
import getDientes from '../services/diente/getDientes';

export default function useDientes(){
    const [dientes, setDientes] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const getDientesService = () => {
        setLoading(true);
        getDientes({values:{}})
            .then(res => {
                let _res = JSON.parse(res);
                if(_res.response === 'success'){
                    setDientes(_res.data);
                }
                setLoading(false);
            })
    }

    React.useEffect(() => {
        getDientesService();
    }, [])

    return { dientes, loading, getDientesService }

}