import React from "react";
import LoadingIndicator from "../LoadingIndicator";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import $ from 'jquery'
import getExpedientesByProceso from "../../services/proceso/getExpedientesByProceso";
import AlertWarningSearch from "../AlertWarningSearch";
import getPagosByProceso from "../../services/proceso/getPagosByProceso";

export default function PagosProceso(props){

    let { modulosPermisos, id_proceso } = props

    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const rows = [
        { id: 1, name: "No. recibo" },
        { id: 2, name: "Paciente" },
        { id: 3, name: "Cantidad" },
        { id: 4, name: "Descripción" }
    ];

    function getPagosByProcesoService(){
        setLoading(true)
        setElements([])
        let data = { 
            id_proceso,
            fecha_inicial: fechaInicio,
            fecha_final: fechaFinal,
        }
        getPagosByProceso({values: data}).then(res => {
            console.log(res)
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setElements(_res.data);
            }
            setLoading(false)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    function reloadTratamientos(){
        getPagosByProcesoService()
    }

    React.useEffect(getPagosByProcesoService,[fechaInicio, fechaFinal]);

    React.useEffect(()=>$('#fecha_inicial').val(fechaInicio), [])

    return(
        <div>

            {/* <div className='mb-4 d-flex justify-content-between align-items-center'>
                

                <form onSubmit={onSubmitFechas} className={`d-flex justify-content-between align-items-center mt-3`}>
                    <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                    <p className="mx-2">y</p>
                    <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                    <button className='btn btn-gris ml-2'>Ver</button>
                </form>

            </div> */}

            <div className="row mt-lg-0 mt-4 mb-4">
                <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <h4>
                            Pagos realizados de este proceso
                        </h4>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-start justify-content-sm-start  mt-lg-0 mt-md-4 mt-sm-4 mt-4">
                    <form onSubmit={onSubmitFechas} className="">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                            <p className="mx-2">y</p>
                            <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                            <button className='btn btn btn-gris ml-2'><i className="fa-solid fa-search"></i></button>
                        </div>
                    </form>
                </div>
            </div>

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="ingresos_proceso" 
                            reload={reloadTratamientos} 
                            permisosUsuario={modulosPermisos} />
                    </Table>
            }

            {
                ( elements.length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

        </div>
    )
}