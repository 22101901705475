import React from 'react';
import './Procesos.css';
import { useHistory } from "react-router-dom";
import Icon from "./../../components/Icon";
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import InputSearch from "./../../components/InputSearch";
import AddButton from "./../../components/AddButton";
import LoadingIndicator from "../../components/LoadingIndicator";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import FilterButton from "../../components/FilterButton";
import getProcesos from './../../services/proceso/getProcesos';
import searchProcesos from './../../services/proceso/searchProcesos';

export default function Procesos({modulosPermisos}){

    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filters, setFilters] = React.useState([])
    const [orderBy, setOrderBy] = React.useState({})
    const [terminoBusqueda, setTerminoBusqueda] = React.useState("")
    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [loadingFilters, setLoadingFilters] = React.useState(false)
    const idUsuario = localStorage.getItem('idUsuario')

    // History de la navegación
    let history = useHistory();

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Unidad" },
        { id: 3, name: "Costo" },
        { id: 4, name: "Estatus" }
    ];
    
    function getProcesosService(){
        setLoading(true)
        if(filters.length !== 0 || Object.keys(orderBy).length !== 0 || terminoBusqueda !== ""){
            search()
        } else {
            let data = {
                id_usuario: idUsuario
            }
            getProcesos({values:data}).then(response => {
                console.log(response)
                let _respuesta = JSON.parse(response);
                if(!(_respuesta.response === "error")){
                    setElements(_respuesta.data);
                }
                setLoading(false)
            }).catch((error) => {
                // console.log(error)
                // console.log("ha petado")
            })
        }
    }

    const search = (q) =>{
        setLoading(true)
        let data = {
            id_usuario: idUsuario
        }

        if(terminoBusqueda !== "")
            data.q = terminoBusqueda
        if(filters.length !== 0)
            data.filtros = filters
        if(Object.keys(orderBy).length !== 0)
            data.ordenar = orderBy

        searchProcesos({values:data})
            .then((response) =>{
                console.log(response)
                let _response = JSON.parse(response)
                if(_response.response === "success"){
                    setElements(_response.data)
                } else {
                    setElements([])
                }
                setLoading(false)
            })
            .catch((error)=>{
                // console.log(error)
            })
    }

    const onSubmitSearch = (e) =>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        let terminoBusqueda = objectValues.q
        setTerminoBusqueda(terminoBusqueda)
    }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        let nuevosFiltros = [...filters]

        let newFiltros = nuevosFiltros.filter((value) =>{
            return value.campo !== tipoFiltro
        })

        newFiltros.push({ campo: tipoFiltro, data: object })
        setFilters(newFiltros)
    }

    const onClickDeleteFilters = () => {
        setFilters([])
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(()=>{
            setLoadingFilters(false)
        }, 50)
    }

    const onClickOrderBy = (campo, orden) => {
        let data = {
            campo: campo,
            orden: orden
        }
        setOrderBy(data)
    }

    React.useEffect(() => {
        getProcesosService()
    },[filters, terminoBusqueda, orderBy]);

    return(
        <section>

            <div className="d-flex justify-content-between">

                <div className="d-flex justify-content-between">
                    <h1 className="">Procesos</h1>

                    {
                        modulosPermisos.procesos.escribir === '1' &&
                            <AddButton text="Agregar proceso" onClick={ async () => {
                                history.push("/procesos/agregar")
                            } } />
                    }
                </div>

            </div>

            <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <InputSearch textPlaceholder="Buscar proceso" onSubmit={onSubmitSearch}/>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-md-3 mt-sm-3 mt-3">
                    <div className="d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-end">

                        <div className="dropdown mx-2">
                            <button className="btn btn-sm btn-light dropdown-toggle text-muted" data-toggle="dropdown" aria-expanded="false">
                                <i className="fa-solid fa-arrow-down-short-wide mr-2"></i>
                                    Ordenar
                            </button>

                            <div className="dropdown-menu">
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','ASC')}>Nombre de A-Z</button>
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','DESC')}>Nombre de Z-A</button>
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','ASC')}>Fecha alta de A-Z</button>
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','DESC')}>Fecha alta de Z-A</button>
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('costo','ASC')}>Costo de 1-9</button>
                                <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('costo','DESC')}>Costo de 9-1</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="my-3" />

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton text="Unidad" type="id_unidad" onSubmit={onSubmitFilter} />
                                <FilterButton text="Estatus" type="estatus" onSubmit={onSubmitFilter} />
                                <FilterButton text="Fecha alta" type="fecha_alta" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <button className={`btn btn-sm btn-light text-muted ${!showDeleteFilters && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                    <Icon icon='fa-solid fa-x' /> Eliminar
                </button>

            </div>

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody elements={elements} typeElement="procesos" permisosUsuario={modulosPermisos} />
                    </Table>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

        </section>
    );
}