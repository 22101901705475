import TableElementExpediente from './TableElementExpediente';
import TableElementTrabajador from './TableElementTrabajador';
import TableElementProceso from './TableElementProceso';
import TableElementIngreso from './TableElementIngreso';
import TableElementTrabajadorUnidad from './TableElementTrabajadorUnidad';
import TableElementIngresoUnidad from './TableElementIngresoUnidad';
import TableElementEgresoUnidad from './TableElementEgresoUnidad';
import TableElementProcesoExpediente from './TableElementProcesoExpediente';
import TableElementIngresosExpediente from './TableElementIngresosExpediente';
import TableElementIngresosEmpleado from './TableElementIngresosEmpleado';
import TableElementTipoEgreso from './TableElementTipoEgreso';
import TableElementMaterialUnidad from './TableElementMaterialUnidad';
import TableElementSalidaMaterialUnidad from './TableElementSalidaMaterialUnidad';
import TableElementCompraMaterialUnidad from './TableElementCompraMaterialUnidad';
import TableElementMaterial from './TableElementMaterial';
import TableElementUsuario from './TableElementUsuario';
import TableElementCitaExpediente from './TableElementCitaExpediente';
import TableElementProcesoExpedienteAsignado from './TableElementProcesoExpedienteAsignado';
import TableElementIngresoProceso from './TableElementIngresoProceso';
import TableElementLog from './TableElementLog';
import TableElementProducto from './TableElementProducto';
import TableElementProductoUnidad from './TableElementProductoUnidad';
import TableElementSalidaProductoUnidad from './TableElementSalidaProductoUnidad';
import TableElementCompraProductoUnidad from './TableElementCompraProductoUnidad';
import TableElementOrdenTrabajo from './TableElementOrdenTrabajo';
import TableElementHistorialOrdenTrabajo from './TableElementHistorialOrdenTrabajo';
import TableElementDashboardOrdenTrabajo from './TableElementDashboardOrdenTrabajo';
import TableElementCitaTrabajador from './TableElementCitaTrabajador';

export default function TableBody({ elements, typeElement, permisosUsuario, reload, setIdIngresoReembolso, setElementToEdit, onClickVerDetalles}){

    function renderTableElements(){
        if(typeElement === "expedientes"){
            return elements.map(
                (element) => 
                    <TableElementExpediente 
                        key={element.id_permisionario} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                        setElementToEdit={setElementToEdit}
                    />);
        }
        if(typeElement === "trabajadores"){
            return elements.map(
                (element) => 
                    <TableElementTrabajador
                        key={element.id_trabajador} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                    />);
        }
        if(typeElement === "procesos"){
            return elements.map(
                (element) => 
                    <TableElementProceso
                        key={element.id_proceso} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                    />);
        }
        if(typeElement === "materiales"){
            return elements.map(
                (element) => 
                    <TableElementMaterial
                        key={element.id_material} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                        setElementToEdit={setElementToEdit}
                        reload={reload}
                    />);
        }
        if(typeElement === "productos"){
            return elements.map(
                (element) => 
                    <TableElementProducto
                        key={element.id_producto} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                        setElementToEdit={setElementToEdit}
                        reload={reload}
                    />);
        }
        if(typeElement === "usuario"){
            return elements.map(
                (element) => 
                    <TableElementUsuario
                        key={element.id_usuario} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                        setElementToEdit={setElementToEdit}
                        reload={reload}
                    />);
        }
        if(typeElement === "tipo_egreso"){
            return elements.map(
                (element) => 
                    <TableElementTipoEgreso
                        key={element.id_tipo_egreso} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                        setElementToEdit={setElementToEdit}
                        reload={reload}
                    />);
        }
        if(typeElement === "ingresos"){
            return elements.map(
                (element) => 
                    <TableElementIngreso
                        key={element.id_ingreso} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                    />);
        }
        if(typeElement === "trabajador_unidad"){
            return elements.map(
                (element) => 
                    <TableElementTrabajadorUnidad
                        key={element.id_empleado} 
                        element={element} 
                        permisosUsuario={permisosUsuario} 
                    />);
        }
        if(typeElement === "ingresos_unidad"){
            return elements.map(
                (element) => 
                    <TableElementIngresoUnidad
                        key={element.id_ingreso} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                        setElementToEdit={setElementToEdit}
                    />);
        }
        if(typeElement === "egresos_unidad"){
            return elements.map(
                (element) => 
                    <TableElementEgresoUnidad
                        key={element.id_egreso} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                        setElementToEdit={setElementToEdit}
                    />);
        }
        if(typeElement === "material_unidad"){
            return elements.map(
                (element) => 
                    <TableElementMaterialUnidad
                        key={element.material} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        setElementToEdit={setElementToEdit}
                    />);
        }
        if(typeElement === "salida_material_unidad"){
            return elements.map(
                (element) => 
                    <TableElementSalidaMaterialUnidad
                        key={element.material} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload}
                        // setElementToEdit={setElementToEdit}
                    />);
        }
        if(typeElement === "compra_material_unidad"){
            return elements.map(
                (element) => 
                    <TableElementCompraMaterialUnidad
                        key={element.material} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload}
                        // setElementToEdit={setElementToEdit}
                    />);
        }
        // if(typeElement === "egresos_trabajador"){
        //     return elements.map(
        //         (element) => 
        //             <TableElementEgresoTrabajador
        //                 key={element.id_egreso} 
        //                 element={element} 
        //                 permisosUsuario={permisosUsuario}
        //                 reload={reload} 
        //             />);
        // }
        if(typeElement === "proceso_expediente"){
            return elements.map(
                (element) => 
                    <TableElementProcesoExpediente
                        key={element.id_proceso_expediente} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                    />);
        }
        if(typeElement === "ingresos_expediente"){
            return elements.map(
                (element) => 
                    <TableElementIngresosExpediente
                        key={element.id_ingreso}
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                        setIdIngresoReembolso={setIdIngresoReembolso}
                        setElementToEdit={setElementToEdit}
                    />);
        }
        if(typeElement === "cita_expediente"){
            return elements.map(
                (element) => 
                    <TableElementCitaExpediente
                        key={element.id_cita}
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                        setElementToEdit={setElementToEdit}
                    />);
        }
        if(typeElement === "cita_trabajador"){
            return elements.map(
                (element) => 
                    <TableElementCitaTrabajador
                        key={element.id_cita}
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                        setElementToEdit={setElementToEdit}
                    />);
        }
        if(typeElement === "ingresos_empleado"){
            return elements.map(
                (element) => 
                    <TableElementIngresosEmpleado
                        key={element.id_ingreso}
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                        setIdIngresoReembolso={setIdIngresoReembolso}
                    />);
        }
        if(typeElement === "proceso_expediente_asignado"){
            return elements.map(
                (element) => 
                    <TableElementProcesoExpedienteAsignado
                        key={element.id_ingreso}
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                    />);
        }

        if(typeElement === "ingresos_proceso"){
            return elements.map(
                (element) => 
                    <TableElementIngresoProceso
                        key={element.id_ingreso}
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload} 
                    />);
        }

        if(typeElement === "logs"){
            return elements.map(
                (element) => 
                    <TableElementLog
                        key={element.id_history}
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        setElementToEdit={setElementToEdit}
                    />);
        }

        if(typeElement === "producto_unidad"){
            return elements.map(
                (element) => 
                    <TableElementProductoUnidad
                        key={element.producto} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        setElementToEdit={setElementToEdit}
                    />);
        }
        
        if(typeElement === "salida_producto_unidad"){
            return elements.map(
                (element) => 
                    <TableElementSalidaProductoUnidad
                        key={element.id_salida_producto_unidad} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload}
                        // setElementToEdit={setElementToEdit}
                    />);
        }
        
        if(typeElement === "compra_producto_unidad"){
            return elements.map(
                (element) => 
                    <TableElementCompraProductoUnidad
                        key={element.id_producto_unidad} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        reload={reload}
                        // setElementToEdit={setElementToEdit}
                    />);
        }

        if(typeElement === "orden_trabajo"){
            return elements.map(
                (element) => 
                    <TableElementOrdenTrabajo
                        key={element.id_orden_trabajo} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        setElementToEdit={setElementToEdit}
                        reload={reload}
                        onClickVerDetalles={onClickVerDetalles}
                    />);
        }

        if(typeElement === "historial_orden_trabajo"){
            return elements.map(
                (element) => 
                    <TableElementHistorialOrdenTrabajo
                        key={element.id_historial_orden_trabajo} 
                        element={element} 
                        permisosUsuario={permisosUsuario}
                        onClickVerDetalles={onClickVerDetalles}
                    />);
        }

        if(typeElement === "dashboard_orden_trabajo"){
            return elements.map(
                (element) => 
                    <TableElementDashboardOrdenTrabajo
                        key={element.id_orden_trabajo} 
                        element={element} 
                        reload={reload}
                    />);
        }
    }

    return(
        <tbody>

            {   
                renderTableElements()
            }

        </tbody>
    )
}