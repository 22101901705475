import React, { useEffect } from 'react'
import { validateNoNumbers } from '../../validators/validateNoNumbers'
import ToggleButton from '../ToggleButton'
import useMetodosPago from '../../hooks/useMetodosPago'
import $ from 'jquery'

export default function ModalEnviarTrabajoLaboratorio({ onSubmitModal }) {
    const { metodosPago } = useMetodosPago()
    useEffect(() => {
        $('.selectpicker').selectpicker('refresh')
    }, [metodosPago])
    return (
        <div
            className="modal"
            id="modalEnviarTrabajoLaboratorio"
            role="dialog"
            aria-labelledby="modalEnviarTrabajoLaboratorioLabel"
        >
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmitModal} id="formModalEnviarTrabajoLaboratorio" className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalEnviarTrabajoLaboratorioLabel">
                            Enviar trabajo al laboratorio
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="mr-4">
                                        <label className="m-0">Se manda a finalizar el trabajo?</label>
                                        <br />
                                        <small className="text-muted">
                                            Sí ya se mandará a terminar el trabajo, marca esta opción. Recuerda pagar el
                                            total.
                                        </small>
                                    </div>
                                    <ToggleButton id="es_orden_final" name="es_orden_final" className="my-0" />
                                </div>
                                <hr className="my-2" />
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <label htmlFor="fecha_entrega_laboratorio">Fecha de entrega al laboratorio:</label>
                                <input
                                    type="date"
                                    className="input"
                                    id="fecha_entrega_laboratorio"
                                    name="fecha_entrega_laboratorio"
                                    required
                                />
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <label htmlFor="fecha_entrega">Fecha de próxima entrega:</label>
                                <input type="date" className="input" id="fecha_entrega" name="fecha_entrega" required />
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label htmlFor="etapa">Nombre de la etapa del trabajo:</label>
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Ejemplo: Prueba de bizcocho"
                                    id="etapa"
                                    name="etapa"
                                    required
                                />
                            </div>

                            <div className="col-6">
                                <label htmlFor="cantidad">Cantidad de pago:</label>
                                <input
                                    type="tel"
                                    className="input"
                                    onKeyPress={validateNoNumbers}
                                    id="cantidad"
                                    name="cantidad"
                                    placeholder="$ 0"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <div className="formulario__grupo-input">
                                    <label htmlFor="cantidad_a_cuenta" className="formulario__label">
                                        Método de pago:
                                        {/* <br />
                  <small className="text-muted font-weight-bold">
                    Opcional
                  </small> */}
                                    </label>
                                    <select
                                        className="input selectpicker"
                                        id="id_metodo_pago"
                                        name="id_metodo_pago"
                                        required
                                    >
                                        <option value="">Elige un método de pago</option>
                                        {metodosPago &&
                                            metodosPago.map(metodo_pago => (
                                                <option
                                                    key={metodo_pago.id_metodo_pago}
                                                    value={metodo_pago.id_metodo_pago}
                                                >
                                                    {metodo_pago.nombre}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                <label htmlFor="descripcion">Descripción detallada de la orden:</label>
                                <textarea
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="Sé lo más detallado posible (Opcional)"
                                    className="input textarea"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="submit" className="btn btn-primary" id="btnModalEnviarTrabajoLaboratorioSubmit">
                            Enviar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
