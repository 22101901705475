import { Link } from 'react-router-dom';
import React from 'react';
import Swal from 'sweetalert2'
import $ from 'jquery'
// import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElementExpediente({ element, permisosUsuario, setElementToEdit }) {

    let { folio, nombrePaciente, totalPagado, totalDeuda, fechaUltimoPago, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el expediente?' : 'Quieres deshabilitar el expediente?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                // let values = new FormData()
                // values.append('folio', folio)
                // values.append('activo', activo)
                // let objectValues = Object.fromEntries(values);
                // // console.log({objectValues})
                // changeEstatusPermisionarioService({values:objectValues}).then(response => {
                //     // console.log(response)
                //     let _respuesta = JSON.parse(response);
                //     if (_respuesta.response === "success") {
                //         setEstadoEstatus(activo)
                //         Swal.fire(
                //             'Estatus actualizado',
                //             '',
                //             'success'
                //         )
                //     }
                    
                // })
            }
        })
    }

    return (
        <tr key={folio}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/expedientes/${folio}`} className="dropdown-item" >Ver</Link>
                            {
                                (permisosUsuario.expedientesCitas.escribir === '1') &&
                                    <a href className="dropdown-item bg-info text-white" onClick={() => {
                                        $("#modalAddCitaCompletadaExpediente").modal('show')
                                        setElementToEdit(element)
                                    }}>
                                        Marcar una cita realizada
                                    </a>
                            }
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{nombrePaciente}</p>
                        <small>Folio: {folio}</small>
                    </div>
                </div>
            </th>

            <td>
                <p>
                    { parseFloat(totalPagado).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                </p>
            </td>

            <td>
                <p>
                    { parseFloat(totalDeuda).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                </p>
            </td>

            <td>
                <p>{fechaUltimoPago}</p>
            </td>

            {/* <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus === '1' ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td> */}
        </tr>
    )
    
}