import React from "react";
import Swal from "sweetalert2";
import deleteEgreso from "../../services/egreso/deleteEgreso";
import $ from "jquery";
// import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElementEgresoUnidad({
  element,
  permisosUsuario,
  reload,
  setElementToEdit,
}) {
  let {
    id_egreso,
    nombre_tipo_gasto,
    nombre_empleado,
    nombre_unidad,
    nombre_material,
    cantidad_disponible_material,
    cantidad,
    nombre_forma_pago,
    descripcion,
    fecha_del_pago,
    activo,
    nombre_producto_farmacia,
    cantidad_compra_producto,
    nombre_laboratorio,
    nombre_trabajo_laboratorio,
    nombre_paciente,
    folio_expediente
  } = element;

  const onClickEstatus = () => {
    let msg = "Quieres eliminar el egreso?";
    let txt = "Eliminar";
    Swal.fire({
      title: msg,
      showCancelButton: true,
      confirmButtonText: txt,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        let values = new FormData();
        values.append("id_egreso", id_egreso);
        let objectValues = Object.fromEntries(values);
        deleteEgreso({ values: objectValues }).then((response) => {
          // console.log(response)
          let _respuesta = JSON.parse(response);
          if (_respuesta.response === "success") {
            Swal.fire({
              title: "Eliminado correctamente",
              timer: 1200,
              showConfirmButton: false,
              icon: "success",
            });
            reload();
          }
        });
      }
    });
  };

  const onUpdateElement = () => {
    // setElementToEdit(null)
    setElementToEdit(element);
    $("#modalAddEgresoUnidad").modal("show");
  };

  return (
    <tr key={id_egreso}>
      <th className="table-max-30">
        <div className="table__name">
          {activo === "1" &&
            (permisosUsuario.egresos.eliminar === "1" ||
              permisosUsuario.egresos.editar === "1") && (
              <div className="dropdown">
                <button
                  type="button"
                  className="btn dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {permisosUsuario.egresos.editar === "1" && (
                    <a
                      className="dropdown-item text-black"
                      onClick={() => onUpdateElement()}
                    >
                      Editar
                    </a>
                  )}

                  {permisosUsuario.egresos.eliminar === "1" && (
                    <a
                      className="dropdown-item bg-danger text-white"
                      onClick={() => onClickEstatus()}
                    >
                      Eliminar
                    </a>
                  )}
                </div>
              </div>
            )}

          <div className="table__nameDescription">
            <p>#{id_egreso}</p>
            <small className="text-muted">{fecha_del_pago}</small>
          </div>
        </div>
      </th>

      <td>
        <small className="text-muted">
          {nombre_empleado && nombre_empleado}
        </small>
        <p>{nombre_tipo_gasto ? nombre_tipo_gasto : "-"}</p>
        <small className="text-muted">
          {nombre_unidad ? `Unidad: ${nombre_unidad}` : ""}
        </small>
      </td>

      <td>
        <p>$ {cantidad}</p>
        <small className="text-muted">{nombre_forma_pago}</small>
      </td>

      <td>
        {nombre_producto_farmacia && (
          <>
            <p>
              {nombre_producto_farmacia &&
                `Producto: ${nombre_producto_farmacia}`}
            </p>
            <small>{`Cantidad: ${cantidad_compra_producto}`}</small>
          </>
        )}

        {nombre_laboratorio && (
          <>
            <p>{`${nombre_laboratorio}, ${nombre_trabajo_laboratorio}`}</p>
            <small>{`del paciente ${nombre_paciente} - Folio: ${folio_expediente}`}</small>
          </>
        )}

        {descripcion !== null && (
          <>
            <p>{`${descripcion}`}</p>
          </>
        )}
        {/* {
                    (descripcion === null) && (!nombre_material || !nombre_producto_farmacia) && '-'
                } */}
        {descripcion === null && nombre_material && (
          <p>
            {nombre_material &&
              `Material: ${nombre_material}, cantidad: ${cantidad_disponible_material}`}
          </p>
        )}
      </td>
    </tr>
  );
}
