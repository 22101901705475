import React from "react";
import getEmpleados from "../../services/empleado/getEmpleados";
import $ from 'jquery'
import Icon from "../Icon";

export default function FormularioUnidad({unidad, onSubmit, modulosPermisos}){

    const [empleados, setEmpleados] = React.useState([]);

    function getEmpleadosService(){
        getEmpleados({values:{}})
            .then(res => {
                let _res = JSON.parse(res)
                setEmpleados(_res.data)
                $('.selectpicker').selectpicker();
                if(unidad !== undefined)
                    setDataFormulario()
            })
    }

    const setDataFormulario = ()=>{
        document.getElementById("nombre").value = unidad.nombre;
        document.getElementById("descripcion").value = unidad.descripcion;
        document.getElementById("id_trabajador").value = unidad.id_empleado;
        $('.selectpicker').selectpicker('refresh');
        if(modulosPermisos.unidades.editar === "0"){
            $("#formularioUnidad :input, :input[form]").prop("readonly", true);
        }
    }

    React.useEffect(getEmpleadosService, []);

    return(
        <form className="formulario" id="formularioUnidad" onSubmit={ onSubmit }>

            {/* ******************************* */}
            {/* BLOQUE DE LOS DATOS PRINCIPALES */}
            {/* ******************************* */}

            <div className=''>
                <h4 className='mb-4'>
                    Datos de la unidad
                </h4>

                <div className="row">
                    <div className="col-5">
                        <label htmlFor="nombre">
                            Nombre:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="nombre"
                            name="nombre"
                            required
                        />
                    </div>
                    <div className="col-7">
                        <label htmlFor="descripcion">
                            Descripción:
                        </label>
                        <input 
                            type="text"
                            className="input" 
                            id="descripcion"
                            name="descripcion"
                            placeholder='Opcional'
                        />
                    </div>
                </div>
            </div>
            
            <div className='mt-4'>
                <h4 className='my-4'>
                    Responsable de la unidad
                </h4>

                <div className="row">
                    <div className="col-5">
                        <label htmlFor="id_trabajador">
                            Elige al responsable:
                        </label>
                        <br></br>
                        <select 
                            className="input selectpicker"
                            id="id_trabajador"
                            name="id_trabajador"
                            required
                        >
                            {
                                empleados &&
                                empleados.map( (empleado) => (
                                    <option key={ empleado.id_empleado } value={ empleado.id_empleado }>{ empleado.nombre }</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            
            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            <hr className='my-4' /> 

            <div className="d-flex justify-content-end">
                
                {
                    (unidad !== undefined && modulosPermisos.unidades.editar === '1') &&
                        <button className="btn btn-azul noMargin">
                            <Icon icon="fa-solid fa-hospital mr-3" />
                            Actualizar unidad
                        </button>
                }

                { 
                    (unidad === undefined && modulosPermisos.unidades.escribir === '1') &&
                        <button className="btn btn-azul noMargin">
                            <Icon icon="fa-solid fa-plus mr-2" />
                            <Icon icon="fa-solid fa-hospital mr-2" />
                            Agregar unidad
                        </button>
                }
            </div>


        </form>
    )
}