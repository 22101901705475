import React from 'react'
import LoadingIndicator from '../../components/LoadingIndicator';
import getLogs from '../../services/history/getLogs';
import AlertWarningSearch from '../../components/AlertWarningSearch';
import LogViewer from '../../components/LogViewer';
import Table from '../../components/Table';
import TableHead from '../../components/Table/TableHead';
import TableBody from '../../components/Table/TableBody';
import ModalViewLog from '../../components/ModalViewLog';
import FilterButton from '../../components/FilterButton';
import Icon from '../../components/Icon';
import getUsers from '../../services/user/getUsers';

export default function Logs(props){

    const { modulosPermisos } = props;

    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay()-1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const [logs, setLogs] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadingFilters, setLoadingFilters] = React.useState(false)
    const [filters, setFilters] = React.useState([])
    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [logSelected, setLogSelected] = React.useState({})
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        let nuevosFiltros = [...filters]

        let newFiltros = nuevosFiltros.filter((value) =>{
            return value.campo !== tipoFiltro
        })

        newFiltros.push({ campo: tipoFiltro, data: object })
        setFilters(newFiltros)
    }

    const onClickDeleteFilters = () => {
        setFilters([])
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(()=>{
            setLoadingFilters(false)
        }, 50)
    }

    const getLogsService = ()=>{
        setLoading(true)
        setLogs([])
        
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal
        }
        
        // Si existen los filtros, agregarlos.
        if(filters.length !== 0)
            data.filtros = filters

        // console.log(data)
        
        getLogs({values:data})
            .then(res =>{
                // console.log(res)
                let _res = JSON.parse(res);
                // console.log(_res)
                if(_res.response === 'success'){
                    setLogs(_res.data)
                }
                setLoading(false)
            })
    }

    React.useEffect(getLogsService, [fechaInicio, fechaFinal, filters])

    return(
        <section>

            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        <h1>Registros del sistema</h1>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-start justify-content-sm-start mt-md-4 mt-sm-4 mt-4">
                    <form onSubmit={onSubmitFechas} className="">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                            <p className="mx-2">y</p>
                            <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                            <button className='btn btn btn-gris ml-2'><i className="fa-solid fa-search"></i></button>
                        </div>
                    </form>
                </div>
            </div>

            <hr className='my-3' />

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton text="Usuario" type="id_usuario" onSubmit={onSubmitFilter} />
                                <FilterButton text="Acción" type="accion" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <button className={`btn btn-sm btn-light text-muted ${!showDeleteFilters && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                    <Icon icon='fa-solid fa-x' /> Eliminar
                </button>

            </div>

            {
                ( logs.length !== 0 && !loading ) &&
                    <main>
                        <Table>
                            <TableHead rows={[
                                { id: 1, name: "Acción" },
                                { id: 3, name: "Entidad" },
                                { id: 4, name: "ID" },
                                { id: 2, name: "Fecha" },
                            ]} />

                            <TableBody 
                                elements={logs} 
                                typeElement="logs" 
                                permisosUsuario={modulosPermisos}
                                setElementToEdit={setLogSelected}
                                // reload={getUsuariosService}
                            />
                        </Table>
                        {/* <LogViewer logs={logs} /> */}
                    </main>
            }

            <LoadingIndicator show={loading} />

            { ( logs.length === 0 && !loading ) && <AlertWarningSearch /> }

            <ModalViewLog 
                log={logSelected}
            />

        </section>
    );
}