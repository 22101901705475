import React from 'react';
import { ConnectionError, handleCallApiError } from '../../validators/errors';
import getLaboratorios from '../../services/laboratorio/getLaboratorios';
import addLaboratorio from '../../services/laboratorio/addLaboratorio';
import Swal from 'sweetalert2';
import getTrabajosByIdLaboratorio from '../../services/laboratorio/getTrabajosByIdLaboratorio';
import addTrabajoLaboratorio from '../../services/laboratorio/addTrabajoLaboratorio';

export default function useLaboratorios(){

    const [laboratorios, setLaboratorios] = React.useState([]);
    const [loadingLaboratorios, setLoadingLaboratorios] = React.useState(true);

    const [trabajosLaboratorio, setTrabajosLaboratorio] = React.useState([]);
    const [loadingTrabajosLaboratorio, setLoadingTrabajosLaboratorio] = React.useState(true);

    const getLaboratoriosByUnidadService = (id_unidad) => {
        setLoadingLaboratorios(true)
        setLaboratorios([])
        let data = { id_unidad }
        getLaboratorios({values:data})
            .then(res => {
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setLaboratorios(_res.data)
                }
                setLoadingLaboratorios(false)
            })
            .catch(e => {
                console.log(e)
                setLoadingLaboratorios(false)
                // Intentar conectar de nuevo en unos segundos
                if(e instanceof ConnectionError){
                    handleCallApiError("ConnectionError")
                }
            })
    }

    const addLaboratorioService = (nombre) =>{
        Swal.showLoading()
        let data = {
            nombre: nombre
        }

        addLaboratorio({values:data})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    let newLaboratorio = {
                        nombre: nombre,
                        id_laboratorio: _res.id_laboratorio
                    }
                    setLaboratorios([...laboratorios, newLaboratorio])
                    document.getElementById('id_laboratorio').value = _res.id_laboratorio
                    Swal.fire({
                        title: 'Agregado',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false
                    })
                } else {
                    Swal.fire({
                        title: 'Error al agregar el laboratorio',
                        text: 'Repórtalo con el desarrollador',
                        icon: 'error'
                    })
                }
            })
            .catch(e =>{
                setLoadingLaboratorios(false)
                // Intentar conectar de nuevo en unos segundos
                if(e instanceof ConnectionError){
                    handleCallApiError("ConnectionError")
                }
                console.log(e)
                Swal.fire({
                    title: 'Error al agregar el laboratorio',
                    text: 'Repórtalo con el desarrollador',
                    icon: 'error'
                })
            })
    }
    
    const addTrabajoLaboratorioService = (nombre, id_laboratorio) =>{
        Swal.showLoading()
        let data = {
            nombre: nombre,
            id_laboratorio: id_laboratorio
        }

        addTrabajoLaboratorio({values:data})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    let newTrabajoLaboratorio = {
                        nombre: nombre,
                        id_trabajo_laboratorio: _res.id_trabajo_laboratorio
                    }
                    setTrabajosLaboratorio([...trabajosLaboratorio, newTrabajoLaboratorio])
                    document.getElementById('id_trabajo_laboratorio').value = _res.id_trabajo_laboratorio
                    Swal.fire({
                        title: 'Agregado',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false
                    })
                } else {
                    Swal.fire({
                        title: 'Error al agregar el trabajo de laboratorio',
                        text: 'Repórtalo con el desarrollador',
                        icon: 'error'
                    })
                }
            })
            .catch(e =>{
                setLoadingLaboratorios(false)
                // Intentar conectar de nuevo en unos segundos
                if(e instanceof ConnectionError){
                    handleCallApiError("ConnectionError")
                }
                console.log(e)
                Swal.fire({
                    title: 'Error al agregar el laboratorio',
                    text: 'Repórtalo con el desarrollador',
                    icon: 'error'
                })
            })
    }

    const getTrabajosByLaboratorioService = (id_laboratorio) => {
        setLoadingTrabajosLaboratorio(true);
        setTrabajosLaboratorio([]);
        let data = { id_laboratorio };
        // console.log(data);
        getTrabajosByIdLaboratorio({ values: data })
          .then((res) => {
            console.log(res);
            let _res = JSON.parse(res);
            if (_res.response === 'success') {
              setTrabajosLaboratorio(_res.data);
            }
            setLoadingTrabajosLaboratorio(false);
          })
          .catch((e) => {
            setLoadingTrabajosLaboratorio(false);
            if (e instanceof ConnectionError) {
              handleCallApiError('ConnectionError');
            }
          });
      };

    React.useEffect(getLaboratoriosByUnidadService, [])

    return { 
        laboratorios, 
        loadingLaboratorios,
        trabajosLaboratorio,
        loadingTrabajosLaboratorio,
        getLaboratoriosByUnidadService,
        addLaboratorioService,
        getTrabajosByLaboratorioService,
        addTrabajoLaboratorioService
    }

}