const createErrorFactory = function (name) {
    return class extends Error {
        constructor(message) {
            super(message);
            this.name = name;
        }
    }
}

export const handleCallApiError = function (error) {
    if (error instanceof ConnectionError) {
        console.log("ConnectionError");
        return;
    }
    
    if (error instanceof ValidationError) {
        console.log("ValidationError");
        return;
    }
    
    if (error instanceof NotFoundError) {
        console.log("NotFoundError");
        return;
    } 
    
    if (error instanceof UnauthorizedError) {
        console.log("UnauthorizedError");
        return;
    } 
    
    if (error instanceof ForbiddenError) {
        console.log("ForbiddenError");
        return;
    }
    
    if (error instanceof InternalServerError) {
        console.log("InternalServerError");
        return;
    }

    console.log("UnknownError");
    return;
}

export const ConnectionError = createErrorFactory("ConnectionError");
export const ValidationError = createErrorFactory("ValidationError");
export const NotFoundError = createErrorFactory("NotFoundError");
export const UnauthorizedError = createErrorFactory("UnauthorizedError");
export const ForbiddenError = createErrorFactory("ForbiddenError");
export const InternalServerError = createErrorFactory("InternalServerError");