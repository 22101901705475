import React from "react";
import './CardCostoTratamientosExpediente.css'

export default function CardCostoTratamientosExpediente(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');

    return(
        <div className='d-flex justify-content-evenly globalCardsIngresosUnidad'>
            <div className='cardIngresosUnidad cardIngresosUnidad--no-border-left'>
                <small className='font-weight-bold'>Total presupuesto:</small>
                <h2>$&nbsp;
                {
                    props.total_presupuesto !== undefined ?
                        dollarUSLocale.format(props.total_presupuesto) : 0
                }
                </h2>
            </div>
            <div className='cardIngresosUnidad'>
                <small className='color-gris font-weight-bold'>Total pagado:</small>
                <h2>$&nbsp;
                {
                    props.total_ha_pagado !== undefined ?
                        dollarUSLocale.format(props.total_ha_pagado) : 0
                }
                </h2>
            </div>
            <div className='cardIngresosUnidad'>
                <small className='color-verde font-weight-bold'>Total deuda:</small>
                <h2>$&nbsp;
                {
                    props.total_debe !== undefined ?
                        dollarUSLocale.format(props.total_debe) : 0
                }
                </h2>
            </div>
        </div>
    )
}