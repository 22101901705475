import React from 'react'
import Swal from 'sweetalert2'

export default function TableElementHistorialOrdenTrabajo({ element, permisosUsuario, onClickVerDetalles }) {
    let {
        id_historial_orden_trabajo,
        id_orden_trabajo,
        descripcion,
        entregado,
        etapa,
        es_terminado,
        fecha_alta,
        fecha_entrega,
        fecha_que_entrego,
        fecha_recepcion,
        pago,
    } = element

    const onClickEstatus = () => {
        let msg = 'Quieres eliminar el egreso?'
        let txt = 'Eliminar'
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
            if (result.isConfirmed) {
                let values = new FormData()
                values.append('id_historial_orden_trabajo', id_historial_orden_trabajo)
                let objectValues = Object.fromEntries(values)
                onClickVerDetalles(id_orden_trabajo)
                // deleteEgreso({ values: objectValues }).then(response => {
                //     // console.log(response)
                //     let _respuesta = JSON.parse(response)
                //     if (_respuesta.response === 'success') {
                //         Swal.fire({
                //             title: 'Eliminado correctamente',
                //             timer: 1200,
                //             showConfirmButton: false,
                //             icon: 'success',
                //         })
                //         reload()
                //     }
                // })
            }
        })
    }

    return (
        <tr key={id_historial_orden_trabajo}>
            <th className="table-max-20">
                <div className="table__name">
                    {(permisosUsuario.egresos.eliminar === '1' || permisosUsuario.egresos.editar === '1') && (
                        <div className="dropdown">
                            <button
                                type="button"
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {permisosUsuario.egresos.eliminar === '1' && (
                                    <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus()}>
                                        Eliminar
                                    </a>
                                )}
                            </div>
                        </div>
                    )}

                    <div>
                        {fecha_recepcion !== null && (
                            <small className="my-2">
                                Recolectado el:
                                <br />
                                <span className="font-weight-bold">{fecha_recepcion}</span>
                            </small>
                        )}
                        {fecha_entrega !== null && fecha_que_entrego === null && (
                            <>
                                <br />
                                <small>
                                    Próxima entrega:
                                    <br />
                                    <span className="font-weight-bold">{fecha_entrega}</span>
                                </small>
                            </>
                        )}
                        {fecha_que_entrego !== null && (
                            <>
                                <br />
                                <small>
                                    Lo entregó el:
                                    <br />
                                    <span className="font-weight-bold">{fecha_que_entrego}</span>
                                </small>
                            </>
                        )}
                    </div>
                </div>
            </th>

            <td className="table-max-20">
                {es_terminado === '1' && (
                    <div className="table__estatus">
                        <span>Para finalizar</span>
                    </div>
                )}
                <p className="my-2">{etapa}</p>
                <small className="font-weight-bold">
                    Pago: {parseFloat(pago).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </small>
            </td>

            <td className="table-null-no-wrap">
                <small>{descripcion}</small>
            </td>
        </tr>
    )
}
