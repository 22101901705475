import React from "react";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import Icon from "../../components/Icon";
import getProceso from "../../services/proceso/getProceso";
import updateProceso from "../../services/proceso/updateProceso";
import updateEstatusProceso from "../../services/proceso/updateEstatusProceso";
import FormularioProceso from "../../components/FormularioProceso";
import ExpedientesProceso from "../../components/ExpedientesProceso";
import PagosProceso from "../../components/PagosProceso";

export default function ViewProceso(props){

    let { modulosPermisos } = props
    let { id_proceso } = useParams()

    const [proceso, setProceso] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const getProcesoService = () => {
        let data = {
            id_proceso: id_proceso
        }
        getProceso({values:data})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    setProceso(_response.data);
                    setLoading(false)
                }
            })
            .catch(error => {
                // console.log(`Ha petado getProceso(): ${error}`)
            })
    }

    const onSubmitUpdate = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_proceso', id_proceso);
        let objectValues = Object.fromEntries(values);
        // console.log({objectValues})
        updateProceso({values:objectValues})
            .then(response => {
                // // console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                    getProcesoService();
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el proceso?' : 'Quieres deshabilitar el proceso?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_proceso', id_proceso)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                updateEstatusProceso({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getProcesoService();
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    React.useEffect(getProcesoService, []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {
                    window.history.go(-1);
                    return false;
                    }}
                    type="submit"
                    value="Regresar"
                    className="btn btn-gris btn-sm noMargin"
                />

                <hr />

                <div className="row">
                    <div className="col-md-3">
                        <h2>{proceso.nombre}</h2>

                        <div className="d-flex flex-column">
                            <p className="my-2">id_proceso: {proceso.id_proceso}</p>
                            <small>Fecha alta: {proceso.fecha_alta}</small>
                            <span
                                className={`mt-2 flag ${proceso.activo === '1' ? '' : 'flag-inactive'}`}
                            >
                                {proceso.activo === '1' ? 'Activo' : 'Inactivo'}
                            </span>
                        </div>

                        <hr />

                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a
                                className="nav-link active"
                                id="v-pills-home-tab"
                                data-toggle="pill"
                                href="#v-pills-home"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                            >
                                <Icon icon="fas fa-hospital mr-2" />
                                Datos generales
                            </a>

                            {modulosPermisos.expedientes.leer === '1' && (
                                <a
                                    className="nav-link"
                                    id="v-pills-procesos-tab"
                                    data-toggle="pill"
                                    href="#v-pills-procesos"
                                    role="tab"
                                    aria-controls="v-pills-procesos"
                                    aria-selected="true"
                                >
                                    <Icon icon="fas fa-book-medical mr-2" />
                                    Expedientes
                                </a>
                            )}

                            {modulosPermisos.ingresos.leer === '1' && (
                                <a
                                    className="nav-link"
                                    id="v-pills-ingresos-tab"
                                    data-toggle="pill"
                                    href="#v-pills-ingresos"
                                    role="tab"
                                    aria-controls="v-pills-ingresos"
                                    aria-selected="true"
                                >
                                    <Icon icon="fas fa-money-bill-transfer mr-2" />
                                    Pagos
                                </a>
                            )}

                            {/* <a className="nav-link" id="v-pills-empleados-tab" data-toggle="pill" href="#v-pills-empleados" role="tab" aria-controls="v-pills-empleados" aria-selected="true">
                                <Icon icon="fas fa-user mr-2" />
                                Materiales e insumos
                            </a>

                            <a className="nav-link" id="v-pills-empleados-tab" data-toggle="pill" href="#v-pills-empleados" role="tab" aria-controls="v-pills-empleados" aria-selected="true">
                                <Icon icon="fas fa-book-medical mr-2" />
                                Procesos
                            </a>

                            <a className="nav-link" id="v-pills-empleados-tab" data-toggle="pill" href="#v-pills-empleados" role="tab" aria-controls="v-pills-empleados" aria-selected="true">
                                <Icon icon="fas fa-file-medical mr-2" />
                                Pacientes atendidos
                            </a> */}

                            {proceso.activo === '1' ? (
                                <a
                                    className="nav-link"
                                    role="tab"
                                    type="button"
                                    onClick={() => onClickEstatus(0)}
                                >
                                    <Icon icon="fa-solid fa-circle-minus mr-2" />
                                    Deshabilitar
                                </a>
                                ) : (
                                <a
                                    className="nav-link"
                                    role="tab"
                                    type="button"
                                    onClick={() => onClickEstatus(1)}
                                >
                                    <Icon icon="fa-solid fa-circle-plus mr-2" />
                                    Habilitar
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                {proceso && (
                                    <FormularioProceso
                                    proceso={proceso}
                                    onSubmit={onSubmitUpdate}
                                    modulosPermisos={modulosPermisos}
                                    />
                                )}
                            </div>

                            <div
                                className="tab-pane fade"
                                id="v-pills-procesos"
                                role="tabpanel"
                                aria-labelledby="v-pills-procesos-tab"
                            >
                                <ExpedientesProceso
                                    id_proceso={id_proceso}
                                    modulosPermisos={modulosPermisos}
                                />
                            </div>

                            <div
                                className="tab-pane fade"
                                id="v-pills-ingresos"
                                role="tabpanel"
                                aria-labelledby="v-pills-ingresos-tab"
                            >
                                <PagosProceso
                                    id_proceso={id_proceso}
                                    modulosPermisos={modulosPermisos}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );

    } else {
        return(
            <div className="vh-75 d-flex justify-content-center align-items-center">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}