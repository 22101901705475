import React from "react"
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import $ from 'jquery';
import getEmpleados from "../../services/empleado/getEmpleados";
import getMetodosPago from "../../services/ingreso/getMetodosPago";
import getAllExpedientes from "../../services/expediente/getAllExpedientes";
import getUnidades from "../../services/unidades/getUnidades";
import getUnidadEmpleados from "../../services/unidades/getUnidadEmpleados";

export default function ModalAddSalidaProducto({onSubmitModal, producto}){

    const [expedientes, setExpedientes] = React.useState([]);
    const [unidades, setUnidades] = React.useState([]);
    const [empleados, setEmpleados] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const getMetodosPagoService = ()=>{
        setLoading(true)
        getMetodosPago({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setMetodosPago(_res.data)
                    setLoading(false)
                    $('.selectpicker').selectpicker('refresh');
                    // setDataFormulario()
                }
            })
    }

    const getUnidadesService = ()=>{
        getUnidades({values:{}})
            .then(res=>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setUnidades(_res.data)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    function getEmpleadosByUnidadService(id_unidad){
        let data = { id_unidad }
        getUnidadEmpleados({values:data})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setEmpleados(_res.data)
                    $("#id_empleado_responsable_unidad").attr("disabled",false)
                } else {
                    setEmpleados(_res.data)
                    $("#id_empleado_responsable_unidad").attr("disabled",false)
                }
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function getExpedientesService(){
        getAllExpedientes()
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                setExpedientes(_res.data)
                setLoading(false)
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function getEmpleadosService(){
        getEmpleados({values:{}})
            .then(res => {
                let _res = JSON.parse(res)
                // console.log(_res)
                if(_res.response === 'success'){
                    setEmpleados(_res.data)
                } 
                $('.selectpicker').selectpicker('refresh');
            })
    }

    function onChangeUnidad(value){
        getEmpleadosByUnidadService(value)
    }

    React.useEffect(getEmpleadosService, [])
    React.useEffect(getMetodosPagoService, []);
    React.useEffect(getExpedientesService, []);
    React.useEffect(getUnidadesService, []);

    React.useEffect(()=>{
        document.getElementById('fecha_salida').value = 
        new Date(
            new Date()
                .toLocaleString("en-US", {timeZone: "America/Mexico_City"})
                .split(',')[0])
        .toISOString()
        .split('T')[0]
    }, [producto])

    return(
        <div className="modal" id="modalAddSalidaProducto" tabIndex="-1" role="dialog" aria-labelledby="modalAddSalidaProductoLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <form onSubmit={onSubmitModal} className="modal-content" id="formAddSalidaProducto">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddSalidaProductoLabel">
                            Marcar salida de producto
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Al marcar una salida, es porque el producto se vendió.
                        </p>
                        <hr className="my-2" />
                        <div className="row mt-3">
                            <div className="col-4">
                                <label htmlFor="fecha_salida">
                                    Producto:
                                </label>
                                <p className="font-weight-bold">
                                    {producto.nombre}
                                </p>
                            </div>
                            <div className="col-4">
                                <label htmlFor="cantidad_salida">
                                    Cantidad salida:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="cantidad_salida"
                                    name="cantidad_salida"
                                    placeholder="1"
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <label htmlFor="precio_venta">
                                    Precio de venta:
                                </label>
                                <input 
                                    type="tel"
                                    className="input" 
                                    onKeyPress={ validateNoNumbers }
                                    id="precio_venta"
                                    name="precio_venta"
                                    placeholder="Total en $"
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <label htmlFor="folio">
                                    Paciente quien realiza el pago:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="folio"
                                    name="folio"
                                    data-live-search="true"
                                    required
                                >

                                    {
                                        expedientes.length > 0 && <option value="">Elige un folio</option>
                                    }

                                    {
                                        expedientes.length === 0 && <option value="">Cargando...</option>
                                    }
                                    {
                                        expedientes &&
                                        expedientes.map( (expediente) => (
                                            <option key={ expediente.folio } value={ expediente.folio }>{ expediente.folio } : { expediente.nombrePaciente.trim() }</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-4">
                                <label htmlFor="id_unidad">
                                    Unidad:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_unidad"
                                    name="id_unidad"
                                    data-live-search="true"
                                    onChange={(e) => onChangeUnidad(e.target.value)}
                                    required
                                >
                                    <option value="">Elige una unidad</option>
                                    {
                                        unidades &&
                                        unidades.map( (unidad) => (
                                            <option key={ unidad.id_unidad } value={ unidad.id_unidad }>{ unidad.nombre }</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-4">
                                <label htmlFor="id_empleado_responsable_unidad">
                                    Trabajador:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_empleado_responsable_unidad"
                                    name="id_empleado"
                                    required
                                >
                                    <option value="">Elige un trabajador</option>
                                    {
                                        empleados &&
                                        empleados.map( (empleado) => (
                                            <option key={ empleado.id_empleado } value={ empleado.id_empleado }>{ empleado.nombre }</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="numero_recibo">
                                    No. de recibo:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="numero_recibo"
                                    name="numero_recibo"
                                    placeholder="######"
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <label htmlFor="fecha_salida">
                                    Fecha de salida:
                                </label>
                                <input 
                                    type="date"
                                    className="input" 
                                    id="fecha_salida"
                                    name="fecha_salida"
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <label htmlFor="id_metodo_pago">
                                    Método de pago:
                                </label>
                                <br/>
                                <select 
                                    className="input selectpicker"
                                    id="id_metodo_pago"
                                    name="id_metodo_pago"
                                    required
                                >  
                                    <option value="">Elige un método de pago</option>
                                    {
                                        metodosPago && 
                                        metodosPago.map( metodo_pago => (
                                            <option key={ metodo_pago.id_metodo_pago } value={ metodo_pago.id_metodo_pago }>
                                                { metodo_pago.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    *Descripción detallada de la salida del producto:
                                </label>
                                <textarea 
                                    rows={5}
                                    cols={5}
                                    type="text"
                                    placeholder="Sé lo más detallado posible (Opcional)"
                                    className="input textarea"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primary">
                            Marcar salida
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}