import React from 'react';
import Swal from 'sweetalert2'
import deleteProcesoExpediente from '../../services/expediente/deleteProcesoExpediente';
import updateFinalizadoProcesoExpediente from '../../services/proceso_expediente/updateFinalizadoProcesoExpediente';
import updateNoFinalizadoProcesoExpediente from '../../services/proceso_expediente/updateNoFinalizadoProcesoExpediente';
// import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElementProcesoExpediente({ element, permisosUsuario, reload }) {

    let { 
        dientes,
        id_proceso, 
        id_proceso_expediente, 
        nombre, 
        nombre_unidad, 
        fecha_inicio_proceso, 
        precio_final, 
        proceso_finalizado,
        fecha_proceso_finalizado,
        activo 
    } = element;

    console.log({dientes})

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = 'Quieres eliminar el tratamiento?';
        let txt = 'Eliminar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_proceso_expediente', id_proceso_expediente)
                let objectValues = Object.fromEntries(values);
                deleteProcesoExpediente({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Eliminado correctamente',
                            '',
                            'success'
                        )
                        reload()
                    }
                    
                })
            }
        })
    }

    const onClickTerminarTratamiento = () => {
        let msg = 'Quieres marcar como terminado este tratamiento?';
        let txt = 'Ya no se le podrán asignar más pagos.';
        Swal.fire({
            title: msg,
            text: txt,
            showCancelButton: true,
            confirmButtonText: "Terminar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_proceso_expediente', id_proceso_expediente)
                let objectValues = Object.fromEntries(values);
                updateFinalizadoProcesoExpediente({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Finalizado correctamente',
                            '',
                            'success'
                        )
                        reload()
                    }
                    
                })
            }
        })
    }

    const onClickReabrirTratamiento = () => {
        let msg = 'Quieres marcar como NO terminado este tratamiento?';
        let txt = 'Se le podrán asignar más pagos.';
        Swal.fire({
            title: msg,
            text: txt,
            showCancelButton: true,
            confirmButtonText: "Si, marcar como no terminado",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_proceso_expediente', id_proceso_expediente)
                let objectValues = Object.fromEntries(values);
                updateNoFinalizadoProcesoExpediente({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Actualizado correctamente',
                            '',
                            'success'
                        )
                        reload()
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_proceso}>
            <th className='table-max-30'>
                <div className="table__name">
                    {
                    estadoEstatus === '1' && (permisosUsuario.expedientesTratamientos.eliminar === '1' || permisosUsuario.expedientesTratamientos.editar === '1') &&
                        <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {
                                    permisosUsuario.expedientesTratamientos.eliminar === '1' &&
                                        <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Eliminar</a>
                                }
                                {
                                    permisosUsuario.expedientesTratamientos.editar === '1' &&
                                    proceso_finalizado === '0' &&
                                        <a className="dropdown-item" onClick={() => onClickTerminarTratamiento()}>
                                            Terminar tratamiento
                                        </a>
                                }
                                {
                                    permisosUsuario.expedientesTratamientos.editar === '1' &&
                                    proceso_finalizado === '1' &&
                                        <a className="dropdown-item" onClick={() => onClickReabrirTratamiento()}>
                                            Marcar como no terminado
                                        </a>
                                }
                            </div>
                        </div>
                    }

                    <div className="table__nameDescription">
                        <p>{nombre}</p>
                        <small>{nombre_unidad}</small>
                    </div>
                </div>
            </th>

            <td>
                {dientes !== null && dientes.map(
                    (diente) => <p>{`${diente.numero}. ${diente.nombre}`}</p>
                )}
                {
                    dientes === null && <p>No aplica</p>
                }
            </td>

            <td>
                <p>{fecha_inicio_proceso}</p>
                {proceso_finalizado === '0' && <small className='color-rojo font-weight-bold'>No terminado</small>}
                {proceso_finalizado === '1' && <small className='color-verde font-weight-bold'>Terminado: {fecha_proceso_finalizado}</small>}
            </td>

            <td>
                {
                    (precio_final > 0) ? `$ ${precio_final}` : '-'
                }
            </td>
        </tr>
    )
    
}