import './Table.css'

export default function Table({ children }){
    return(
        <div className="table-responsive-gpt">
            <div className="table-scroll">
                <table className="table table-hover shadow">

                    { children }

                </table>
            </div>
        </div>
    );
}