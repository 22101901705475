import React from 'react';
import Swal from 'sweetalert2';
import deleteSalidaProductoUnidad from '../../services/inventario_productos/deleteSalidaProductoUnidad';

export default function TableElementSalidaProductoUnidad({ element, permisosUsuario, reload }) {

    let { 
        id_salida_producto_unidad,
        id_producto, 
        id_unidad, 
        nombre_empleado, 
        apellido_paterno_empleado,
        apellido_materno_empleado,
        nombre_producto, 
        nombre_unidad, 
        cantidad_salida, 
        fecha_salida, 
        descripcion
    } = element;

    const onClickDelete = () => {
        let msg = 'Seguro que quieres revertir la entrega de producto?';
        let txt = 'Si';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_salida_producto_unidad', id_salida_producto_unidad)
                let objectValues = Object.fromEntries(values);
                // console.log({objectValues})
                deleteSalidaProductoUnidad({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        reload()
                        Swal.fire({
                            timer: 1200,
                            title: 'Revertido',
                            showConfirmButton: false,
                            icon: 'success'
                        })
                    } else {
                        Swal.fire({
                            title: 'No se puede eliminar',
                            text: 'Ya existen registros que están vinculados a este tipo de egreso, para eliminarlo, primero elimina todos los registros al que está vinculado.',
                            icon: 'info'
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_salida_producto_unidad}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {
                                (permisosUsuario.inventarioMateriales.eliminar === '1') &&
                                (permisosUsuario.unidadesEgresos.eliminar === '1') &&
                                    <a href 
                                        className="dropdown-item bg-danger text-white" 
                                        onClick={() => onClickDelete()}
                                    >
                                        Revertir
                                    </a>
                            }
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{nombre_producto}</p>
                        {
                            descripcion !== null && 
                                <small className='text-muted'>
                                    Descripción: {`${descripcion}`}
                                </small>
                        }
                    </div>
                </div>
            </th>

            <td>
                <p>
                    {`${nombre_empleado} ${apellido_paterno_empleado !== null && apellido_paterno_empleado}`}
                </p>
                <small className='text-muted'>
                    Fecha entrega: {`${fecha_salida}`}
                </small>
            </td>

            <td>
                <p>{Math.floor(cantidad_salida)}</p>
            </td>

        </tr>
    )
    
}