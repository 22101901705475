import React from "react";
import './CardCorteSemanal.css'

export default function CardCorteSemanal(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');

    return(
        <div className='d-flex flex-wrap justify-content-evenly globalCardsCorteSemanal'>
            <div className='cardCorteSemanal cardCorteSemanal--no-border-left'>
                <small className='color-verde font-weight-bold'>Ingresos totales:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_ingresos !== undefined
                    ? dollarUSLocale.format(props.total_ingresos)
                    : 0}
                </h2>
            </div>
            <div className='cardCorteSemanal'>
                <small className='color-rojo font-weight-bold'>Gastos totales:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total_gastos !== undefined
                    ? dollarUSLocale.format(props.total_gastos)
                    : 0}
                </h2>
            </div>
            <div className='cardCorteSemanal'>
                <small className='font-weight-bold'>Total:</small>
                <h2 className="text-md-right">$&nbsp;
                {props.total !== undefined
                    ? dollarUSLocale.format(props.total)
                    : 0}
                </h2>
            </div>
        </div>

    )
}