import React from 'react'
import { BrowserRouter, Route, Switch, Redirect, Link, useHistory } from 'react-router-dom'
import Header from '../../components/Header'
import LateralMenu from '../../components/LateralMenu'
import Corte from '../Corte'
import Expedientes from '../Expedientes'
import AddExpediente from '../Expedientes/AddExpediente'
import ViewExpediente from '../Expedientes/ViewExpediente'
import AddIngreso from '../Ingresos/AddIngreso'
import InventarioMateriales from '../InventarioMateriales'
import AddInventarioMateriales from '../InventarioMateriales/AddInventarioMateriales'
import Procesos from '../Procesos'
import AddProceso from '../Procesos/AddProceso'
import ViewProceso from '../Procesos/ViewProceso'
import TipoGastos from '../TipoGastos'
import Trabajadores from '../Trabajadores'
import AddTrabajador from '../Trabajadores/AddTrabajador'
import ViewTrabajador from '../Trabajadores/ViewTrabajador'
import Unidades from '../Unidades'
import AddUnidad from '../Unidades/AddUnidad'
import ViewUnidad from '../Unidades/ViewUnidad'
import Materiales from '../Materiales'
import Usuarios from '../Usuarios'
import Logs from '../Logs'
import Productos from '../Productos'
import InventarioFarmacia from '../InventarioFarmacia'
import AddInventarioFarmacia from '../InventarioFarmacia/AddInventarioFarmacia'
import Laboratorios from '../Laboratorios'
import AddTrabajoLaboratorio from '../Laboratorios/AddTrabajoLaboratorio'
import PaginaPrincipal from '../PaginaPrincipal'
import Metas from '../Metas'

export default function HomePage(props) {

    const history = useHistory()

    // Obtenemos los parametros que nos pasaron de App.js
    let { setLogin, idUsuario, setIdUsuario, permisos, setPermisos } = props

    const handleLogout = () =>{
        setLogin(false)
        setIdUsuario(null)
        setPermisos(null)
        localStorage.removeItem('idUsuario');
        localStorage.removeItem('permisos');
        window.history.pushState({}, "", "/")
    }

    const getPermiso = ({modulo}) => {
        let _modulo = permisos.filter(
            permiso=>permiso.nombre_modulo === modulo)

        return (_modulo.length >= 1) ? _modulo[0] : {}
    }

    const modulosPermisos = {
        expedientes : getPermiso({modulo: "Expedientes"}),
        unidades : getPermiso({modulo: "Unidades"}),
        procesos : getPermiso({modulo: "Procesos de unidades"}),
        trabajadores : getPermiso({modulo: "Trabajadores"}),
        usuarios : getPermiso({modulo: "Usuarios"}),
        materiales : getPermiso({modulo: "Materiales"}),
        ingresos: getPermiso({modulo: "Ingresos"}),
        egresos: getPermiso({modulo: "Egresos"}),
        expedientesTratamientos: getPermiso({modulo: "Expedientes: tratamientos"}),
        unidadesEgresos: getPermiso({modulo: "Unidades: egresos"}),
        tipoEgresos: getPermiso({modulo: "Catálogo: egresos"}),
        inventarioMateriales: getPermiso({modulo: "Inventario: materiales"}),
        expedientesCitas: getPermiso({modulo: "Expedientes: citas"}),
        logs: getPermiso({modulo: "Logs: registros del sistema"}),
        catalogoProductos: getPermiso({modulo: "Catálogo: productos"}),
        farmacia: getPermiso({modulo: "Farmacia"}),
        laboratorios: getPermiso({modulo: "Laboratorios"}),
        metas: getPermiso({modulo: "Metas"}),
    }

    // console.log(modulosPermisos)

    // Estado para saber sí ha buscado algo o no globalmente
    // const [loadingSearch, setLoadingSearch] = React.useState(true);

    return (
        <BrowserRouter>

            <div id="body" className="body_move">
                <LateralMenu>

                    {
                        Object.keys(modulosPermisos.unidades).length > 0 && modulosPermisos.unidades.leer === '1' &&
                            <Link to={"/unidades"} className="option_enlace unidadesLink">
                                <div className="option">
                                    <i className="fa-solid fa-hospital" title="Unidades"></i>
                                    <p>Unidades</p>
                                </div>
                            </Link>
                    }

                    {
                        Object.keys(modulosPermisos.expedientes).length > 0 && modulosPermisos.expedientes.leer === '1' &&
                            <Link to={"/expedientes"} className="option_enlace expedientesLink">
                                <div className="option">
                                    <i className="fa-solid fa-file-medical" title="Expedientes"></i>
                                    <p>Expedientes</p>
                                </div>
                            </Link>
                    }

                    {
                        Object.keys(modulosPermisos.metas).length > 0 && modulosPermisos.metas.leer === '1' &&
                            <Link to={"/metas"} className="option_enlace metasLink">
                                <div className="option">
                                    <i className="fa-solid fa-chart-line" title="Metas"></i>
                                    <p>Metas</p>
                                </div>
                            </Link>
                    }

                    {
                        Object.keys(modulosPermisos.procesos).length > 0 && modulosPermisos.procesos.leer === '1' &&
                            <Link to={"/procesos"} className="option_enlace procesosLink">
                                <div className="option">
                                    <i className="fa-solid fa-book-medical" title="Procesos"></i>
                                    <p>Procesos</p>
                                </div>
                            </Link>
                    }

                    {
                        Object.keys(modulosPermisos.trabajadores).length > 0 && modulosPermisos.trabajadores.leer === '1' &&
                            <Link to={"/trabajadores"} className="option_enlace trabajadoresLink">
                                <div className="option">
                                    <i className="fa-solid fa-user-doctor" title="Trabajadores"></i>
                                    <p>Trabajadores</p>
                                </div>
                            </Link>
                    }

                    {
                        Object.keys(modulosPermisos.usuarios).length > 0 && modulosPermisos.usuarios.leer === '1' &&

                            <Link to={"/usuarios"} className="option_enlace usuariosLink">
                                <div className="option">
                                    <i className="fa-solid fa-users" title="Usuarios"></i>
                                    <p>Usuarios</p>
                                </div>
                            </Link>
                    }

                    {
                        Object.keys(modulosPermisos.farmacia).length > 0 && modulosPermisos.farmacia.leer === '1' &&
                            <Link to={"/farmacia"} className="option_enlace farmaciaLink">
                                <div className="option">
                                    <i className="fa-solid fa-pills" title="Farmacia"></i>
                                    <p>Farmacia</p>
                                </div>
                            </Link>
                    }
                    
                    {
                        Object.keys(modulosPermisos.inventarioMateriales).length > 0 && modulosPermisos.inventarioMateriales.leer === '1' &&
                            <Link to={"/inventario"} className="option_enlace inventarioLink">
                                <div className="option">
                                    <i className="fa-solid fa-boxes" title="Inventario de Materiales"></i>
                                    <p>Inventario Materiales</p>
                                </div>
                            </Link>
                    }
                    
                    {
                        Object.keys(modulosPermisos.laboratorios).length > 0 && modulosPermisos.laboratorios.leer === '1' &&
                            <Link to={"/laboratorios"} className="option_enlace laboratoriosLink">
                                <div className="option">
                                    <i className="fa-solid fa-teeth" title="Trabajos de laboratorio"></i>
                                    <p>Trabajos de laboratorio</p>
                                </div>
                            </Link>
                    }

                    {
                        Object.keys(modulosPermisos.materiales).length > 0 && modulosPermisos.materiales.leer === '1' &&

                            <Link to={"/materiales"} className="option_enlace materialesLink">
                                <div className="option">
                                    <i className="fa-solid fa-box" title="Catálogo: Materiales"></i>
                                    <p>Catálogo: Materiales</p>
                                </div>
                            </Link>
                    }

                    {
                        Object.keys(modulosPermisos.catalogoProductos).length > 0 && modulosPermisos.catalogoProductos.leer === '1' &&

                            <Link to={"/productos"} className="option_enlace productosLink">
                                <div className="option">
                                    <i className="fa-solid fa-shopping-bag" title="Catálogo: Productos"></i>
                                    <p>Catálogo: Productos</p>
                                </div>
                            </Link>
                    }
                    
                    {
                        Object.keys(modulosPermisos.tipoEgresos).length > 0 && modulosPermisos.tipoEgresos.leer === '1' &&

                            <Link to={"/tipo-egresos"} className="option_enlace tipo-egresosLink">
                                <div className="option">
                                    <i className="fa-solid fa-file-invoice" title="Tipo de egresos"></i>
                                    <p>Catálogo: Egresos</p>
                                </div>
                            </Link>
                    }
                    
                    {
                        ( Object.keys(modulosPermisos.ingresos).length > 0 && Object.keys(modulosPermisos.egresos).length > 0 ) &&
                        ( modulosPermisos.ingresos.leer === '1' && modulosPermisos.egresos.leer === '1'  ) &&

                            <Link to={"/corte"} className="option_enlace corteLink">
                                <div className="option">
                                    <i className="fa-solid fa-money-bill-transfer" title="Corte"></i>
                                    <p>Corte</p>
                                </div>
                            </Link>
                    }
                    
                    {
                        Object.keys(modulosPermisos.logs).length > 0 && modulosPermisos.logs.leer === '1' &&

                            <Link to={"/logs"} className="option_enlace logsLink">
                                <div className="option">
                                    <i className="fa-solid fa-history" title="Logs"></i>
                                    <p>Registros del sistema</p>
                                </div>
                            </Link>
                    }
                    {/* <Link to={"/ajustes"} className="option_enlace ajustesLink">
                        <div className="option">
                            <i className="fa-solid fa-gear" title="Ajustes"></i>
                            <p>Ajustes</p>
                        </div>
                    </Link> */}
                </LateralMenu>

                <Header handleLogout={handleLogout} />

                <Switch>
                    <Route path="/" exact>
                        <PaginaPrincipal />
                    </Route>
                    
                    <Route path="/unidades" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.unidades).length > 0 && modulosPermisos.unidades.leer === '1' ) ?
                                <Unidades modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/unidades/agregar" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.unidades).length > 0 && modulosPermisos.unidades.escribir === '1' ) ?
                                <AddUnidad modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/unidades/:id_unidad" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.unidades).length > 0 && modulosPermisos.unidades.leer === '1' ) ?
                                <ViewUnidad modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/expedientes" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.expedientes).length > 0 && modulosPermisos.expedientes.leer === '1' ) ?
                                <Expedientes modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/expedientes/agregar" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.expedientes).length > 0 && modulosPermisos.expedientes.escribir === '1' ) ?
                                <AddExpediente modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/expedientes/:folio" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.expedientes).length > 0 && modulosPermisos.expedientes.leer === '1' ) ?
                                <ViewExpediente modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    {
                        // Metas
                    }

                    <Route path="/metas" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.metas).length > 0 && modulosPermisos.metas.leer === '1' ) ?
                                <Metas modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                    } } />

                    <Route path="/procesos" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.procesos).length > 0 && modulosPermisos.procesos.leer === '1' ) ?
                                <Procesos modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/procesos/agregar" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.procesos).length > 0 && modulosPermisos.procesos.escribir === '1' ) ?
                                <AddProceso modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/procesos/:id_proceso" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.procesos).length > 0 && modulosPermisos.procesos.leer === '1' ) ?
                                <ViewProceso modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/trabajadores" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.trabajadores).length > 0 && modulosPermisos.trabajadores.leer === '1' ) ?
                                <Trabajadores modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/trabajadores/agregar" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.trabajadores).length > 0 && modulosPermisos.trabajadores.escribir === '1' ) ?
                                <AddTrabajador modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/trabajadores/:id_trabajador" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.trabajadores).length > 0 && modulosPermisos.trabajadores.leer === '1' ) ?
                                <ViewTrabajador modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/corte" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.ingresos).length > 0 && Object.keys(modulosPermisos.egresos).length > 0 ) &&
                            ( modulosPermisos.ingresos.leer === '1' && modulosPermisos.egresos.leer === '1'  ) ?
                                <Corte modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/ingresos/agregar" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.ingresos).length > 0 && modulosPermisos.ingresos.escribir === '1' ) ?
                                <AddIngreso modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />
                   
                    <Route path="/inventario/agregar" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.inventarioMateriales).length > 0 && modulosPermisos.inventarioMateriales.escribir === '1' ) ?
                                <AddInventarioMateriales modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />
                   
                    <Route path="/inventario" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.inventarioMateriales).length > 0 && modulosPermisos.inventarioMateriales.leer === '1' ) ?
                                <InventarioMateriales modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />
                   
                    <Route path="/tipo-egresos" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.tipoEgresos).length > 0 && modulosPermisos.tipoEgresos.escribir === '1' ) ?
                                <TipoGastos modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />
                   
                   <Route path="/materiales" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.materiales).length > 0 && modulosPermisos.materiales.escribir === '1' ) ?
                                <Materiales modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />
                   
                    <Route path="/usuarios" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.usuarios).length > 0 && modulosPermisos.usuarios.escribir === '1' ) ?
                                <Usuarios modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />
                   
                    <Route path="/logs" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.logs).length > 0 && modulosPermisos.logs.leer === '1' ) ?
                                <Logs modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/productos" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.catalogoProductos).length > 0 && modulosPermisos.catalogoProductos.escribir === '1' ) ?
                                <Productos modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/farmacia" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.farmacia).length > 0 && modulosPermisos.farmacia.leer === '1' ) ?
                                <InventarioFarmacia modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/farmacia/agregar" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.farmacia).length > 0 && modulosPermisos.farmacia.escribir === '1' ) ?
                                <AddInventarioFarmacia modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/laboratorios" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.laboratorios).length > 0 && modulosPermisos.laboratorios.leer === '1' ) ?
                                <Laboratorios modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    <Route path="/laboratorios/agregar" exact
                        render={ ()=>{
                            return ( Object.keys(modulosPermisos.laboratorios).length > 0 && modulosPermisos.laboratorios.escribir === '1' ) ?
                                <AddTrabajoLaboratorio modulosPermisos={modulosPermisos} />
                                :
                                <Redirect to="/"/>
                            
                    } } />

                    {/* <Route path="/ajustes" exact> 
                        <>Ajustes del sistema</>
                    </Route> */}

                    <Redirect to="/"/>
                </Switch>
            </div>

        </BrowserRouter>
    );
}