import React from 'react'
import './LoadingIndicator.css'

export default function LoadingIndicator(props){
    return(
        <div className={`d-flex justify-content-center align-items-center vh-90 flex-column mt-5 ${props.show ? '' : 'hidden'}`}>
            <div className="loadingSearch"></div>
            <p>
                Cargando...
            </p>
        </div>
    )
}