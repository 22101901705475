import React from 'react';
import Swal from 'sweetalert2';
import isProbadoEnPaciente from '../../services/orden_trabajo/isProbadoEnPaciente';

export default function TableElementDashboardOrdenTrabajo({ element, reload }) {

    let {
        id_orden_trabajo,
        descripcion,
        entregado,
        etapa,
        nombre,
        apellido_paterno,
        apellido_materno,
        es_terminado,
        nombre_trabajo_laboratorio,
        fecha_alta,
        costo_total,
        fecha_entrega,
        fecha_que_entrego,
        fecha_recepcion,
        id_estado_orden_trabajo,
        nombre_estado_orden_trabajo,
        total_pagado
    } = element;

    const getClassBackgroundByEstatus = (estatus) => {
        switch (estatus) {
            case '1':
                return 'table__bg-azul';
            case '2':
                return 'table__bg-naranja';
            case '3':
                return 'table__bg-rojo';
            case '4':
                return 'table__bg-verde';
            default:
                return '';
        }
    }

    const onClickTrabajoProbadoEnPaciente = () => {
        Swal.fire({
            title: "Confirmar prueba en paciente",
            text: "Esto indicará que haz probado el trabajo en el paciente y está listo para ser enviado de nuevo al laboratorio",
            showCancelButton: true,
            confirmButtonText: "Si, he probado el trabajo en el paciente",
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#34802f",
            cancelButtonColor: "#6c757d",
            icon: "info"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Procesando',
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false
                })

                // Obtenemos los detalles de la orden de trabajo
                let data = { id_orden_trabajo }
                isProbadoEnPaciente({ values: data })
                    .then(response => {
                        console.log(response)
                        let _respuesta = JSON.parse(response);
                        if (_respuesta.response === "success") {
                            reload()
                            Swal.fire(
                                'Estatus actualizado',
                                '',
                                'success'
                            )
                        }

                    })
                    .catch(err => {
                        console.log(err)
                        Swal.fire(
                            'Error',
                            'No se pudo actualizar el estatus',
                            'error'
                        )
                    })
            }
        });
    }

    return (
        <tr key={id_orden_trabajo} className={`${getClassBackgroundByEstatus(id_estado_orden_trabajo)}`}>
            <th>
                <div className="table__name">
                    {/* <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {
                                <button 
                                    className="dropdown-item"
                                >
                                    Ver detalles
                                </button>
                            }
                            {
                                <button
                                    className="dropdown-item bg-success text-white"
                                >
                                    He recibido el trabajo
                                </button>
                            }
                            {
                                <button
                                    className="dropdown-item bg-danger text-white"
                                >
                                    Enviar al laboratorio de nuevo
                                </button>
                            }
                        </div>
                    </div> */}

                    {
                        id_estado_orden_trabajo === '2' && 
                            <button 
                                className='btn btn-sm btn-verde mr-3'
                                onClick={onClickTrabajoProbadoEnPaciente}
                            >
                                <i className="fas fa-check"></i>
                            </button>
                    }
                    <div>
                        <p>{nombre} {apellido_paterno} {apellido_materno}</p>
                        <small className='text-muted'>
                            {nombre_trabajo_laboratorio}
                        </small>
                    </div>
                </div>
            </th>

            <td className='table-max-20'>
                {
                    es_terminado === '1' &&
                    <div className="table__estatus">
                        <span>
                            Para finalizar
                        </span>
                    </div>
                }
                <p className='my-2'>{etapa}</p>
                <small className="font-weight-bold">
                    Total: {parseFloat(costo_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </small>
                <br />
                <small className="font-weight-bold">
                    Pagado: {parseFloat(total_pagado).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </small>
            </td>

            <td className='table-null-no-wrap'>
                {nombre_estado_orden_trabajo}
                {
                    id_estado_orden_trabajo === "1" &&
                    <>
                        <br />
                        <small>Próxima entrega: {fecha_entrega}</small>
                    </>
                }
                {
                    id_estado_orden_trabajo === "2" &&
                    <>
                        <br />
                        <small>Lo entregó el: {fecha_que_entrego}</small>
                    </>
                }
            </td>

        </tr>
    )

}