import React from "react";
import "./LateralMenu.css";
import logo from "./../../asset/images/logo_divum.png";
import { useLocation, Link } from 'react-router-dom';
import $ from 'jquery/dist/jquery.min.js';

function LateralMenu({children}){

    const location = useLocation();
    const rutaActual = location.pathname.split('/')[1];

    $(document).ready(function(){
        
        // LIMPIAMOS EL INPUT DE BUSQUEDA POR SI LAS DUDAS
        if(rutaActual !== "resultados"){
            $(".search").val("");
        }
        
        $(".option_enlace").removeClass("selected");

        $(".option_enlace").click(function(){
            $(this).addClass("selected");
        });
        $("."+rutaActual+"Link").addClass("selected")
        
    })

    return(
        <nav className="menu__side menu__side_move" id="menu_side">

            <div className="name__page">
                <img src={ logo } alt="Logo de la empresa" />
                {/* <h4>Autobuses Urbanos de Ecatepec</h4> */}
            </div>

            <hr className='my-1'></hr>

            <div className="options__menu">

                <Link to={"/"} className="option_enlace Link">
                    <div className="option">
                        <i className="fas fa-home" title="Dashboard"></i>
                        <p>Dashboard</p>
                    </div>
                </Link>
                
                { children }

            </div>
        </nav>
    )
}

export default LateralMenu;