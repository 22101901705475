import React from 'react'
import './style.css'
import { useHistory } from 'react-router'

export default function CardEmpleado(props) {
    let dollarUSLocale = Intl.NumberFormat('en-US')
    let history = useHistory()

    const {
        foto_perfil,
        nombre,
        total_pacientes_atendidos,
        ingresos,
        egresos,
        meta,
        comision,
        id_empleado,
        egreso_prestamos,
        egreso_laboratorios,
        nombre_unidad,
    } = props

    const onClick = () => {
        history.push(`/trabajadores/${id_empleado}`)
    }

    return (
        <div className="cardEmpleado shadow" onClick={onClick}>
            <div className="d-flex align-items-center">
                {foto_perfil !== null && foto_perfil !== undefined && (
                    <img src={foto_perfil} className="img-fluid" alt={nombre} />
                )}
                {(foto_perfil === null || foto_perfil === undefined) && <div className="no_foto_perfil">?</div>}
                <div>
                    <h2 className="ml-2">{nombre}</h2>
                    {nombre_unidad !== undefined && <p className="text-muted ml-2">{nombre_unidad}</p>}
                </div>
            </div>

            <hr className="my-3" />

            <div className="row">
                <div className="col-12 col-sm-6 col-lg-4">
                    <p>Total ingresos:</p>
                    <p className="mb-3 font-weight-bold">
                        ${ingresos !== undefined ? dollarUSLocale.format(ingresos) : 0}
                    </p>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <p>Total egresos:</p>
                    <p className="mb-3 font-weight-bold">
                        ${egresos !== undefined ? dollarUSLocale.format(egresos) : 0}
                    </p>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <p>Préstamos:</p>
                    <p className="mb-3 font-weight-bold">
                        ${egreso_prestamos !== undefined ? dollarUSLocale.format(egreso_prestamos) : 0}
                    </p>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <p>Pagos a laboratorio:</p>
                    <p className="mb-3 font-weight-bold">
                        ${egreso_laboratorios !== undefined ? dollarUSLocale.format(egreso_laboratorios) : 0}
                    </p>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <p className="cardEmpleado__cantidad">Px atendidos:</p>
                    <p className="mb-3 font-weight-bold">
                        {total_pacientes_atendidos !== undefined ? total_pacientes_atendidos : 0}
                    </p>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <p>Meta (ingresos - egresos):</p>
                    <p className="mb-3 font-weight-bold">${meta !== undefined ? dollarUSLocale.format(meta) : 0}</p>
                </div>
                {
                    // Se muestra la comision si el nombre contiene "Isela"
                    nombre.includes('Isela') && (
                        <div className="col-12 col-sm-6 col-lg-4">
                            <p>Comisión (30%):</p>
                            <p className="mb-3 font-weight-bold">
                                ${comision !== undefined ? dollarUSLocale.format(comision) : 0}
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
