import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';

export default function LogViewer(props){
    const { log } = props
    return (
        <div>
            {/* <h3>{log.accion} en {log.nombre_tabla} (ID: {log.id_tabla})</h3>
            <p>{log.fecha_alta}</p> */}
            {log.accion === 'update' && (
                <ReactDiffViewer
                    newValue={(JSON.stringify(JSON.parse(log.valor_actual), null, 2).replace(/\\n/g, '\n'))}
                    oldValue={(JSON.stringify(JSON.parse(log.valor_anterior), null, 2).replace(/\\n/g, '\n'))}
                    hideLineNumbers={true}
                    // showDiffOnly={true}
                    leftTitle="Antes de actualizar:"
                    rightTitle="Actualizado:"
                    // splitView={false}
                    // disableWordDiff={true}
                />
            )}
            {log.accion === 'create' && (
                <ReactDiffViewer
                    newValue={(JSON.stringify(JSON.parse(log.valor_actual), null, 2).replace(/\\n/g, '\n'))}
                    hideLineNumbers={true}
                    // showDiffOnly={true}
                    leftTitle="Se creó:"
                    splitView={false}
                />
            )}
            {log.accion === 'delete' && (
                <ReactDiffViewer
                    oldValue={(JSON.stringify(JSON.parse(log.valor_anterior), null, 2).replace(/\\n/g, '\n'))}
                    hideLineNumbers={true}
                    // showDiffOnly={true}
                    leftTitle="Se eliminó:"
                    splitView={false}
                />
            )}
        </div>
    );
};