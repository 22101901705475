import React from "react";
import { useHistory } from "react-router";
import getUnidadEmpleados from "../../services/unidades/getUnidadEmpleados";
import AddButton from "../AddButton";
import LoadingIndicator from "../LoadingIndicator";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import $ from 'jquery'
import ModalAddEmpleadoUnidad from "../ModalAddEmpleadoUnidad";
import addEmpleadoUnidad from "../../services/unidades/addEmpleadoUnidad";
import Swal from "sweetalert2";

export default function UnidadEmpleados(props){

    let { modulosPermisos } = props

    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const history = useHistory()

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Fecha alta" },
        { id: 3, name: "Estatus" }
    ];

    function getUnidadEmpleadosService(){
        setLoading(true)
        let data = { id_unidad: props.id_unidad }
        getUnidadEmpleados({values: data}).then(res => {
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setElements(_res.data);
            }
            setLoading(false)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }
    const onSubmitModal = (e)=>{
        e.preventDefault()
        const formData = new FormData(e.target);
        formData.append("id_unidad",props.id_unidad);
        let objectValues = Object.fromEntries(formData);
        addEmpleadoUnidad({values:objectValues})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'existe'){
                    Swal.fire({
                        title:'El empleado ya está asignado',
                        timer:1500,
                        showConfirmButton:false,
                        icon:'info'
                    })
                } else {
                    if(_res.response === 'success'){
                        Swal.fire({
                            title:'Asignado correctamente',
                            timer:1200,
                            showConfirmButton:false,
                            icon:'success'
                        })
                        $("#modalAddEmpleadoUnidad").modal("hide")
                        getUnidadEmpleadosService()
                    }
                }


            })
    }

    React.useEffect(getUnidadEmpleadosService,[]);

    return(
        <div>

            <ModalAddEmpleadoUnidad 
                id_unidad={props.id_unidad}
                onSubmitModal={onSubmitModal}
            />

            <div className="row mb-4">
                <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-8 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        <h4>
                            Trabajadores de esta unidad
                        </h4>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-4 col-md-4 col-sm-4 col-4 d-flex align-items-center justify-content-end">
                    {
                        modulosPermisos.unidades.escribir === '1' &&
                            <AddButton text="Asignar empleado" onClick={ async () => {
                                $('#modalAddEmpleadoUnidad').modal('show')
                            } } />
                    }
                </div>
            </div>

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody elements={elements} typeElement="trabajador_unidad" permisosUsuario={modulosPermisos} />
                    </Table>
            }

        </div>
    )
}