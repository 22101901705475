import React from "react";
import { useParams } from "react-router";
import FormularioUnidad from "../../components/FormularioUnidad";
import Swal from "sweetalert2";
import Icon from "../../components/Icon";
import getUnidad from "../../services/unidades/getUnidad";
import updateUnidad from "../../services/unidades/updateUnidad";
import updateEstatusUnidad from "../../services/unidades/updateEstatusUnidad";
import UnidadEmpleados from "../../components/UnidadEmpleados";
import UnidadIngresos from "../../components/UnidadIngresos";
import UnidadEgresos from "../../components/UnidadEgresos";

export default function ViewUnidad(props){

    let { id_unidad } = useParams()
    let { modulosPermisos } = props

    const [unidad, setUnidad] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const getUnidadService = () => {
        let data = {
            id_unidad: id_unidad
        }
        getUnidad({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                console.log(_response)
                if(_response.response === 'success'){
                    setUnidad(_response.unidad);
                    setLoading(false)
                }
            })
            .catch(error => {
                // console.log(`Ha petado getUnidad(): ${error}`)
            })
    }

    const onSubmitUpdate = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_unidad', id_unidad);
        let objectValues = Object.fromEntries(values);
        // // console.log({objectValues})
        updateUnidad({values:objectValues})
            .then(response => {
                // // console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                    getUnidadService();
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar la unidad?' : 'Quieres deshabilitar la unidad?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_unidad', id_unidad)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                updateEstatusUnidad({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getUnidadService();
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    React.useEffect(() => {
        getUnidadService()
    } , []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btn btn-gris btn-sm mb-2"
                />

                <hr />

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4">
                        <h2>{unidad.nombre}</h2>

                        <div className="d-flex flex-column">
                            <p className="my-2">
                                <small>Fecha alta: {unidad.fecha_alta}</small>
                            </p>
                            <span className={`flag ${unidad.activo === '1' ? '' : 'flag-inactive'}`}>
                                {unidad.activo === '1' ? 'Activo' : 'Inactivo'}
                            </span>
                        </div>

                        <hr />

                        <div className="my-2">
                            <ul className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <li className="nav-item">
                                <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                    <Icon icon="fas fa-hospital mr-2" />
                                    Datos generales
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="v-pills-empleados-tab" data-toggle="pill" href="#v-pills-empleados" role="tab" aria-controls="v-pills-empleados" aria-selected="true">
                                    <Icon icon="fas fa-user mr-2" />
                                    Trabajadores
                                </a>
                            </li>
                            {modulosPermisos.ingresos.leer === '1' && (
                                <li className="nav-item">
                                    <a className="nav-link" id="v-pills-ingresos-tab" data-toggle="pill" href="#v-pills-ingresos" role="tab" aria-controls="v-pills-ingresos" aria-selected="true">
                                        <Icon icon="fas fa-money-bill-transfer mr-2" />
                                        Ingresos
                                    </a>
                                </li>
                            )}
                            {modulosPermisos.unidadesEgresos.leer === '1' && (
                                <li className="nav-item">
                                    <a className="nav-link" id="v-pills-egresos-tab" data-toggle="pill" href="#v-pills-egresos" role="tab" aria-controls="v-pills-egresos" aria-selected="true">
                                        <Icon icon="fas fa-money-bill-transfer mr-2" />
                                        Egresos
                                    </a>
                                </li>
                            )}
                            {modulosPermisos.unidades.editar === '1' && (
                                <li className="nav-item">
                                    {unidad.activo === '1' ? (
                                        <button className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(false)}>
                                        <Icon icon="fa-solid fa-circle-minus mr-2" />
                                        Deshabilitar
                                        </button>
                                    ) : (
                                        <button className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(true)}>
                                        <Icon icon="fa-solid fa-circle-plus mr-2" />
                                        Habilitar
                                        </button>
                                    )}
                                </li>
                            )}
                            </ul>
                        </div>
                    </div>

                    <div className="col-xl-9 col-lg-9 col-md-8 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            {unidad && (
                                <FormularioUnidad
                                unidad={unidad}
                                onSubmit={onSubmitUpdate}
                                modulosPermisos={modulosPermisos}
                                />
                            )}
                            </div>

                            <div className="tab-pane fade" id="v-pills-empleados" role="tabpanel" aria-labelledby="v-pills-empleados-tab">
                                <UnidadEmpleados
                                    id_unidad={id_unidad}
                                    modulosPermisos={modulosPermisos}
                                />
                            </div>

                            <div className="tab-pane fade" id="v-pills-ingresos" role="tabpanel" aria-labelledby="v-pills-ingresos-tab">
                            {modulosPermisos.ingresos.leer === '1' && (
                                <UnidadIngresos
                                    id_unidad={id_unidad}
                                    id_empleado_responsable_unidad={unidad.id_empleado}
                                    modulosPermisos={modulosPermisos}
                                />
                            )}
                            </div>

                            <div className="tab-pane fade" id="v-pills-egresos" role="tabpanel" aria-labelledby="v-pills-egresos-tab">
                            {modulosPermisos.unidadesEgresos.leer === '1' && (
                                <UnidadEgresos
                                    id_unidad={id_unidad}
                                    modulosPermisos={modulosPermisos}
                                />
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="vh-75 d-flex justify-content-center align-items-center">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}