import React from 'react';
import Swal from 'sweetalert2'
import $ from 'jquery';
import updateEstatusMaterial from '../../services/materiales/updateEstatusMaterial';
import deleteMaterial from '../../services/materiales/deleteMaterial';

export default function TableElementMaterial({ element, permisosUsuario, reload, setElementToEdit }) {

    let { id_material, nombre, descripcion, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres activar el material?' : 'Quieres desactivar el material?';
        let txt = (activo) ? 'Activar' : 'Desactivar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_material', id_material)
                values.append('activo', activo ? '1' : '0')
                let objectValues = Object.fromEntries(values);
                // console.log({objectValues})
                updateEstatusMaterial({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo ? '1' : '0')
                        Swal.fire({
                            timer: 1200,
                            title: 'Estatus actualizado',
                            showConfirmButton: false,
                            icon: 'success'
                        })
                    }
                    
                })
            }
        })
    }

    const onUpdateElement = ()=>{
        // setElementToEdit(null)
        setElementToEdit(element)
        $("#modalUpdateMaterial").modal('show')
    }

    const onClickDelete = () => {
        let msg = 'Seguro que quieres eliminarlo?';
        let txt = 'Si';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_material', id_material)
                let objectValues = Object.fromEntries(values);
                // console.log({objectValues})
                deleteMaterial({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        reload()
                        Swal.fire({
                            timer: 1200,
                            title: 'Eliminado',
                            showConfirmButton: false,
                            icon: 'success'
                        })
                    } else {
                        Swal.fire({
                            title: 'No se puede eliminar',
                            text: 'Ya existen registros que están vinculados a este material, para eliminarlo, primero elimina todos los registros al que está vinculado.',
                            icon: 'info'
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_material}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* <Link to={`/procesos/${id_material}`} className="dropdown-item" >Ver</Link> */}
                            {
                                (permisosUsuario.tipoEgresos.editar === '1') &&
                                    (estadoEstatus === '1' ?
                                    <a className="dropdown-item text-black" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                    :
                                    <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            }

                            {
                                (permisosUsuario.tipoEgresos.editar === '1') &&
                                    <a className="dropdown-item text-black" onClick={() => onUpdateElement()}>Editar</a>
                            }

                            {
                                (permisosUsuario.tipoEgresos.eliminar === '1') &&
                                    <a className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>Eliminar</a>
                            }
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{nombre}</p>
                        <small>id material: {id_material}</small>
                    </div>
                </div>
            </th>

            <td>
                <p>{descripcion ? descripcion : '-'}</p>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus === '1' ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}