import React from 'react';
import "./ToggleButton.css"

export default function ToggleButton(props){
    const {
        id, name
    } = props
    return(
        <div className="toggle-button">
            <input 
                type="checkbox" 
                id={id} 
                name={name} 
            />
            <label 
                htmlFor={id}
            >
                Toggle
            </label>
        </div>
    )
}