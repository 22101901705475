import React from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import FormularioProceso from "../../components/FormularioProceso";
import addProceso from "../../services/proceso/addProceso";

export default function AddProceso(props){

    let { modulosPermisos } = props

    const history = useHistory()

    const onSubmitProceso = (e)=>{
        e.preventDefault()

        let formData = new FormData(e.target)
        let objectValues = Object.fromEntries(formData)
        addProceso({values:objectValues})
            .then(res =>{
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    Swal.fire({
                        title:"Proceso agregado exitosamente",
                        timer:1500,
                        showConfirmButton:false,
                        icon:'success'
                    }).then(res=> history.push('/procesos'))
                    
                }
            })
        // console.log(objectValues)
    }

    return(
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btn btn-gris noMargin"
            />

            <h2 className="my-4">Agregar un proceso</h2>

            <p>
                Para agregar un nuevo proceso, tienes que completar el siguiente formulario:
            </p>

            <hr className='my-3' />

            <FormularioProceso
                onSubmit={onSubmitProceso}
                modulosPermisos={modulosPermisos}
            />


        </section>
    )
}