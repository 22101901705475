import React from 'react'
import AddButton from '../../components/AddButton';
import CardCorteSemanal from '../../components/CardCorteSemanal'
import LoadingIndicator from '../../components/LoadingIndicator'
import Table from '../../components/Table';
import TableBody from '../../components/Table/TableBody';
import TableHead from '../../components/Table/TableHead';
import getIngresos from '../../services/ingreso/getIngresos';
import getIngresosTotales from '../../services/ingreso/getIngresosTotales';
import { useHistory } from 'react-router';
import './Corte.css'
import AlertWarningSearch from '../../components/AlertWarningSearch';
import $ from 'jquery'
import CardCorteSemanalEfectivo from '../../components/CardCorteSemanalEfectivo';
import CardCorteSemanalBanco from '../../components/CardCorteSemanalBanco';

export default function Corte({permisosUsuario}){

    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    
    const history = useHistory()
    const [loading, setLoading] = React.useState(true);
    const [ingresos, setIngresos] = React.useState([]);
    const [ingresosTotales, setIngresosTotales] = React.useState({})
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    const rowsIngresos = [
        { id: 1, name: "No. de recibo" },
        { id: 2, name: "Unidad" },
        { id: 3, name: "Proceso" },
        { id: 4, name: "Cantidad" },
        { id: 5, name: "Forma de pago" }
    ];

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    function getIngresosTotalesService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
        }
        // // console.log(firstday, lastday)
        getIngresosTotales({values:data}).then(res => {
            // console.log(res)
            let _res = JSON.parse(res);
            console.log(_res)
            if(_res.response === "success"){
                setIngresosTotales(_res.data);
            }
            setLoading(false)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }

    function getIngresosService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
        }

        // // console.log(firstday, lastday)
        getIngresos({values:data}).then(res => {
            // console.log(res)
            let _res = JSON.parse(res);
            // console.log(_res)
            if(_res.response === "success"){
                setIngresos(_res.data);
            }
            setLoading(false)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }

    React.useEffect(() => {
        getIngresosService()
        getIngresosTotalesService()
        // console.log(fechaInicio, fechaFinal)
        $('#fecha_inicial').val(fechaInicio)
    },[fechaInicio, fechaFinal]);

    return(
        <section>

            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        <h1>Corte semanal</h1>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-start justify-content-sm-start mt-md-4 mt-sm-4 mt-4">
                    <form onSubmit={onSubmitFechas} className="">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                            <p className="mx-2">y</p>
                            <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                            <button className='btn btn btn-gris ml-2'><i className="fa-solid fa-search"></i></button>
                        </div>
                    </form>
                </div>
            </div>

            <CardCorteSemanalEfectivo 
                total_ingresos_efectivo={ingresosTotales.total_ingresos_efectivo}
                total_gastos_efectivo={ingresosTotales.total_gastos_efectivo}
                total_efectivo_caja={ingresosTotales.total_efectivo_caja}
            />
            
            <CardCorteSemanalBanco 
                total_ingresos_banco={ingresosTotales.total_ingresos_banco}
                total_gastos_banco={ingresosTotales.total_gastos_banco}
                total_banco={ingresosTotales.total_banco}
            />

            <CardCorteSemanal 
                total_ingresos={ingresosTotales.total_ingresos}
                total_gastos={ingresosTotales.total_gastos}
                total={ingresosTotales.total}
            />

            <div className="d-flex align-items-center my-5">    
                <h2 className="">Lista de ingresos</h2>

                <AddButton text="Agregar ingreso" onClick={ async () => {
                    history.push("/ingresos/agregar")
                } } />
            </div>

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(ingresos).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rowsIngresos} />

                        <TableBody elements={ingresos} typeElement="ingresos" permisosUsuario={permisosUsuario} />
                    </Table>
            }

            {
                ( Object.keys(ingresos).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

        </section>
    )
}