import Swal from "sweetalert2";

export default function AlertError({titulo, mensaje}){
    let data = {
        icon: 'error',
        title: titulo,
        showConfirmButton: false,
        timer: 1300
    }

    if(mensaje)
        data.text = mensaje

    Swal.fire(data)
}