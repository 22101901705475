import $ from 'jquery';
import config from './../../config';
const URL_API = config.urlApi+'controlador/inventario_productos/deleteCompraProductoUnidad.php';

export default function deleteCompraProductoUnidad({values}){
    const idUsuario = localStorage.getItem("idUsuario")
    values = {...values, id: idUsuario}
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values
    })
}